import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserSignInComponent } from './Component/user-sign-in/user-sign-in.component';
import { UserListDashboardComponent } from './Component/user-list-dashboard/user-list-dashboard.component';
import { PageNotFoundComponent } from './Component/page-not-found/page-not-found.component';
import {UserHealthDashboardComponent} from './Component/user-health-dashboard/user-health-dashboard.component';
import { AuthGuard } from './auth.guard';
import { SetNewPasswordComponent } from './Component/set-new-password/set-new-password.component';
import { ManageCompanyComponent } from './Component/manage-company/manage-company.component';
import { NewCompanyComponent } from './Component/new-company/new-company.component';
import { AdminUserListComponent } from './Component/admin-user-list/admin-user-list.component';
import { NewAdminUserComponent } from './Component/new-admin-user/new-admin-user.component';
import { MyProfileComponent } from './Component/my-profile/my-profile.component'
import { BranchListComponent } from './Component/branch-list/branch-list.component'
import { NewBranchComponent } from './Component/new-branch/new-branch.component'
import { EmployeeListComponent } from './Component/employee-list/employee-list.component'
import { CorporateDashboardComponent  } from './Component/corporate-dashboard/corporate-dashboard.component'
import { BranchEmployeeListComponent } from './Component/branch-employee-list/branch-employee-list.component'
import { CompanyDetailsComponent } from './Component/company-details/company-details.component'
import { ServiceDashboardComponent } from './Component/service-dashboard/service-dashboard.component'
import { UserHealthStatusComponent} from './Component/user-health-status/user-health-status.component'

import { PushNotificationComponent} from './Component/push-notification/push-notification.component';
import { PushNotificationListComponent} from './Component/push-notification-list/push-notification-list.component';
import { CorporatePushNotificationComponent} from './Component/corporate-push-notification/corporate-push-notification.component';
import { CorporatePushNotificationListComponent } from './Component/corporate-push-notification-list/corporate-push-notification-list.component';
import { ChallengesComponent } from './Component/challenges/challenges.component';
import { FirmwareSettingComponent } from './Component/firmware-setting/firmware-setting.component';
import { ParticipantslistComponent } from './participantslist/participantslist.component'
import { EditchallengesComponent } from './editchallenges/editchallenges.component';
import { ReportsComponent } from './reports/reports.component';




const routes: Routes = [
  { path: 'Home', component: UserSignInComponent,  },    
  { path: 'UserListDashboard', component: UserListDashboardComponent, canActivate: [AuthGuard] },  
  { path: 'CorporateDashboard', component: CorporateDashboardComponent,canActivate:[AuthGuard] },
  { path: 'UserHealthDashboard', component:UserHealthDashboardComponent,canActivate: [AuthGuard] },
  { path: 'SetNewPassword', component:SetNewPasswordComponent ,canActivate: [AuthGuard]},
 
  { path: 'AddNewCompany', component:NewCompanyComponent ,canActivate: [AuthGuard] },
  { path: 'AdminUserList', component:AdminUserListComponent ,canActivate: [AuthGuard] },
  { path: 'AddNewAdminUser', component:NewAdminUserComponent ,canActivate: [AuthGuard] },
  { path: 'MyProfile',component:MyProfileComponent ,canActivate: [AuthGuard] },
  { path: 'BranchList',component:BranchListComponent ,canActivate: [AuthGuard] },
  { path: 'AddNewBranch',component:NewBranchComponent ,canActivate: [AuthGuard] },
  { path: 'EmployeeList',component:EmployeeListComponent ,canActivate: [AuthGuard] },
  { path: 'BranchEmployeeList',component:BranchEmployeeListComponent ,canActivate: [AuthGuard] },
  { path: 'CompanyDetails',component:CompanyDetailsComponent ,canActivate: [AuthGuard] },  
  { path: 'ServiceDashboard',component:ServiceDashboardComponent,canActivate: [AuthGuard]},   
  { path: 'UserHealthStatus',component:UserHealthStatusComponent ,canActivate: [AuthGuard] },    
  { path: 'PageNotFound', component: PageNotFoundComponent,canActivate: [AuthGuard] },  
  { path: 'FirmwareSetting', component:FirmwareSettingComponent,canActivate:[AuthGuard] },  
  { path:  'PushNotification',component:PushNotificationComponent,canActivate:[AuthGuard]},
  { path:  'PushNotificationList',component:PushNotificationListComponent,canActivate:[AuthGuard]},
  { path:  'CorporatePushNotification',component:CorporatePushNotificationComponent,canActivate:[AuthGuard]},
  { path:  'CorporatePushNotificationList',component:CorporatePushNotificationListComponent,canActivate:[AuthGuard]},
  { path: '',redirectTo: 'Home',pathMatch: 'full'},
  { path:'CorporateChallenges' , component: ChallengesComponent,canActivate:[AuthGuard]},
  { path: 'reports', component: ReportsComponent,canActivate:[AuthGuard]},
  // { path: 'profile2', component: Profile2Component }, 
  { path: 'ManageCompany', component:ManageCompanyComponent ,canActivate: [AuthGuard] },
  {path: 'participantslist/:id' , component:ParticipantslistComponent , canActivate:[AuthGuard]},
  { path: 'editchallenges/:id', component: EditchallengesComponent, canActivate: [AuthGuard] },
  
  { path: '**', redirectTo:'/PageNotFound'} 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule ]
})
export class AppRoutingModule { }
