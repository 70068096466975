import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  userDataSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  userData = this.userDataSource.asObservable();

  constructor() { }

  updateUserData(data) {
    
    this.userDataSource.next(data);
}
}
