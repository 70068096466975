import { Component, OnInit } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { Employee } from '../../Class/Employee/Employee'
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import * as $ from 'jquery';
import { analytics } from 'firebase';
import { JsonPipe } from '@angular/common';
import { event } from 'jquery';
import { NgxSpinnerService} from "ngx-spinner";
import {DataService} from '../../Services/data.service'
@Component({
  selector: 'app-branch-employee-list',
  templateUrl: './branch-employee-list.component.html',
  styleUrls: ['./branch-employee-list.component.css']
})
export class BranchEmployeeListComponent implements OnInit {

  EmployeeList ;  
  EmployeeCount;
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string;   
  CompanyId:string;
  BranchId:string;  
  SearchText: string='';  
  BranchName:string="";
  Type:string="";
   order: string = 'LastReadingDate';
  reverse: boolean = false;
  isDisableduser:boolean=true;

  isMasterSel:boolean;  
  checkedEmployeeList:any=[]; 
  body = { EmailInfo: []}; 
  CheckedEmployee:any={};
  EmployeeData:any;
  pushnotification:boolean=false;
  IsNotReadEmployee: boolean=false;
  
  
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  } 
  constructor(private orderPipe: OrderPipe, private SpinnerService:NgxSpinnerService, private router: Router, private apiService: UserService,private route: ActivatedRoute,private data:DataService) { }

  ngOnInit(): void {
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.BranchId= atob(this.route.snapshot.queryParams.BranchId); 
    this.BranchName= atob(this.route.snapshot.queryParams.BranchName);
    this.Type= atob(this.route.snapshot.queryParams.Type);
    if(this.Type =="NotReadEmployee")
    {
      this.IsNotReadEmployee =true;
    }
    this.getEmployeeList(this.CompanyId,this.BranchId,this.Type); 
  }
  getEmployeeList(CompanyId:string,BranchId:string,Type:string){ 

    this.SpinnerService.show();

    this.apiService.GetEmployeeList(CompanyId,BranchId,Type).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.EmployeeList = data['EmployeeList']; 
            this.SpinnerService.hide(); 
            this.EmployeeCount=this.EmployeeList.length;
            this.EmployeeList.sort((a,b) => a.LastReadingDate.localeCompare(b.LastReadingDate));
                       
          }          
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
beginPagination(pagedItems: Array<any>) {  
  this.pagedItems = pagedItems;   
} 
ShowUserHealthDetails(UID:string, UserName:string,HealthStatus:string) {           
  this.router.navigate(['../UserHealthDashboard'], {
    queryParams: {        
      "UID": UID, "User": encodeURIComponent(UserName),"HealthStatus": btoa(HealthStatus)
    }
  });    
}
deleteEmployee(CompanyId:string,UID:string,BranchId:string ) {     
  if(confirm("Are you sure you want to delete this Employee?")) {     
      this.apiService.deleteEmployee(CompanyId,UID,BranchId).subscribe(   
        (data:any)=>  
        {
          if (data != null){
            if(data.Status =! "1" && data.ErrorMessage !=""  ){
              this.errorMessage = data.ErrorMessage;
            }
            else{                             
              window.location.reload();
            }
          }              
        },           
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }    
      ); 
   }
}
sendEmail(CompanyId: string,BranchId:string,UID:string,EmailId:string,ProfileStatus:string,EmployeeName:string){  
  ProfileStatus='Orange';
  var EmployeeData = [];  
  this.errorMessage=null;
  this.successMessage = null;

  this.EmployeeData = JSON.stringify({CompanyId:CompanyId,BranchId:BranchId, UID:UID,EmailId : EmailId,ProfileStatus:ProfileStatus,EmployeeName: EmployeeName });
  this.apiService.SendSingleHealthStatusMail(this.EmployeeData).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status =! "1" || data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                             
          this.successMessage='Mail sent successfully';
          setTimeout(() => {            
            this.successMessage = null;            
           }, 5000);
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
  ); 
}
sendMultipleEmail(){    
  debugger    
  this.errorMessage=null;
  this.successMessage = null;
  if(this.body.EmailInfo.length==0){
    alert('Please check atleast one record');
    return false;
  }  
  let bodydata = JSON.stringify(this.body);  
  this.data.updateUserData(bodydata);
  this.apiService.SendMultipleHealthStatusMail(bodydata).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status =! "1" || data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                             
          this.successMessage='Mail sent successfully';
          setTimeout(() => {            
            this.successMessage = null;            
           }, 5000);
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
  ); 
}
ClickPopup()
{
  debugger;
  if(this.body.EmailInfo.length==0){
    alert('Please check atleast one record');
    this.pushnotification=false;
    return false;
  }  
  this.pushnotification=true;
 let userIds: string[]=[];
    
   this.body.EmailInfo.forEach((item,index) =>
  
    userIds[index] = item.UID
 ); 
  this.data.updateUserData(userIds);
  

}

checkUncheckAll(event) {  
  const checked = event.target.checked;
  this.EmployeeList.forEach(item =>
     item.state = checked
  ); 
  this.getCheckedItemList();
}

isAllSelected(event) {   
  this.isMasterSel = this.EmployeeList.every(function(item:any) {
      return item.state == true;
    })
  this.getCheckedItemList();
}

getCheckedItemList(){  
  debugger
  this.body = { EmailInfo: []} 
  for (var i = 0; i < this.EmployeeList.length; i++) {
    if(this.EmployeeList[i].state)    
      this.body.EmailInfo.push(this.EmployeeList[i])
    //this.checkedEmployeeList.push(this.EmployeeList[i]);
  }
  // if (this.checkedEmployeeList.length != 0){
  //  this.checkedEmployeeList = JSON.stringify(this.checkedEmployeeList);
  // }
}

}
