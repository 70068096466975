import { Component, OnInit } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import { BoundTextAst } from '@angular/compiler';
declare var $:any;
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { UserService } from '../Services/user.service';
import { title } from 'process';



@Component({
  selector: 'app-participantslist',
  templateUrl: './participantslist.component.html',
  styleUrls: ['./participantslist.component.css']
})
export class ParticipantslistComponent implements OnInit {
 
 
   
  
  title: string; // 

   order: string;
  reverse: boolean;
  SearchText: string = '';
  id: string;
  participantslist: any[] = [];
 
  pageOfItems: any[] = []; 
   
  getParticipantsList: any;
  GetParticipantsList: any;
  errorMessage: string;
  corporateName: any;
  groupName: any;
  corporate: any;
  group: any;
   

  constructor(

    private route: ActivatedRoute,
    private orderPipe: OrderPipe,
    private router: Router,
    private http: HttpClient,
    private apiService: UserService, 
     ) {
      this.route.params.subscribe((params) => {
        this.id = params['id']; // Store 'id' in the component property
        this.title = params['title'];  // Store 'title' in the component property
        this.corporate  = params['corporate'];
        this.group  = params['group']; 

        console.log("tittle",title);
        console.log("corporate",this.corporate);
        console.log("group",this.group);
      });

 
      }
  
  ngOnInit(): void {

    // this.route.params.subscribe((params) => {
    //   this.id = params['id']; // Store 'id' in the component property
    //   const title = params['title'];  // Store 'title' in the component property
     
    //   console.log("title",title);
    // });






       this.getparticipantslist();

     
    
  }
  
  setOrder(value: string): void {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
   
   
  getparticipantslist() {
    // Encode the search text to be sent to the API
    let SearchKey = btoa(this.SearchText ? this.SearchText : '');
  
    let PageNo = 1; // You can adjust the page number if needed
  
    this.apiService.Getparticipantslist(SearchKey, PageNo, this.id).subscribe(
      (data: any) => {
        // Handle the data received from the API here
        console.log('Data from API:', data);
        console.log('Data from  PageNo:',PageNo);
        console.log('Data from  PageNo:', this.pageOfItems);

  
        if (data && data.GetParticipants) {
          // Check if the data and GetParticipants array are available
          this.participantslist = data.GetParticipants;
          console.log('participantslist:', this.participantslist);
  
          // Automatically generate ranks serially
          this.calculateRank();
        } else {
          // Handle the case where there's no data
          this.errorMessage = 'No participants found.';
        }
      },
      (error: any) => {
        // Handle any errors that may occur during the subscription
        console.error('Error:', error);
        this.errorMessage = 'An error occurred while fetching data.';
      }
    );
  }


  onPageChange(pageOfItems: Array<any>) {
    // Update the current page of items
    this.pageOfItems = pageOfItems;
  }


  
  // calculateRank() {
  //   // Sort the participantslist based on a property, e.g., target_achieved
  //   this.participantslist = this.participantslist.sort((a, b) => b.target_achieved - a.target_achieved);
  
  //   // Calculate the rank serially
  //   this.participantslist.forEach((user, index) => {
  //     user.Rank = index + 1;
  //   });
  // }
  
  // calculateRank(startingRank = 1) {
  //   // Sort the participantslist based on a property, e.g., target_achieved
  //   this.participantslist = this.participantslist.sort((b, a) => b.target_achieved - a.target_achieved);
  
  //   // Calculate the rank serially starting from the specified rank
  //   this.participantslist.forEach((user, index) => {
  //     user.Rank = startingRank + index;
  //   });
  // }
  
 
  
  // calculateRank(startingRank = 1) {
  //   // Sort the participantslist based on the target_achieved property in descending order
  //   this.participantslist = this.participantslist.sort((a, b) => b.target_achieved - a.target_achieved);
  
  //   // Find the maximum target_achieved value
  //   const maxTargetAchieved = this.participantslist[0].target_achieved;
  
  //   // Calculate the rank serially starting from the specified rank
  //   this.participantslist.forEach((user, index) => {
  //     // Check if the current participant has the maximum target_achieved
  //     if (user.target_achieved === maxTargetAchieved) {
  //       user.Rank = 1; // If yes, assign rank 1
  //     } else {
  //       user.Rank = startingRank + index; // Otherwise, assign ranks serially
  //     }
  //   });
  // }
  
  calculateRank(startingRank = 1) {
    // Sort the participantslist based on the target_achieved property in descending order
    this.participantslist = this.participantslist.sort((a, b) => {
      if (a.target_achieved === b.target_achieved) {
        // If two users have the same target_achieved, prioritize the one initially with maximum target_achieved
        return this.participantslist.findIndex(user => user.target_achieved === a.target_achieved) -
               this.participantslist.findIndex(user => user.target_achieved === b.target_achieved);
      }
      return b.target_achieved - a.target_achieved;
    });
  
    // Calculate the rank serially starting from the specified rank
    this.participantslist.forEach((user, index) => {
      // Check if the current participant has the maximum target_achieved
      if (user.target_achieved === this.participantslist[0].target_achieved) {
        user.Rank = 1; // If yes, assign rank 1
      } else {
        user.Rank = startingRank + index; // Otherwise, assign ranks serially
      }
    });
  }
  

}
