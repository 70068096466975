import { Component, NgZone, OnInit } from '@angular/core';

  
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap  } from "@angular/router";
import { observable } from 'rxjs';
import { map, timestamp } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSpinnerService } from "ngx-spinner";  
import { Console, debug } from 'console';
import { data } from 'jquery';
import { ChangeDetectorRef } from '@angular/core';
// import { Data } from '../../../Scripts/index';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
// Other imports...
import { FormsModule } from '@angular/forms';
declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

import Histogram from 'highcharts/modules/histogram-bellcurve';
Histogram(Highcharts);

import highcharts3D from 'highcharts/highcharts-3d';
highcharts3D(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);
 
  

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
 
import { title } from 'process';

 
import { NgForm } from '@angular/forms';

import HC_exporting from 'highcharts/modules/exporting';
import HC_data from 'highcharts/modules/data';
HC_exporting(Highcharts);
HC_data(Highcharts);
import { Options } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import fullscreen from 'highcharts/modules/full-screen';
import { UserService } from '../Services/user.service';
import { OrderPipe } from 'ngx-order-pipe';
import { FormControl} from '@angular/forms';
import { start } from 'repl';
import { DatePipe } from '@angular/common';
import { AfterViewInit } from '@angular/core';


declare var $:any;
declare var require: any;

declare const CreateChart :any;
declare const CreateArrayForData:any;
declare const ReCreateBodyTemperatureChart:any;
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);


 

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
 
  
      // Add this property
 
// new changes
 
Highcharts: typeof Highcharts = Highcharts;
chartOptions: Highcharts.Options = {};
// chartOptionss: Highcharts.Options = {};
calorieschartOptions:Highcharts.Options = {};
DistancechartOptions: Highcharts.Options = {}; 
pieChartOptions: any;
matchingStepValue: number | undefined;
matchingCalorieValue:number | undefined;
matchingDistanceValue:number | undefined;
highcharts = Highcharts;
 
ChartDataFromDate = Date.now();
ChartDataToDate;
term: string;
users;//=User;
UserName: string ;
HealthStatus:string;
UID : string;  
 
checked=false;
errorMessage: string;
StatusToShow:string;
infoToShow:string;
calValue: any;
rating: string;
carechartdata:any;
careCount= 0;
unknownCount= 0;
// Add this variable along with your existing class variables
combinedTotalUsers: number = 0;
totalCareUsers:number = 0;
total_user:number = 0;
Active_User:number = 0;
New_Users:number = 0;
loginUser:number =0;
Inactive_User:number = 0;
totalUnknownUsers:number =0;
selectedInterval: string = 'day';

reason: string;
ActivityData: any;
 
AnotherData: any;
caloriesDetails: any[] = [];
 
startDate: Date;
endDate: Date;
 
entry: any;
distanceDetails: any;
stepDetails: any;
distanceValue: any;
stepValue: any;
noDataAvailable: boolean;
currentTimeInterval: string = 'day';

cgenerateData: any;
dgenerateData: any;
CalorieValue: any;
DistanceValue: any;
 
chartType: string = 'DayWise';
chart: any;
  mappedData: {};
  uniqueUserCount: {};
  careUserCount: {};
  totalUserCount: {};
 
showDatePicker: any;
 
  submitted: boolean;
  reportform: FormGroup;
clearStartDate: any;
  lastLoginDate: any;
  lastLoginUsers: any;
  loginDateCount: number;
  loginDate: Date;
  loginDateTimestamp: any;
  startTimestamp: number;
  endTimestamp: number;
  startDateUTC: Date;
  endDateUTC: Date;
// loginUser: any;
status = '';
isMouseOver: boolean = false;
 
  
 
 
  
   constructor( 
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private fb: FormBuilder,
     private apiService: UserService, 
     private route: ActivatedRoute,
     private orderPipe: OrderPipe,
     private router: Router,
     private http: HttpClient,
     private SpinnerService: NgxSpinnerService,
     private ChangeDetectorRef:ChangeDetectorRef,
      private fireStore : AngularFirestore,
      private zone: NgZone,
  
   ) { 
 
   }
   
  
   
   get fn() { return this.reportform.controls; }

ngOnInit()   {

  
  

  this.setDefaultDates();
  this.reportform = this.formBuilder.group({
    'startDate': new FormControl((this.startDate).toISOString().substring(0,10), [Validators.required]), // Change 'startDate' to 'startDate'
    'endDate': new FormControl((this.endDate).toISOString().substring(0,10), [Validators.required]),   // Change 'endDate' to 'endDate'
     'viewGraphType': new FormControl('', []),
      //  this.updateEndDateValidation();
  });
  
  console.log("Total Unknown Users:", this.totalUnknownUsers);
   
  this.apiService.GetPartnerDashboardData(this.startDate, this.endDate)
    .subscribe((data: any) => {
       
    
    //  this.total_user = data.DashboardSummary.total;
       
    });
  
    console.log('Raw Data:', data);


  this.init(this.startDate, this.endDate,[],[],[],[],[],[],[]);
  
  this.getData(this.startDate, this.endDate);
}
// for date validation
getMaxDate(): string {
  const today = new Date();
  const year = today.getFullYear();
  let month: string | number = today.getMonth() + 1;
  let day: string | number = today.getDate();

  // Add leading zero if needed
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}

 


 
submitForm() {
  this.reportform.disable(); // Disable form controls during submission

  // Extract start and end dates
  this.startDate = this.reportform.get('startDate').value; // Change 'startDate' to 'startDate'
  this.endDate = this.reportform.get('endDate').value;     // Change 'endDate' to 'endDate'

  // Check form validity after extracting dates
  if (this.reportform.invalid) {
    // Find the first invalid control in the form
    const invalidControl = Object.values(this.reportform.controls).find(control => control.invalid);

    if (invalidControl) {
      // Focus on the first invalid control
      invalidControl.markAsTouched();
      invalidControl.markAsDirty();
      invalidControl.updateValueAndValidity();
    }
    return; // Stop here if the form is invalid
  }
  setTimeout(() => {
    // Hide loader after form submission
    this.reportform.enable(); // Enable form controls after submission
  }, 1800); // Adjust the delay as needed

  // Continue processing if the form is valid
   this.getData(this.startDate, this.endDate); // Use the startDate and endDate




   
}

over() {
  console.log('Mouseover called');
  this.isMouseOver = true;
}

out() {
  console.log('Mouseout called');
  this.isMouseOver = false;
}
     
getData( startDate: Date, endDate: Date) {
  console.log('Start Dateee:', startDate);
  console.log('End Dateeee:', endDate);

  // this.updateEndDateValidation();
  
 
 

  let mappedData: any[] = [];
  let totalUserCount: { [key: string]: { Value: number, name: string } } = {};
  let careUserCount: { [key: string]: { Value: number, name: string } } = {};
  let uniqueUserCount: { [key: string]: { Value: number, name: string } } = {};

  // Initialize Monthly count objects
  let totalUserCountMonthly: { [key: string]: { Value: number, name: string } } = {};
  let careUserCountMonthly: { [key: string]: { Value: number, name: string } } = {};
  let uniqueUserCountMonthly: { [key: string]: { Value: number, name: string } } = {};

// Initialize yearly count objects
let totalUserCountYearly: { [key: string]: { Value: number, name: string } } = {};
let careUserCountYearly: { [key: string]: { Value: number, name: string } } = {};
let uniqueUserCountYearly: { [key: string]: { Value: number, name: string } } = {};


  startDate = new Date(startDate);
  endDate = new Date(endDate);
    // api calling
  this.apiService.GetPartnerDashboardData(startDate, endDate)
    .subscribe((data: any) => {
      console.log("Raw API Response", data);
      console.log("StartDate", startDate);
      console.log("EndDate", endDate);
 
  

      mappedData = data.DashboardSummary?.alldata?.map((item: any) => ({
        devicetype: item?.devicetype || 'Unknown',
        created_at: item?.created_at,
        userId: item?.userId, // Assuming you have a unique identifier for users
        lastlogindate: new Date(item?.lastlogindate), // Convert lastlogindate to Date object

      })) || [];

      

      //      this.total_user = data.DashboardSummary.total;
      // this.Active_User = data.DashboardSummary.active;
      // this.Inactive_User = data.DashboardSummary.inactive;
      // this.New_Users = data.DashboardSummary.new_users;

      
      

       

      


      
mappedData.forEach(item => {
  const createdDate = new Date(item.created_at);
       const deviceTypeWithMonth = createdDate.toLocaleString('default', { month: 'short' }) + ' ' + createdDate.getFullYear();


   const deviceTypeWithYear = createdDate.getFullYear();
  const deviceTypeWithDate = createdDate.toDateString();

  if (item.devicetype.includes('Unknown')) {
    if (totalUserCount.hasOwnProperty(deviceTypeWithDate)) {
        totalUserCount[deviceTypeWithDate].Value++;
    } else {
        totalUserCount[deviceTypeWithDate] = { Value: 1, name: 'Unknown' };
    }

    if (totalUserCountMonthly.hasOwnProperty(deviceTypeWithMonth)) {
        totalUserCountMonthly[deviceTypeWithMonth].Value++;
    } else {
        totalUserCountMonthly[deviceTypeWithMonth] = { Value: 1, name: 'Unknown' };
    }

    if (totalUserCountYearly.hasOwnProperty(deviceTypeWithYear)) {
        totalUserCountYearly[deviceTypeWithYear].Value++;
    } else {
        totalUserCountYearly[deviceTypeWithYear] = { Value: 1, name: 'Unknown' };
    }
}
 
  if (item.devicetype.includes('CARE')) {
      if (careUserCount.hasOwnProperty(deviceTypeWithDate)) {
          careUserCount[deviceTypeWithDate].Value++;
      } else {
          careUserCount[deviceTypeWithDate] = { Value: 1, name: 'CARE' };
      }

      if (careUserCountMonthly.hasOwnProperty(deviceTypeWithMonth)) {
          careUserCountMonthly[deviceTypeWithMonth].Value++;
      } else {
          careUserCountMonthly[deviceTypeWithMonth] = { Value: 1, name: 'CARE' };
      }

      if (careUserCountYearly.hasOwnProperty(deviceTypeWithYear)) {
          careUserCountYearly[deviceTypeWithYear].Value++;
      } else {
          careUserCountYearly[deviceTypeWithYear] = { Value: 1, name: 'CARE' };
      }
  }

  // Counting users for each unique devicetype for each date
  if (!item.devicetype.includes('CARE') && !item.devicetype.includes('Unknown')) {
      if (uniqueUserCount.hasOwnProperty(deviceTypeWithDate)) {
          uniqueUserCount[deviceTypeWithDate].Value++;
      } else {
          uniqueUserCount[deviceTypeWithDate] = { Value: 1, name: item.devicetype };
      }

      if (uniqueUserCountMonthly.hasOwnProperty(deviceTypeWithMonth)) {
          uniqueUserCountMonthly[deviceTypeWithMonth].Value++;
      } else {
          uniqueUserCountMonthly[deviceTypeWithMonth] = { Value: 1, name: item.devicetype };
      }

      if (uniqueUserCountYearly.hasOwnProperty(deviceTypeWithYear)) {
          uniqueUserCountYearly[deviceTypeWithYear].Value++;
      } else {
          uniqueUserCountYearly[deviceTypeWithYear] = { Value: 1, name: item.devicetype };
      }
  }

  
});



this.startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
this.endDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

// Filter log entries within the specified range and where devicetype is 'Unknown'
const filteredEntries = mappedData.filter(entry => {
    const entryUTC = new Date(Date.UTC(entry.lastlogindate.getFullYear(), entry.lastlogindate.getMonth(), entry.lastlogindate.getDate(), 0, 0, 0));
    return entryUTC >= startDate && entryUTC <= endDate && entry.devicetype === 'Unknown';
});
 

// Count the number of entries within the range
this.loginUser = filteredEntries.length;

console.log(`Number of Unknown Users with lastlogindate entries between ${startDate.toISOString()} and ${endDate.toISOString()}: ${this.loginUser}`);
console.log("loginUser", this.loginUser);

     
      console.log("StepsDetails: " + startDate + " - " + endDate);
      console.log("Unknown User Count for Each Date:", totalUserCount);
      console.log("CARE User Count for Each Date:", careUserCount);
      console.log("Unique User Count for Each Date:", uniqueUserCount);
      console.log("CARE User Count (Monthly):", careUserCountMonthly);
      console.log("CARE User Count (Yearly):", careUserCountYearly);
       console.log("Total User Count (Monthly):", totalUserCountMonthly);
       console.log("Total User Count (Yearly):", totalUserCountYearly);
       console.log("newusers",this.New_Users)
       console.log("newusers",this.Inactive_User)
       console.log("newusers",this.Active_User)
       console.log("newusers",this.total_user)


      //  countUsersWithLastLogin() {
      //   const lastLoginDate = "2024-02-02T11:05:37"; // Replace this with the desired last login date
      //   const usersWithLastLogin = this.dashboardData.DashboardSummary.alldata.filter(user => user.lastlogindate === lastLoginDate);
      //   const count = usersWithLastLogin.length;
      //   console.log(`Number of users with last login date ${lastLoginDate}: ${count}`);
      //   return count;
      // }
    
 
 

 
      this.totalUnknownUsers = Object.values(totalUserCount).reduce((sum, entry) => sum + entry.Value, 0);
       console.log("Total Unknown Users:", this.totalUnknownUsers);

      // Calculate and display the sum of all values in careUserCount
      this.totalCareUsers = Object.values(careUserCount).reduce((sum, entry) => sum + entry.Value, 0);
      console.log("Total CARE Users:", this.totalCareUsers);

       this.combinedTotalUsers = this.totalUnknownUsers + this.totalCareUsers;
      console.log("Combined Total Users (CARE + Unknown):", this.combinedTotalUsers);
 
      this.init(startDate, endDate,totalUserCount,careUserCount,uniqueUserCount,careUserCountMonthly,careUserCountYearly,totalUserCountMonthly,totalUserCountYearly);

        
      const desiredDate = new Date(startDate).toDateString();
      const matchingTotalUserValue = totalUserCount[desiredDate]?.Value;

      this.total_user = data.DashboardSummary.total;
      this.Active_User = data.DashboardSummary.active;
      this.Inactive_User = data.DashboardSummary.inactive;
      this.New_Users = data.DashboardSummary.new_users;


      console.log("newusers",this.New_Users)
       console.log("newusers",this.Inactive_User)
       console.log("newusers",this.Active_User)
       console.log("newusers",this.total_user)

       
      //  this.pieChartOptions = {
      //   chart: {
      //     type: 'pie'
      //   },
      //   title: {
      //     text: 'User Distribution'
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.y}</b>'
      //   },
      //   plotOptions: {
      //     pie: {
      //       allowPointSelect: true,
      //       cursor: 'pointer',
      //       dataLabels: {
      //         enabled: true,
      //         format: '<b>{point.name}</b>: {point.y}'
      //       }
      //       showInLegend: true;
      //     }
      //   },
        
      //   series: [{
      //     name: 'Users',
      //     colorByPoint: true,
      //     data: [
      //       {
      //         name: 'New Users in Last one Month',
      //         y: this.New_Users || 0 // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'Active Users',
      //         y: this.Active_User || 0  // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'Inactive Users',
      //         y: this.Inactive_User || 0 // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'total_user',
      //         y: this.total_user || 0
      //       },
            
      //       // Add more data points as needed
      //     ]
      //   }]
      // };
      // this.pieChartOptions = {
      //   chart: {
      //     type: 'pie'
      //   },
      //   title: {
      //     text: 'User Distribution'
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.y}</b>'
      //   },
      //   plotOptions: {
      //     pie: {
      //       allowPointSelect: true,
      //       cursor: 'pointer',
      //       dataLabels: {
      //         enabled: true,
      //         format: '<b>{point.name}</b>: {point.y}'
      //       },
      //       showInLegend: true // Corrected from `;` to `,`
      //     }
      //   },
      //   credits: {
      //     enabled: false,
      //   },



      //   series: [{
      //     name: 'Users',
      //     colorByPoint: true,
      //     data: [
      //       {
      //         name: 'New Users in Last one Month',
      //         y: this.New_Users || 0 // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'Active Users',
      //         y: this.Active_User || 0  // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'Inactive Users',
      //         y: this.Inactive_User || 0 // Placeholder value, it will be updated later
      //       },
      //       {
      //         name: 'total_user',
      //         y: this.total_user || 0
      //       }




            
      //     ]
      //   }]
      // };
      
      // this.pieChartOptions = {
      //   chart: {
      //     type: 'pie',
      //     options3d: {
      //       enabled: true, // Enable 3D
      //       alpha: 360, // Set the angle of the pie chart
      //       beta: 0 // Set the depth of the pie chart
      //     }

 
      //   },
      //   title: {
      //     text:  this.total_user === 0 ? 'No Data Available' : ' ',
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.y}</b>'
      //   },
      //   plotOptions: {
      //     pie: {
      //       allowPointSelect: true,
      //       cursor: 'pointer',
      //       dataLabels: {
      //         enabled: true,
      //         format: '<b>{point.name}</b>: {point.y}'
      //       },
      //       showInLegend: true                     
      //     }
      //   },
         
      //   exporting: {  
      //     buttons: {
      //       contextButton: {
      //         menuItems: [
      //           'viewFullscreen', 'downloadPNG', 'printChart', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS'
      //         ]
      //       }
      //     }
      //   },
      //   series: [{
      //     name: 'Users',
      //     colorByPoint: true,
      //     data: [
      //       {
      //         name: 'New users in last one month',
      //         y: this.New_Users || 0  
      //       },
      //       {
      //         name: 'Active users',
      //         y: this.Active_User || 0   
      //       },
      //       {
      //         name: 'loginUser',
      //         y: this.loginUser || 0  
      //       },
      //       {
      //         name: 'Total no of registered user',
      //         y: this.total_user || 0,
      //         color: '#328d56',
      //       }
      //     ]
      //   }]
      // };
      // this.pieChartOptions = {
      //   chart: {
      //     type: 'pie',
      //     options3d: {
      //       enabled: true,
      //       alpha: 50,
      //       beta: 0
            
      //     }
      //   },
      //   title: {
      //     text: this.total_user === 0 ? 'No Data Available' : ' ',
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.y}</b>'
      //   },
      //   plotOptions: {
      //     pie: {
      //       allowPointSelect: true,
      //       cursor: 'pointer',
      //       depth: 60,
      //       dataLabels: {
      //         enabled: true,
      //         format: '<b>{point.name}</b>: {point.y}'
      //       },
      //       showInLegend: true,
           
      //     }
      //   },
      //   exporting: {  
      //     buttons: {
      //       contextButton: {
      //         menuItems: [
      //            'downloadPNG', 'printChart', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS'
      //         ]
      //       }
      //     }
      //   },

      //   credits: {
      //     enabled: false,
      //   },
      //   series: [{
      //     name: 'Users',
      //     colorByPoint: true,
      //     data: [
      //       {
      //         name: 'New users in last one month',
      //         y: this.New_Users || 0,
      //         color:'#00aded',
      //       },
      //       // {
      //       //   name: 'Active users in last one month',
      //       //   y: this.Active_User || 0,
      //       //   color:'#6e2d9d', 
      //       // },
      //       {
      //         name: 'login users',
      //         y: this.loginUser || 0 ,
      //         color:'#8fcd4e',
      //       },
      //       {
      //         name: 'Total no of registered users',
      //         y: this.total_user || 0,
      //         color: '#fc9f34',
      //       }
      //     ]
      //   }]
      // };

    //     this.pieChartOptions = {
  
    //   chart: {
    //     type: 'pie'
    //   },
    //   title: {
    //     text: 'User Distribution'
    //   },
    //   tooltip: {
    //     pointFormat: '{series.name}: <b>{point.y}</b>'
    //   },
    //   plotOptions: {
    //     pie: {
    //       allowPointSelect: true,
    //       cursor: 'pointer',
    //       dataLabels: {
    //         enabled: true,
    //         format: '<b>{point.name}</b>: {point.y}'
    //       }
           
    //     }
        
    //   },
      
    //   series: [{
    //     name: 'Users',
    //     colorByPoint: true,
    //     data: [
    //       {
    //         name: 'New Users in Last one Month',
    //         y:this.New_Users || 0 // Placeholder value, it will be updated later
    //       },
    //       {
    //         name: 'Active Users',
    //         y: this.Active_User || 0  // Placeholder value, it will be updated later
    //       },
    //       {
    //         name: 'Inactive Users',
    //         y: this.Inactive_User || 0 // Placeholder value, it will be updated later
    //       },
    //       {
    //         name: 'total_user',
    //         y: this.total_user || 0

    //       },
          
    //       // Add more data points as needed
    //     ]
    //   }]
    
    // };
  });
    }

    
    

    resetForm() {
      if (this.reportform) {
        // Reset the form
         this.reportform.reset();
    
        // Set default dates
        this.setDefaultDates();
    
        // Set default value for the "View Graph Type" dropdown
        this.reportform.get('viewGraphType').setValue('day');
    
        // Autofill start date and end date
        this.reportform.get('startDate').setValue(this.startDate.toISOString().substring(0, 10));
        this.reportform.get('endDate').setValue(this.endDate.toISOString().substring(0, 10));
    
        // Trigger data retrieval with default dates
        this.getData(this.startDate, this.endDate);
      } else {
        console.error('Form is undefined');
      }
    }
    
 

     
setDefaultDates() {
  const currentDate = new Date();

  // Set the end date as the current date
  this.endDate = currentDate;

  // Set the start date as the current date minus 30 days
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  this.startDate = thirtyDaysAgo;
}
   

formatDate(date: Date): string {
  // Format date as 'YYYY-MM-DD' for input type date
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
showData(timeInterval: string) {
  this.currentTimeInterval = timeInterval;

  // Reset or recreate the chart based on the selected time interval
  const startDate = new Date();
  const endDate = new Date();

  if (timeInterval === 'month') {
    // Set the start date to the beginning of the current month
    startDate.setDate(1);
  } else if (timeInterval === 'year') {
    // Set the start date to the beginning of the current year
    startDate.setMonth(0, 1);
  } else {
    // Set the start date as the current date minus 30 days for day view
    startDate.setDate(startDate.getDate() - 30);
  }

  // this.startDate = startDate;
  // this.endDate = endDate;

  this.updateChartData();
}
  updateChartData() {
     
  }

    
   
  validateDates() {
    const startDate = this.reportform.get('startDate').value;
    const endDate = this.reportform.get('endDate').value;
  
    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
  
      if (endDateObj < startDateObj) {
        // If end date is less than start date, set end date to null
        this.reportform.get('endDate').setValue(null);
      }
    }
  }
  
   
 
getMinEndDate(): string {
  const startDate = this.reportform.get('startDate').value;

  if (startDate) {
    const startDateObj = new Date(startDate);
    startDateObj.setDate(startDateObj.getDate() + 0); // Increment the start date by 1 day

    // Format the minimum end date as 'yyyy-MM-dd'
    return startDateObj.toISOString().split('T')[0];
  }

  // If there is no start date selected, return an empty string or a default value as needed
  return '';
}









 

 
  init(startDate: Date, endDate: Date, totalUserCount: any, careUserCount: any, uniqueUserCount: any, careUserCountMonthly: any, careUserCountYearly: any, totalUserCountMonthly: any, totalUserCountYearly: any) {
  let totalChartData, careChartData;
 
  if (this.currentTimeInterval === 'day') {
    totalChartData = this.generateData(startDate, endDate, totalUserCount);
    careChartData = this.generateData(startDate, endDate, careUserCount);
  
  } else if (this.currentTimeInterval === 'month') {
    totalChartData = this.generateData(startDate, endDate, totalUserCountMonthly);
    careChartData = this.generateData(startDate, endDate, careUserCountMonthly);
    
  } else if (this.currentTimeInterval === 'year') {
    totalChartData = this.generateData(startDate, endDate, totalUserCountYearly);
    careChartData = this.generateData(startDate, endDate, careUserCountYearly);
  }


  
  

    console.log('Total Chart Data:', totalChartData);
console.log('Care Chart Data:', careChartData);
 
const chartOptions: any = {
  chart: {
    type: 'column',
    backgroundColor: 'white',

    
  },
  title: {
    align: 'center',
    text: this.combinedTotalUsers === 0 ? 'No Data Available' : ' ',
    style: {
      color: 'black',
    },
  },
  xAxis: {
    categories: totalChartData.map((item) => {
      if (this.currentTimeInterval === 'day') {
        return `${item.day} ${this.getMonthName(item.month)} ${item.year}`;
      } else if (this.currentTimeInterval === 'month') {
        return `${this.getMonthName(item.month)} ${item.year}`;
      } else if (this.currentTimeInterval === 'year') {
        return item.label;
      }
    }),
    labels: {
      style: {
        color: 'black',
      },
    },
    title: {
      text: 'Date',
      style: {
        color: 'black',
      },
    },
  },
  yAxis: {
    title: {
      text: 'Value',
      style: {
        color: 'black',
      },
    },
    labels: {
      style: {
        color: 'black',
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      type: 'column',
      name: 'Not Paired',
      data: this.combinedTotalUsers === 0 ? [] : totalChartData.map((day) => day.value),
      color: '#ff6000',
    },
    {
      type: 'column',
      name: 'Paired',
      data: this.combinedTotalUsers === 0 ? [] : careChartData.map((day) => day.value),
      color: '#328d56',
    },
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen', 'downloadPNG', 'printChart', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV', 'downloadXLS'
        ]
      }
    }
  },
  tooltip: {
    formatter: function () {
      return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}`;
    },
  },
  legend: {
    enabled: true,
  },
};

this.chartOptions = chartOptions;


 
  }
 


  
  
generateData(startDate: Date, endDate: Date, rawData: any): any[] {
  const data = [];
  let currentDate = new Date(startDate);
  const endDateTime = new Date(endDate).getTime();

  while (currentDate.getTime() <= endDateTime) {
    // Format the date string based on the current time interval
    let dateString = '';

    if (this.currentTimeInterval === 'day') {
      dateString = currentDate.toDateString();
    } else if (this.currentTimeInterval === 'month') {
      dateString = `${this.getMonthName(currentDate.getMonth() + 1)} ${currentDate.getFullYear()}`;
    } else if (this.currentTimeInterval === 'year') {
      dateString = currentDate.getFullYear().toString();
    }

    const userData = rawData[dateString];
    const value = userData ? userData.Value : 0;

    // Push data based on the current time interval
    if (this.currentTimeInterval === 'day') {
      data.push({
        day: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        value: value,
      });
    } else if (this.currentTimeInterval === 'month') {
      
      data.push({
        label: dateString, // e.g., "Jan 2022"
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        value: value,
      });
 
    } else if (this.currentTimeInterval === 'year') {
      
      data.push({
        label: currentDate.getFullYear().toString(), // e.g., "2022"
        year: currentDate.getFullYear(),
        value: value,
      });
    }

    // Debug statements
    console.log('Current Date:', currentDate);
    console.log('Date String:', dateString);
    console.log('User Data for Date:', userData);
    console.log('Value:', value);
    console.log('rawdata', rawData);

    if (this.currentTimeInterval === 'month') {
      // Move to the first day of the next month
      currentDate.setMonth(currentDate.getMonth() + 1, 1);
    } else if (this.currentTimeInterval === 'year') {
      // Move to the next year
      currentDate.setFullYear(currentDate.getFullYear() + 1, 0, 1);
    } else {
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return data;
}





getMonthName(month: number): string {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[month - 1];
  
}
 

 







  
}

 










 








function countUsersWithLastLogin() {
  throw new Error('Function not implemented.');
}
 