import { Component, OnInit } from '@angular/core';
import {UserSignInService} from '../../Services/user-sign-in.service';

import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { validateEventsArray } from '@angular/fire/firestore';
declare var $:any;

@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.css']
})

export class UserSignInComponent implements OnInit {     
  loginForm : FormGroup;    
  isLoginError: boolean = false; 
  loading = false; 
  serverErrorMsg=""; 
  resetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true; 

  inputType = "password";

  togglePasswordVisibility(inputElement: HTMLInputElement): void {
    console.log(inputElement);
    inputElement.type = inputElement.type === 'password' ? 'text' : 'password';
    this.inputType = inputElement.type;
    inputElement.focus();
  }
  
  constructor(private formBuilder: FormBuilder,private UserService : UserSignInService , private router: Router,private route: ActivatedRoute, private formdata : FormsModule ) {
    
   }

  ngOnInit() {
    $(function () {
        $(".preloader").fadeOut();
    });

    this.loginForm = this.formBuilder.group({     
      UserEmail: ['',[Validators.required, Validators.email]],
      Password:  ['',[Validators.required, Validators.minLength(4)]],     
      rememberMe : []
    });
    this.resetForm = this.formBuilder.group({           
      PassResetEmail: ['',[Validators.required, Validators.email]]
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
      $('#to-recover').on("click", function () {
          $("#loginformGrp").slideUp();
          $("#recoverform").fadeIn();
      });
      $('#cancel').on("click", function () {
          $("#recoverform").fadeOut();
          $("#loginformGrp").slideDown();
      });  
      this.AutoFill();
      document.getElementById('UserEmail').focus();
  }

  get f() { return this.loginForm.controls; }
  get fn() { return this.resetForm.controls; }

  Login(form) {          
    this.serverErrorMsg=null;
    if (form.valid) {           
    this.loading = true;
    this.UserService.logout();    
    this.UserService.userAuthentication(this.f.UserEmail.value,this.f.Password.value).subscribe(      
      (data: any) => {      
      if(data.body !=null){
        var userdata=JSON.parse(data.body.userDetail);      
        console.log(data.body);              
          this.UserService.storeToken(data.body.access_token,userdata.FullName,userdata.EmailId,userdata.UID,userdata.IsTemporyPwd,this.f.rememberMe.value,this.f.Password.value,userdata.Scan,userdata.Monitoring,userdata.CompanyName,userdata.CompanyId,userdata.UserType);
          if(sessionStorage.getItem("IsTemporyPwd")=="true"){
            this.router.navigate(['SetNewPassword']);
          }else{
            this.redirectToMenu();
          }
        }                     
      },
     err => {
      if (err.error) {
        this.isLoginError = true;
        this.serverErrorMsg = err.error.error_description;
      }    
    }     
    );
   }
    else {
      this.IsvalidForm = false;
    }
  }
  redirectToMenu(){    
    let Monitoring=sessionStorage.getItem("Monitoring");
    let Scaning=sessionStorage.getItem("Scan");
    let UserType=sessionStorage.getItem("UserType");

    if (Monitoring == "true" && Scaning == "false" && UserType==""){
      this.router.navigate(['CorporateDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "false" && UserType==""){
      this.router.navigate(['ServiceDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "true" && UserType==""){
      this.router.navigate(['CorporateDashboard']);
    }
    else{
      this.router.navigate(['UserListDashboard']);
    }
  }
  Reset(form) {        
    this.errorMessage=null;
    if (form.valid) {
      this.IsvalidForm = true;
      this.UserService.forgotPassword(this.fn.PassResetEmail.value).subscribe(        
          (data: any) => {
            if (data.body != null){
             if(data.body.Status != 1 && data.body.ErrorMessage !=""  ){
                this.errorMessage = data.body.ErrorMessage;
             }
             else{               
                this.successMessage = "Your password has been mailed you successfully.";
                setTimeout(() => {
                  this.resetForm.reset();
                  this.successMessage = null;
                  window.location.reload();            
                }, 2000);
              }
            }
        },
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }
  AutoFill(){    
    if(localStorage.getItem("rememberMe")=="true"){
      this.loginForm.controls['rememberMe'].setValue(true);
      this.loginForm.controls['UserEmail'].setValue(atob(localStorage.getItem("emailAddress")))
      this.loginForm.controls['Password'].setValue(atob(localStorage.getItem("Password")))
    }  
  }
}
