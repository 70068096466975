import { Injectable, ErrorHandler } from '@angular/core';
import { HttpHeaders, HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { HttpResponse, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router'; 
import { throwError, Observable, observable } from 'rxjs';
import {catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList  } from '@angular/fire/database';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import { Company, CompanyInfo,AdminInfo,Role,Licence } from '../Class/Company/company';
import { AppUpdateHistory } from '../Class/AppUpdateHistory/app-update-history'
import {FirmwareRelease} from '../Class/FirmwareRelease/firmware-release'
import { Branch } from '../Class/Branch/branch'
import { UserProfile } from '../Class/UserProfile/user-profile';
import { DatePipe } from '@angular/common'
import { PushNotificationService } from '../Class/PushNotification/push-notification.service';
import { data, post } from 'jquery';
import { parse } from 'path';
import { Employee } from '../Class/Employee/Employee';
import { promise } from 'protractor';
import { User, analytics } from 'firebase';
import * as _ from 'lodash';
// import { ViewChallengeHistory } from '../Class/view-challenge-history';
import { ViewChallengeHistory } from '../Class/view-challenge-history';
import { ParticipantslistComponent } from '../participantslist/participantslist.component';




@Injectable({
  providedIn: 'root'
})
export class UserService {
  getData() {
    throw new Error('Method not implemented.');
  }
  someApiCall(apiStartDate: string, apiEndDate: string) {
    throw new Error('Method not implemented.');
  }
  currentPage: number;
  getParticipantsCount(challengeId: string) {
    throw new Error('Method not implemented.');
  }
 
  // userQuery: any
             public rootUrl ="https://care.tres.in/CareAtwork";
                // public rootUrl = "http://preproduction-k/CareAPI"; 
      // public rootUrl = "https://localhost:44398"; 
      //  public rootUrl = "http://konnect.quadlabs.net/CareAPI";

  private dbPath= "UserMaster";  
  companyList : Company[];  
  // getAllBadges: any;
  constructor(public datepipe: DatePipe,private http: HttpClient, private router: Router, private firestore : AngularFirestore) {    
   }

  user: any;
  items$: AngularFirestoreCollection<any>;
  items: Observable<any[]>; 


  getUsers() {
    return this.http.get('https://jsonplaceholder.typicode.com/users');
  }

 

  getToken(){
    return sessionStorage.getItem("userToken");
  }
 
   GetUserHealthReading(UID:string,FromDate:string,ToDate:string):Observable<object>{            
    let data = "UID=" + btoa(UID) + "&FromDate=" + FromDate + "&ToDate=" + ToDate + "&VitalCode=All";
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken()});       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetHealthReading", data, { headers: reqHeader })
  }  

  getUserList(SearchKey:string,PageNo: number): Observable<object> {            
    let data = "PageNo=" + PageNo + "&SearchKey=" + SearchKey;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken() });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetDashboardUserList", data, { headers: reqHeader })
    
  }   
  getTravelHistoryList(UID:string):Observable<object>{
  
    //this.rootUrl='https://localhost:44398'
    let data = "UID=" + UID ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetTravelHistory_web", data, { headers: reqHeader })    
  }
  getVaccinationTestDetails(UID:string):Observable<object>{    
     let data = "UID=" + UID ;
     const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});       
     return this.http.post(this.rootUrl + "/API/v1/Service/GetVaccinationDetail_Web", data, { headers: reqHeader })    
   }
  GetCompanyDetail(CompanyId:string,AdminId:string): Observable<object> {    
    let data = "CompanyId=" + CompanyId + "&AdminId=" + AdminId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetCompanyDetail_Web", data, { headers: reqHeader })
  }  
  GetAdminUserDetail(CompanyId:string,AdminId:string): Observable<object> {
        
    let data = "CompanyId=" + CompanyId + "&AdminId=" + AdminId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetAdminDetail_Web", data, { headers: reqHeader })
     //return this.http.post('https://localhost:44398/API/V1/service/GetAdminDetail_Web',data, { headers: reqHeader })
  }

 
  
  GetBranchDetail(CompanyId:string,BranchId:string): Observable<object> {    
    let data = "CompanyId=" + CompanyId + "&BranchId=" + BranchId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetCompanyBranchDetail", data, { headers: reqHeader })
  }
  GetUserProfileDetail(Email:string){
    // let data = "CompanyId=" + btoa('0') + "&AdminId=" + btoa('0') +  "&Email=" + Email 
    let data = "CompanyId=0"+ "&AdminId=0"  +  "&Email=" + Email 
    console.log(data);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetAdminDetail_Web", data, { headers: reqHeader })
  }
 
  GetAdminUserList(CompanyId:string): Observable<object> {                    
    let data ="CompanyId=" + CompanyId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetAdminList_Web", data, { headers: reqHeader }) 
    //return this.http.post('https://localhost:44398/API/V1/service/GetAdminList_Web',data, { headers: reqHeader })       
  }
  GetBranchList(CompanyId:string): Observable<object> { 
                    
    let data ="CompanyId=" + btoa(CompanyId);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/ManageCompanyBranch_Web", data, { headers: reqHeader }) 
    //return this.http.post('https://localhost:44398/API/V1/service/ManageCompanyBranch_Web',data, { headers: reqHeader })       
  } 
  






   
  GetChallenges(SearchKey: string, PageNo: number,): Observable<object> {
    const requestData = {
      startIndex: 0, // Provide a valid startIndex value
      pageSize: 100, // Provide a valid pageSize value
      challengeType: 'ALL', // Provide a valid challengeType value
      uid: '243ad7c4-28ff-4aa0-aeb3-1a2f78c90f77', // Provide a valid uid value
      period: 'CURRENT' // Provide a valid period value
    };
    // 'a55186d4-b5a0-11ec-a392-001aa03b4b43'
    // Update the requestData object with SearchKey and PageNo
    requestData['PageNo'] = PageNo;
    requestData['SearchKey'] = SearchKey;
  
    // Convert the requestData object to a JSON string
    const jsonData = JSON.stringify(requestData);
  
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json', // Use 'application/json' for JSON data
      'Authorization': 'Bearer ' + this.getToken()
    });
  
    return this.http.post(this.rootUrl + '/API/v1/service/GetChallenges', jsonData, { headers: reqHeader });
  }

  
  getChallenges() {
    throw new Error('Method not implemented.');
  }

 
  

  GetMyChallenges(SearchKey: string, PageNo: number,  CompanyId: string): Observable<object> {
    const requestData = {
      corporateId:  CompanyId, // Use the same name 'corporate_id'
      startIndex: 0, // Provide a valid startIndex value
      pageSize: 100, // Provide a valid pageSize value
      PageNo: PageNo,
      SearchKey: SearchKey
    };
  
    const jsonData = JSON.stringify(requestData);
  
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getToken()
    });
  
    return this.http.post(this.rootUrl + '/API/v1/service/GetMyChallenge', jsonData, { headers: reqHeader });
  }


 

  // In your service file (your-service.service.ts)
GetActivityData(UId:string, FromDate:Date,ToDate:Date): Observable<object> {
  const requestData = {
    "UID": UId,
    "StartDate":FromDate,
    "EndDate":ToDate,
  };

  const jsonData = JSON.stringify(requestData);

  const reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.getToken()
  });

  return this.http.post(this.rootUrl + '/API/v1/service/GetActivityData', jsonData, { headers: reqHeader });
}

  
GetPartnerDashboardData( startDate:Date,endDate:Date): Observable<object> {
  //format 2024-01-01
  console.log("Start Date: "+startDate+ "-"+typeof(startDate));
  console.log("End Date: "+endDate+ "-"+typeof(startDate));
  const startDateString = startDate.toISOString().split('T')[0];
  const endDateString = endDate.toISOString().split('T')[0];
  console.log("Start Date: "+startDateString);
  console.log("End Date: "+endDateString);
  const requestData = {
     device_types:"CARE C303",
    "start_date":startDateString,
    "end_date":endDateString,
  }

  const jsonData = JSON.stringify(requestData);

  const reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.getToken()
  });

  return this.http.post(this.rootUrl + '/API/v1/service/GetPartnerDashboardData', jsonData, { headers: reqHeader });
}




  deleteChallenge(id: string): Observable<object> {
    const requestData = {
      challengeId: id // Include the provided challengeId in the request body
    };
  
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json', // Use 'application/json' for JSON data
      'Authorization': 'Bearer ' + this.getToken()
    });
  
    return this.http.post(`${this.rootUrl}/API/v1/service/DeleteChallenge`, JSON.stringify(requestData), {
      headers: reqHeader
    });
  }
  


 


  Getparticipantslist(SearchKey: string, PageNo: number, id: string): Observable<object> {
    const requestData = {
      Search: '', // Set Search as an empty string  
      Id:id, 
      pageSize: '500', 
      user_uid: '', 
      
    
    };
  
    // Update the requestData object with SearchKey and PageNo
    requestData['PageNo'] = PageNo;
    requestData['SearchKey'] = SearchKey;
  
    // Convert the requestData object to a JSON string
    const jsonData = JSON.stringify(requestData);
  
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json', // Use 'application/json' for JSON data
      'Authorization': 'Bearer ' + this.getToken()
    });
  
    return this.http.post(this.rootUrl + '/API/v1/service/GetParticipants', jsonData, { headers: reqHeader });
  }


  




  
  GetEmployee_Web(CompanyId:string): Observable<object> {                    
    let data ="CompanyId=" + btoa(CompanyId);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetAllEmployee_Web", data, { headers: reqHeader })        
  }    
  GetEmployeeList(CompanyId:string,BranchId:string,Type:string): Observable<object> {             
    let data = "CompanyId=" + btoa(CompanyId) + "&BranchId=" + btoa(BranchId) + "&Type=" + btoa(Type);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetEmployeeList_Web", data, { headers: reqHeader })
    //return this.http.post('https://localhost:44398/API/V1/service/GetEmployeeList_Web', data, { headers: reqHeader })
            
  }
  GetAppHistoryList_Web(CompanyId:string,AdminId:string): Observable<object> {    
    let data = "CompanyId=" + btoa(CompanyId) + "&UploadedBy=" + btoa(AdminId);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetAppUpdateHistoryList_Web", data, { headers: reqHeader })        
  } 
  

  GetUserHistoryList(CompanyId:string,ScannedBy:string): Observable<object> {                    
    let data = "CompanyId=" + btoa(CompanyId) + "&ScannedBy=" + btoa(ScannedBy) ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetScanHistory_Web", data, { headers: reqHeader })        
  } 
  GetUserHealthStatus(CompanyId:string,UID:string): Observable<object> {                    
    let data = "CompanyId=" + btoa(CompanyId) + "&ScannedBy=" + btoa(UID) ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetUserHealthStatus_Web", data, { headers: reqHeader })        
  } 
  deleteAdminUser(_AdminId) :Observable<object> {    
    let data =  "AdminId=AdminId";
    // let data =  "AdminId=" + btoa('0');
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/DeactivateAdminUser_Web", data, { headers: reqHeader })        
  }
  deleteBranch(CompanyId:string , BranchId:string) :Observable<object> {    
    let data = "CompanyId=" +  btoa(CompanyId) + "&BranchId=" + btoa(BranchId) ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/DeleteCompanyBranch_Web", data, { headers: reqHeader })        
  }
  DeleteMChallenge(challengeId:string) :Observable<object> {    
    let data = "challengeId=" +  btoa(challengeId);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/service/DeLeteChallenge", data, { headers: reqHeader })        
  }


  deleteEmployee(CompanyId:string,UID:string,BranchId:string) :Observable<object> {    
    let data = "CompanyId=" +  btoa(CompanyId) + "&UID=" + btoa(UID) + "&BranchId=" + BranchId ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/DeleteEmployee_Web", data, { headers: reqHeader })        
  }  
  SendSingleHealthStatusMail(body){        
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/SendHealthStatusEmaployee_Web", body, { headers: reqHeader })        
  }
  SendMultipleHealthStatusMail(body){    
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/SendHealthStatusBulk_Web", body, { headers: reqHeader })        
  }
  SetActiveInactive(CompanyId,IsActive):Observable<object> {
    let data = "CompanyId=" + btoa(CompanyId) + "&IsActive=" + IsActive;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/DeactivateCompany_Web", data, { headers: reqHeader })        
  }
  
  createCompany(companyinfo : CompanyInfo,adminInfo : AdminInfo, role : Role, licencedata : Licence ){         
     var licence;       
     licence = {"LicenceExpiryDate": this.datepipe.transform(licencedata.LicenceExpiryDate, 'yyyy-MM-dd').toString()}  
    
    var data = { companyinfo , adminInfo,role , licence };  
    var jsoondata = JSON.stringify(data);    
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/AddCompany_Web', jsoondata,httpOptions) 
  } 
  createAdminUser(adminInfo : AdminInfo){                    
    var data= "CompanyId="+ btoa(adminInfo.CompanyId) + "&AdminId=" + btoa(adminInfo.AdminId)  + "&FirstName="+ btoa(adminInfo.FirstName) + "&LastName="+ btoa(adminInfo.LastName)  + "&Email="+ btoa(adminInfo.Email) + "&Password="+ btoa(adminInfo.Password) + "&PhoneNumber="+ btoa(adminInfo.PhoneNumber) + "&Branchids="+ btoa(adminInfo.Branchids)   
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/AddAdminUser_Web', data,httpOptions)
   
  }  
  createAppHistory(AppHistory : AppUpdateHistory){           
    var data= "CompanyId="+ btoa(AppHistory.CompanyId) + "&AdminId=" + btoa(AppHistory.AdminId)  + "&ReleaseDate="+ AppHistory.ReleaseDate + "&AppVersion=" + btoa(AppHistory.AppVersion)  + "&BuildNumber="+ btoa(AppHistory.BuildNumber) + "&OSType="+ btoa(AppHistory.OSType)  + "&IsForcefullUpdate="+ AppHistory.IsForcefullUpdate
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/AddAppHistory_Web', data,httpOptions) 
  }
  UploadReleaseFile(data:FormData){      
    return this.http.post(this.rootUrl +'/API/v1/Service/AddFirmwareFile', data)
  }  
  createBranch(BranchInfo : Branch){                    
    var data= "CompanyId="+ btoa(BranchInfo.CompanyId) + "&BranchId=" + btoa(BranchInfo.BranchId)  + "&BranchName="+ btoa(BranchInfo.BranchName) + "&Address="+ btoa(BranchInfo.Address)  + "&Country="+ btoa(BranchInfo.Country) + "&State="+ btoa(BranchInfo.State) + "&City="+ btoa(BranchInfo.City)
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/AddCompanyBranch_Web', data,httpOptions) 
  }

  createEmployee(employeeInfo : Employee){                  
    if(employeeInfo.EmployeeCode == null){
        employeeInfo.EmployeeCode=""
    }
    var data= "CompanyId="+ btoa(employeeInfo.CompanyId) + "&BranchId=" + btoa(employeeInfo.BranchId)  + "&UID="+ btoa(employeeInfo.UID) + "&AdminId=" + btoa(employeeInfo.AdminId) + "&EmployeeCode="+ btoa(employeeInfo.EmployeeCode) + "&Designation="+ btoa(employeeInfo.Designation);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/AddEmployee_Web', data,httpOptions)
  }
  createServiceUser(employeeInfo : Employee){                    
    var data= "CompanyId="+ btoa(employeeInfo.CompanyId) + "&CustomerUID="+ btoa(employeeInfo.UID) + "&ScannedBy=" + btoa(employeeInfo.AdminId) 
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/ScanCustomerByServiceProvider_Web', data,httpOptions) 
  }
  updateUserProfile(userProfile: UserProfile){
    var data = "AdminId=" + btoa(userProfile.AdminId) + "&FirstName="+ btoa(userProfile.FirstName) + "&LastName=" + btoa(userProfile.LastName)  + "&PhoneNo="+ btoa(userProfile.PhoneNumber) + "&Email="+ btoa(userProfile.Email)
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };     
    return this.http.post(this.rootUrl +'/API/v1/Service/UpdateAdminProfile', data,httpOptions) 
  }

  CreatePushNotification(data: FormData)
  {
   
    return this.http.post(this.rootUrl + "/API/v1/service/SendPushNotification", data)    
  }

  CreatePushNotifications(data: FormData)
  {
    return this.http.post(this.rootUrl + "/API/v1/service/CorporateSendPushNotification", data)    
  }

  getPushNotificationList(SearchKey:string,PageNo: number): Observable<object> {            
    let data = "PageNo=" + PageNo + "&SearchKey=" + SearchKey;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken() });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetSentNotificationList", data, { headers: reqHeader })    
  }  

  CorporategetPushNotificationList(SearchKey:string,PageNo: number,CompanyId:string): Observable<object> {            
    let data = "PageNo=" + PageNo + "&SearchKey=" + SearchKey + "&CompanyId=" + CompanyId;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken() });       
    return this.http.post(this.rootUrl + "/API/v1/Service/CorporateGetSentNotificationList", data, { headers: reqHeader })
     
  }  

  getDropDownText(id, object){
    debugger;
    const selObj = _.filter(object, function (o) {
        return (_.includes(id,o.id));
    });
    return selObj;
  }

  createChallenges(data: FormData){
    const reqHeader = new HttpHeaders({'Authorization': 'Bearer '+ this.getToken()});  
    return this.http.post(this.rootUrl + '/API/v1/service/CreateChallenges', data, { headers: reqHeader });

  }

  

  updateChallenges(data: any) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken() });
    return this.http.post(this.rootUrl + '/API/v1/service/ChallengeUpdate', data, { headers: reqHeader });
  }
  
  
  
   getAllBadges(rank: number) {
    let data = "Rank=" + rank ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken()});  
    return this.http.post(this.rootUrl + '/API/v1/service/GetAllBadge', data, { headers: reqHeader });
  }
  GetCompanyList(): Observable<object> {                
    let data ="";
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
     
    return this.http.post(this.rootUrl + "/API/v1/Service/ManageCompany_Web", data, { headers: reqHeader })        
  }  
  

  
  
  GetCorporateEmployeeList(CompanyId:string,BranchId:string): Observable<object> 
  { 
                    
    let data = "CompanyId=" + btoa(CompanyId) + "&BranchId=" + btoa(BranchId) ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });       
    return this.http.post(this.rootUrl + "/API/v1/Service/GetCorporateDashboard_Web", data, { headers: reqHeader })
    //return this.http.post('https://localhost:44398/API/V1/service/GetCorporateDashboard_Web', data, { headers: reqHeader })        
  }

  GetUserProfile(UID:string) {
    let data = "UID=" + UID ;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer '+ this.getToken()});  
    return this.http.post(this.rootUrl + '/API/v1/service/GetUserProfile', data, { headers: reqHeader });
  }
  
}
