import { now } from 'jquery'

export class CompanyInfo {  
    CompanyId:string="0";      
    CompanyName:string=""         
    Address : string =""
    City: string=""
    State: string=""
    Country: string=""
    PhoneNumber : string=""
    CompanyEmail: string=""         
    IsActive : boolean=true   
}
export class AdminInfo{    
    AdminId: string ="0"
    CompanyId: string ="0"
    FirstName: string=""
    LastName:string=""
    Email:string=""
    Password: string =""
    IsMailSend:boolean = false
    PhoneNumber:string=""
    Branchids:string=""
}

export class Role {
    Scan : boolean = false
    Monitoring: boolean = true
}
export class Licence{
    LicenceExpiryDate : Date = null
}


 export class Company{
//     companyInfo = new CompanyInfo     
//     adminInfo = new  AdminInfo
//     role = new Role
//     licence = new Licence 
 }