import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  BackToDashboard(){    
    let Monitoring=sessionStorage.getItem("Monitoring");
    let Scaning=sessionStorage.getItem("Scan");
    let UserType=sessionStorage.getItem("UserType");

    if (Monitoring == "true" && Scaning == "false" && UserType==""){
      this.router.navigate(['../CorporateDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "false" && UserType==""){
      this.router.navigate(['../ServiceDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "true" && UserType==""){
      this.router.navigate(['../CorporateDashboard']);
    }
    else{
      this.router.navigate(['../UserListDashboard']);
    }
  }

}
