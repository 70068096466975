export class Branch {
        CompanyId: string
        BranchId :string="0"
        BranchName : string=""
        Address : string=""
        City : string=""
        State : string=""
        Country : string=""
        IsActive : boolean = true
}
