import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import { BoundTextAst } from '@angular/compiler';
declare var $:any;


@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.css']
})
export class AdminUserListComponent implements OnInit {

  constructor(private orderPipe: OrderPipe,private router: Router, private apiService: UserService,private route: ActivatedRoute) { }
  UserList;
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  CompanyId;
  CompanyName;
  SearchText: string='';  
  order: string = 'FullName';
  reverse: boolean = false;
  redirectFrom:string;
  BranchId:string;
  
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  

  ngOnInit(): void {
    this.CompanyId = this.route.snapshot.queryParams.CompanyId; 
    this.CompanyName= atob(this.route.snapshot.queryParams.CompanyName); 
    this.redirectFrom=atob(this.route.snapshot.queryParams.redirectFrom); 

    this.getUserList(this.CompanyId); 
  }
  GoBack(){
    if(this.redirectFrom == "ManageCompany"){
      this.router.navigate(['/ManageCompany']) 
    }
    else{
      this.router.navigate(['/CompanyDetails']) 
    }
  }
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  }    
  getUserList(CompanyId:string){  
    
    this.apiService.GetAdminUserList(CompanyId).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.UserList = data['AdminList'];
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
EditUser(CompanyId: string, AdminId:string) { 
   
  this.router.navigate(['AddNewAdminUser'], {
    queryParams: {        
      "CompanyId": btoa(CompanyId), "AdminId": btoa(AdminId),
      "CompanyName" : btoa(this.CompanyName),
     
      "redirectFrom" : btoa(this.redirectFrom)
    }
  });    
}
deleteUser(AdminId:string ) { 
  if(this.UserList.length == 1){    
    $('#smallform').modal('show'); 
    return;
  }
  if(confirm("Are you sure you want to delete this user?")) {     
      this.apiService.deleteAdminUser(AdminId).subscribe(   
        (data:any)=>  
        {
          if (data != null){
            if(data.Status =! "1" && data.ErrorMessage !=""  ){
              this.errorMessage = data.ErrorMessage;
            }
            else{                             
              window.location.reload();
            }
          }              
        },           
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }    
      ); 
   }
}
AddNewUser(){
  this.router.navigate(['AddNewAdminUser'], {
    queryParams: {        
      "CompanyId": this.CompanyId, 
      "CompanyName" : btoa(this.CompanyName),
      "redirectFrom" : btoa(this.redirectFrom)
    }
  });    
}

}
