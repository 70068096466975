import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder,FormArray } from '@angular/forms';
import { PushNotificationService } from '../../Class/PushNotification/push-notification.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgSelectModule } from "@ng-select/ng-select"
import { TextAttribute } from '@angular/compiler/src/render3/r3_ast';
import {DataService} from '../../Services/data.service';


@Component({   
  selector: 'app-corporate-push-notification',
  templateUrl: './corporate-push-notification.component.html',
  styleUrls: ['./corporate-push-notification.component.css']
})
export class CorporatePushNotificationComponent implements OnInit {



  FrmGrpPushNotification: FormGroup;
  UploadFile:any;
  rdo_opt_value:any;
  rdo_target_value:any;
  DynNotificationTitle:string = '';
  DynNotificationMSG:string ='';
  ShParam: boolean = false;
  hiddenrichcontent: boolean = false;
  hiddentextmedia: boolean = false;
  hiddentextonly: boolean = false;

  FlagFileUpload: boolean = false;
  FlagNotificationTitle: boolean = false;
  IsTargetSelected: boolean = false;
  FlagNotificationMSG: boolean =false;
  FlagFileformat:boolean=false;
  FlagFilelimit:boolean=false;
  errorMessage:string;
  successMessage:string;
  isSelectImage:boolean=false;
  isTextOnly=false;
  isMedia=false;
  isRichontent=false;
  CompanyId:string;
  BranchListData:any;
  SelectedBranch : string[];
  BranchId:string;
  EmployeeList ;
  EmployeeListdata:any;
  isDisableduser:boolean=true;
  branchSelected:string;
  isBranchDisable:boolean=false;
  isShowBranch:boolean=true;
  isShowTargetplatform:boolean=true;
  isRadioHide:boolean=true;
  isShowBothRadio:boolean=true;
  
  
 selectedUserIds: string[];



  constructor(private SpinnerService: NgxSpinnerService, public fb: FormBuilder,public us: UserService,private apiService:UserService, private router: Router,private data:DataService) 
  { 
       
  }

  ngOnInit(): void {
  
    debugger;
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
   
    //this.BranchName= atob(this.route.snapshot.queryParams.BranchName);
   
    this.getCorporateBranchData(this.CompanyId);
    this.getCorporateEmployeeData(this.CompanyId);
   
    this.data.userData.subscribe(x=>this.selectedUserIds=x)
if(this.selectedUserIds.length>0)
{
  this.isDisableduser=false;
  this.isShowBranch = false;
  this.isShowTargetplatform=false;
  this.isRadioHide=false;
  this.isShowBothRadio=false;
  this.rdo_target_value=1

}
    this.FrmGrpPushNotification = this.fb.group({

      rdo_opt:[''],
      DDLAppTrgtTyp:[''],
      NotificationTitle:['', Validators.required],
      NotificationMSG:['', Validators.required],
      FUPNotificationBanner:[''],
      notification_events:[''],
      rdo_target:['',Validators.required],
      CbBranch: [''],
      mltselect: '',
      branches: this.fb.array([])

    });

    this.ShParam = false; 
    let defaultId = "1";
   this.FrmGrpPushNotification.controls['DDLAppTrgtTyp'].setValue(defaultId);
   this.FrmGrpPushNotification.controls['notification_events'].setValue(defaultId);
   
   
  }

  onCheckboxChange(e) {
   
    const branches: FormArray = this.FrmGrpPushNotification.get('branches') as FormArray;
    //this.FrmGrpPushNotification.controls['rdo_target'];
    if (e.target.checked) {
      branches.push(new FormControl(e.target.value));
    } else {
       const index = branches.controls.findIndex(x => x.value === e.target.value);
       branches.removeAt(index);
    }
   this.SelectedBranch= branches.value
      
  
    if(branches.value.length>0)
    {
      this.isDisableduser=false;
      this.selectedUserIds=null;
    }
  else
  {
    this.isDisableduser=true;
  }

  }
  resetEmployeeDropdown()
  {
   
    this.selectedUserIds=null;
    this.isBranchDisable=false;
    this.isRadioHide=true;
  }
  onSelectEmployee(e)
  {
    debugger;
    if(this.selectedUserIds.length>0)
    {
      this.isBranchDisable=true;
      this.isRadioHide=false;
      this.rdo_target_value=1
    }

    else
    {
      this.isBranchDisable=false;
      this.isRadioHide=true;
    }
   
  }

  NotificationTitle_KeyUp()
  {    
    if (this.FrmGrpPushNotification.value["NotificationTitle"] != null || this.FrmGrpPushNotification.value["NotificationTitle"].length > 0 || this.FrmGrpPushNotification.value["NotificationTitle"] != "")
    {
          this.FlagNotificationTitle = false;
          //return false;          
    }
    else
    {
      this.FlagNotificationTitle = true;
    }
  }

  NotificationMSG_KeyUp()
  {    
    if (this.FrmGrpPushNotification.value["NotificationMSG"] != null || this.FrmGrpPushNotification.value["NotificationMSG"].length > 0 || this.FrmGrpPushNotification.value["NotificationMSG"] != "")
    {
          this.FlagNotificationMSG = false;
          //return false;          
    }
    else
    {
      this.FlagNotificationMSG = true;
    }
  }


  onImageClick(e:HTMLElement){
    console.log(e.id, e.className);
    if(e.id=="lbl_textonly")
    {
      this.isTextOnly=true;
      this.isMedia=false;
      this.isRichontent=false;
      this.rdo_opt_value=1;
    }
    else if(e.id=="lbl_textmedia")
    {
      this.isTextOnly=false;
      this.isMedia=true;
      this.isRichontent=false;
      this.rdo_opt_value=2;
    } 
    else if(e.id=="lbl_richcontent")
    {
      this.isTextOnly=false;
      this.isMedia=false;
      this.isRichontent=true;
      this.rdo_opt_value=3;
    } 



    ////////////

        

    if(this.rdo_opt_value == 1)
    {
      this.ShParam = true; 
      this.FlagFileUpload = false;
      this.hiddentextmedia=true;
      this.hiddenrichcontent=true;
      this.hiddentextonly = false;
    }
    else if(this.rdo_opt_value == 2)
    {
      this.ShParam = false; 
      this.FlagFileUpload = false;

      this.hiddentextmedia=false;
      this.hiddenrichcontent=true;
      this.hiddentextonly = true;
    }
    else if(this.rdo_opt_value == 3)
    {
      this.ShParam = false; 
      this.hiddentextmedia=true;
      this.hiddenrichcontent=false;
      this.hiddentextonly = true;
    }

    /////////
}





  BtnSubmit_Click()
  {
    debugger;
    

    if (this.UploadFile ==  undefined && this.rdo_opt_value == 3)
    {
          this.FlagFileUpload = true;
          return false;          
    }

    if (this.FrmGrpPushNotification.value["NotificationTitle"] == null || this.FrmGrpPushNotification.value["NotificationTitle"].length == 0 || this.FrmGrpPushNotification.value["NotificationTitle"] == "")
    {
          this.FlagNotificationTitle = true;
          return false;          
    }
    else
    {
      this.FlagNotificationTitle = false;
    }

    if (this.FrmGrpPushNotification.value["NotificationMSG"] == null || this.FrmGrpPushNotification.value["NotificationMSG"].length == 0 || this.FrmGrpPushNotification.value["NotificationMSG"] == "")
    {
          this.FlagNotificationMSG = true;
          return false;          
    }
    else
    {
      this.FlagNotificationMSG = false;
     
   
    }
    if (this.FrmGrpPushNotification.value["rdo_target"]!=null && this.FrmGrpPushNotification.value["rdo_target"]!="" )
    {
          this.IsTargetSelected = false;
          //return false;          
    }
    else
    {
      if(this.isShowTargetplatform==false)
      {
        this.IsTargetSelected = true;
  
      }
     else{
      this.IsTargetSelected = true;
       return false;
     }
    }

    let formData:FormData = new FormData();
    formData.append("NotificationType", this.rdo_opt_value);
    formData.append("AppTarget",this.FrmGrpPushNotification.value["DDLAppTrgtTyp"]);
    formData.append("NotificationTitle",this.FrmGrpPushNotification.value["NotificationTitle"]);
    formData.append("NotificationMessage",this.FrmGrpPushNotification.value["NotificationMSG"]);
    formData.append("NotificationImg", (this.UploadFile == undefined || this.UploadFile == null) ?  "" : this.UploadFile[0] );
    formData.append("TargetPlatform",this.rdo_target_value);
    formData.append("PushDate",this.FrmGrpPushNotification.value["notification_events"]);
    formData.append("Company",this.CompanyId);
    
    if(this.SelectedBranch!=null && this.SelectedBranch.length>0)
    formData.append("Branch", this.SelectedBranch.join(",")); //this.FrmGrpPushNotification.value["CbBranch"]);
    else
    formData.append("Branch", null);
    if(this.selectedUserIds !=null && this.selectedUserIds.length>0)
    {
      // formData.append("Employee", JSON.stringify(this.selectedUserIds));
      formData.append("Employee", this.selectedUserIds.join(","));
    }
    else
    {
      formData.append("Employee", null);
    }
   
   
    this.SpinnerService.show();

    

    // for (var key in formData) {
    //   console.log(key, formData[key]);
    // }

  //   this.us.CreatePushNotifications(formData).subscribe( 
      
       
  //     (data:any)=>
      
     
    
        
  //     {
        

  //       if (data != null)
  //           {
  //         if(data.Status != 1 && data.ErrorMessage !=""  ){
  //           this.errorMessage = data.ErrorMessage;
  //         }
  //         else{ 
  //           this.SpinnerService.hide();                                                    
  //           this.successMessage = 'Notification Sent';  
  //           this.selectedUserIds=[];
  //           setTimeout(() => {
  //           this.FrmGrpPushNotification.reset();
  //           this.successMessage = null;
  //           this.router.navigate(['CorporatePushNotificationList']);
  //         }, 1000);
  //       }
  //     }                               
  //     },           
  //     err => {
  //       if (err.error) 
  //       {
          
  //       }
  //     }      
  //   )     

    

   

  // }


  this.us.CreatePushNotifications(formData).subscribe(
    (data: any) => {
      if (data != null) {
        if (data.Status !== 1 && data.ErrorMessage !== "") {
          // Error case
          this.errorMessage = data.ErrorMessage;
        } else {
          // Success case
          this.SpinnerService.hide();
          this.successMessage = 'Notification Sent';
          this.selectedUserIds = [];
          
          // Reset form and navigate after a delay
          setTimeout(() => {
            this.FrmGrpPushNotification.reset();
            this.successMessage = null;
            this.router.navigate(['CorporatePushNotificationList']);
          }, 1000);
        }
      }
    },
    (err) => {
      // Handle error response
      if (err.error) {
        // Additional error handling if needed
      }
    }
  );
  }  

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg') {
        return true;
    }
else {
    return false;
}
}

  Filevalidation(fileInput: any)
  { 
       
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e : any) {
          $('#previewtextmedia').attr('src', e.target.result);
          $('#previewrichcontent').attr('src', e.target.result);
      }

      if (!this.validateFile(fileInput.target.files[0].name)) {
        // alert('Selected file format is not supported');
        this.FlagFileformat =true;
        this.UploadFile.value='';
        return false;
        
    }
    if(fileInput.target.files[0].size > 1572864)
{
    // alert("File size exceeds the limit.");
    this.FlagFilelimit =true;
    this.UploadFile.value='';
    return false;
}

      reader.readAsDataURL(fileInput.target.files[0]);
      this.UploadFile = fileInput.target.files; 



  }  
  }

Rdo_Change(element)
{     
    this.rdo_opt_value = element.target.value;      

    if(this.rdo_opt_value == 1)
    {
      this.ShParam = true; 
      this.FlagFileUpload = false;
      this.hiddentextmedia=true;
      this.hiddenrichcontent=true;
      this.hiddentextonly = false;
    }
    else if(this.rdo_opt_value == 2)
    {
      this.ShParam = false; 
      this.FlagFileUpload = false;

      this.hiddentextmedia=false;
      this.hiddenrichcontent=true;
      this.hiddentextonly = true;
    }
    else if(this.rdo_opt_value == 3)
    {
      this.ShParam = false; 
      this.hiddentextmedia=true;
      this.hiddenrichcontent=false;
      this.hiddentextonly = true;
    }

}
Rdo_TChange(element)
{
  debugger;
  this.rdo_target_value =element.target.value;
  this.IsTargetSelected=false;
}

GoToPushNList(){    
  this.router.navigate(['CorporatePushNotificationList'], {
    queryParams: {        
      
    }
  });    
}

getCorporateBranchData(CompanyId:string){           
  this.apiService.GetBranchList(CompanyId).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.BranchListData = data['BranchList'];            
        }
      }                      
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
} 

getCorporateEmployeeData(CompanyId:string)
{           
  this.apiService.GetEmployee_Web(CompanyId).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.EmployeeListdata = data['GetEmployee']; 
         
                
        }
      }                      
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
} 



}
