import { Component, OnInit,AfterViewInit } from '@angular/core';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { AdminInfo } from '../../Class/Company/company'
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { debug } from 'console';
import { stringify } from 'querystring';
import { Employee } from 'src/app/Class/Employee/Employee';
import { event } from 'jquery';
import { NgxSpinnerService} from "ngx-spinner";


declare var $ :any;
@Component({
  selector: 'app-corporate-dashboard',
  templateUrl: './corporate-dashboard.component.html',
  styleUrls: ['./corporate-dashboard.component.css']
})
export class CorporateDashboardComponent implements OnInit,AfterViewInit {

  slides:any;
  slideConfig:any;
  RiskEmployeeList:any;
  NonReadEmployeeList:any;
  SearchEmployeeList:any;
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  CompanyId:string;
  BranchListData:any;
  SearchText: string='';
  SelectedBranchId:string='0'; 
  SelectedBranchName:string=''
  TimeNow:number; 
  borderClass:string;
  

  
  constructor(private SpinnerService: NgxSpinnerService, private router: Router, private apiService: UserService,private route: ActivatedRoute) {
    setInterval(() => {
      this.TimeNow = Date.now();
    }, 1);
   }
  
  options: CloudOptions = {          
    overflow: true,
    zoomOnHover: {
      scale: 1.2,
      transitionTime: 0.3,
      delay: .3,
     
    }  ,
  
  };
  cloudData: CloudData[]=[];  
  NormalCloudData:CloudData[]=[];
 
  ngOnInit(): void {    
    this.SetBranchSlider();
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.getCorporateBranchData(this.CompanyId);        
  }
  
  
  SearchEmployee(){   
    this.getSearchEmployeeList(this.CompanyId);
  }
  getCorporateBranchData(CompanyId:string){ 
    debugger;          
    this.apiService.GetBranchList(CompanyId).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.BranchListData = data['BranchList'];            
          }
        }                      
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
} 
getSearchEmployeeList(CompanyId:string){     
  this.apiService.GetEmployeeList(CompanyId,'0','All').subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else {                           
          this.SearchEmployeeList = data['EmployeeList'];
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
  ); 
}
ShowBranchEmployeeList(Type:string){
  if (this.SelectedBranchId != '0'){
    this.router.navigate(['../BranchEmployeeList'], {
      queryParams: {        
        "BranchId": btoa(this.SelectedBranchId),
        "BranchName": btoa(this.SelectedBranchName),
        "Type": btoa(Type)
      }
    });    
 }
}
  getBranchEmployeeData(CompanyId:string,BranchId:string,BranchName:string){
    this.errorMessage=null;
    $('#sliderDiv a').click(function() {      
      $(this).parent().siblings().children().removeClass("branch-active");
      $(this).addClass("branch-active"); 
    });
    this.SelectedBranchId=BranchId;
    this.SelectedBranchName=BranchName;
    this.NormalCloudData=[];
    this.cloudData=[];
    this.getEmployeeList(CompanyId,BranchId);   
  }  
  getEmployeeList(CompanyId:string,BranchId:string){ 
        
    this.RiskEmployeeList=[];
    this.NonReadEmployeeList=[];
    this.SpinnerService.show();
   
    this.apiService.GetCorporateEmployeeList(CompanyId,BranchId).subscribe( 
      
      (data:any)=>  
      {
       debugger; 
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                                       
            let EmployeeData= data['EmployeeList'];
            this.SpinnerService.hide();
            this.RiskEmployeeList = EmployeeData.riskEmp;
            this.NonReadEmployeeList = EmployeeData.nonReadEmp;
            this.NonReadEmployeeList.sort((a,b) => a.LastReadingDate.localeCompare(b.LastReadingDate));
            if (this.RiskEmployeeList == null){
              this.RiskEmployeeList=[];
            }
            if (this.NonReadEmployeeList == null){
              this.NonReadEmployeeList=[];
            }
          }   
          if(this.RiskEmployeeList){            
            this.cloudData = this.bindEmployeeData(this.RiskEmployeeList);            
           
          }                   
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
  bindEmployeeData(EmployeeData): CloudData[] {    
    debugger;
    const cd: CloudData[] = [];
    for (let i = 0; i < EmployeeData.length; i++) {
      let link: string;
      let color: string;
      let external: boolean;
      let weight = 5;
      let text = '';
      let rotate = 0;
      let username=''
      let userAvtarName='';     
      let status: string;  

      // randomly set link attribute and external
      //if (Math.random() >= 0.5) {
        username = EmployeeData[i].EmployeeName;
        link = `${EmployeeData[i].UID}` + '&' + `${username}` + '&' + `${EmployeeData[i].LastHealthProfile}`;        
        external = false;
        status=EmployeeData[i].LastHealthProfile;
    
      weight = Math.floor((Math.random() * 10) + 1);                  
      userAvtarName= this.GetAvtarName(EmployeeData[i].EmployeeName);
      text= `${userAvtarName}`     

      const el: CloudData = {
        link: link,        
        text: text,
        weight: weight,
        color: 'green',        
        external: external,
        status: status
            
      };
      
           el.status=EmployeeData[i].LastHealthProfile;
       
      cd.push(el);
      
    }
    return cd;
  }  
  GetAvtarName(name){    
    var nameArray = name.split(" ");
    var initials = '';
    
    if(nameArray.length === 1) {
       initials = nameArray[0].charAt(0) + "" +nameArray[0].charAt(1);
       return initials.toUpperCase();
    }else{
       initials = nameArray[0].charAt(0);
    }  
    for (let i = (nameArray.length - 1); i < nameArray.length; i++){
      initials += nameArray[i].charAt(0);
    }   
   return initials.toUpperCase();
 }
  SetBranchSlider()
  {    
    this.slideConfig = {
      "slidesToShow": 4,
      "slidesToScroll": 1,
      "nextArrow": "<button class='slick-next slick-arrow'></button>",
      "prevArrow": "<button  class='slick-prev slick-arrow'></button>",
      "dots": false,
      "infinite": false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }        
      ]
    };
  }  
  ShowUserHealthDetails(UID:string, UserName:string,HealthStatus:string ) {
    debugger;           
    this.router.navigate(['../UserHealthDashboard'], {
      queryParams: {        
        "UID": UID, "User": encodeURIComponent(UserName), "HealthStatus": btoa(HealthStatus)
      }
    });    
  }
  
  logClicked(clicked: CloudData){   
    let url,UID,UserName,HealthStatus;
    url=clicked.link.split("&");    
    UID= url[0];
    UserName= url[1];
    HealthStatus= url[2];        
    $('#CloudTags a').attr('href',"javascript:void(0);");    
    this.router.navigate(['../UserHealthDashboard'], {      
      queryParams: {        
        "UID": UID, "User": btoa(UserName), "HealthStatus": btoa(HealthStatus)               
      }                  

    });       
    return false;
  } 
  ngAfterViewInit() {                       
    jQuery(function(){    
      setTimeout(() => {                
        $('#sliderDiv').find('a:first').addClass('branch-active');          
        $('#sliderDiv').find('a:first')[0].click();          
      }, 1000);            
    });         
  }

    
}
