import { Component, OnInit,Input } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { CompanyInfo,AdminInfo,Role,Licence } from '../../Class/Company/company'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import * as $  from 'jquery';
import { DatePipe } from '@angular/common'

import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { Observable } from 'rxjs';
import { invalid } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',  
  styleUrls: ['./new-company.component.css']
})
export class NewCompanyComponent implements OnInit {
  submitted = false;
  companyInfo: CompanyInfo = new CompanyInfo();  
  adminInfo: AdminInfo = new AdminInfo();
  role: Role = new Role();
  licence: Licence = new Licence();    
  companyForm:FormGroup;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  isValidate=true;
  generatedPassword:string;
  CompanyId;
  AdminId;
  DefaultExpiryDate:Date;

  CompanyIdUpdate = "0";
  AdminIdUpdate="0";
  Isactive:boolean= true;  
  startDate: Date;
  public Countries ;
  private geoCoder;
  RightsError:boolean=false;

	@ViewChild('search')
  public searchElementRef: ElementRef;
 
  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,public datepipe: DatePipe,private formBuilder: FormBuilder,private router: Router, private route:ActivatedRoute, private apiService: UserService) {
    //angulartics2GoogleAnalytics.startTracking();
   }

  ngOnInit(): void {     
    this.startDate =  new Date();
    this.companyForm = this.formBuilder.group({
      CompanyId: {value: null, disabled:true},
      CompanyName: ['',[Validators.required]],      
      PhoneNumber: ['',[Validators.required,Validators.maxLength(15),Validators.minLength(10) ,Validators.pattern('[- +()0-9]+') ]],       
      CompanyEmail: ['',[Validators.required, Validators.email]],      
      Address: ['',[Validators.required]],
      City: ['',[Validators.required]],
      State: [''],
      Country: [null,[Validators.required]],      
      LicenceExpiryDate: ['',[Validators.required]],
      Monitoring: [],
      Scan: [],
      AdminId:[],
      FirstName: ['',[Validators.required]],
      LastName: ['',[Validators.required]],
      AdminEmail: ['',[Validators.required, Validators.email]],
      Password: ['',[Validators.required ,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#~^{}])[A-Za-z\\d$@$!%*?&#~^].{7,}')]],      
      IsActive: [] ,
      IsMailSend:[]     
    });   
    this.CompanyId = this.route.snapshot.queryParams.CompanyId; 
    this.AdminId = this.route.snapshot.queryParams.AdminId; 

    if(this.CompanyId != null )  
    {  
      this.CompanyEdit(this.CompanyId,this.AdminId) ;        
    }   
    else{
      this.Isactive = true;
      this.GenerateAutoPass();   
      this.SetLicenceExpiryDate();            
    }                
    this.autoCompleteAddress();    
    this.getCountryList();
  }
  getCountryList(){    
    this.Countries = 
    [
      {
        code: 'AF',
        name: 'Afghanistan'
      }, {
        code: 'AL',
        name: 'Albania'
      }, {
        code: 'DZ',
        name: 'Algeria'
      }, {
        code: 'AS',
        name: 'American Samoa'
      }, {
        code: 'AD',
        name: 'Andorre'
      }, {
        code: 'AO',
        name: 'Angola'
      }, {
        code: 'AI',
        name: 'Anguilla'
      }, {
        code: 'AQ',
        name: 'Antarctica'
      }, {
        code: 'AG',
        name: 'Antigua and Barbuda'
      }, {
        code: 'AR',
        name: 'Argentina'
      }, {
        code: 'AM',
        name: 'Armenia'
      }, {
        code: 'AW',
        name: 'Aruba'
      }, {
        code: 'AU',
        name: 'Australia'
      }, {
        code: 'AT',
        name: 'Austria'
      }, {
        code: 'AZ',
        name: 'Azerbaijan'
      }, {
        code: 'BS',
        name: 'Bahamas'
      }, {
        code: 'BH',
        name: 'Bahrain'
      }, {
        code: 'BD',
        name: 'Bangladesh'
      }, {
        code: 'BB',
        name: 'Barbade'
      }, {
        code: 'BY',
        name: 'Belarus'
      }, {
        code: 'BE',
        name: 'Belgium'
      }, {
        code: 'BZ',
        name: 'Belize'
      }, {
        code: 'BJ',
        name: 'Benin'
      }, {
        code: 'BM',
        name: 'Bermuda'
      }, {
        code: 'BT',
        name: 'Bhutan'
      }, {
        code: 'BO',
        name: 'Bolivia'
      }, {
        code: 'BQ',
        name: 'Bonaire, Sint Eustatius and Saba'
      }, {
        code: 'BA',
        name: 'Bosnia and Herzegovina'
      }, {
        code: 'BW',
        name: 'Botswana'
      }, {
        code: 'BV',
        name: 'Bouvet Island'
      }, {
        code: 'BR',
        name: 'Brazil'
      }, {
        code: 'IO',
        name: 'British Indian Ocean Territory'
      }, {
        code: 'VG',
        name: 'British Virgin Islands'
      }, {
        code: 'BN',
        name: 'Brunei'
      }, {
        code: 'BG',
        name: 'Bulgaria'
      }, {
        code: 'BF',
        name: 'Burkina Faso'
      }, {
        code: 'BI',
        name: 'Burundi'
      }, {
        code: 'KH',
        name: 'Cambodia'
      }, {
        code: 'CM',
        name: 'Cameroon'
      }, {
        code: 'CA',
        name: 'Canada'
      }, {
        code: 'CV',
        name: 'Cape Verde'
      }, {
        code: 'KY',
        name: 'Cayman Islands'
      }, {
        code: 'CF',
        name: 'Central African Republic'
      }, {
        code: 'TD',
        name: 'Chad'
      }, {
        code: 'CL',
        name: 'Chile'
      }, {
        code: 'CN',
        name: 'China'
      }, {
        code: 'CX',
        name: 'Christmas Island'
      }, {
        code: 'CC',
        name: 'Cocos (Keeling) Islands'
      }, {
        code: 'CO',
        name: 'Colombia'
      }, {
        code: 'KM',
        name: 'Comoros'
      }, {
        code: 'CG',
        name: 'Congo'
      }, {
        code: 'CD',
        name: 'Congo (Dem. Rep.)'
      }, {
        code: 'CK',
        name: 'Cook Islands'
      }, {
        code: 'CR',
        name: 'Costa Rica'
      }, {
        code: 'ME',
        name: 'Crna Gora'
      }, {
        code: 'HR',
        name: 'Croatia'
      }, {
        code: 'CU',
        name: 'Cuba'
      }, {
        code: 'CW',
        name: 'Curaçao'
      }, {
        code: 'CY',
        name: 'Cyprus'
      }, {
        code: 'CZ',
        name: 'Czech Republic'
      }, {
        code: 'CI',
        name: "Côte D'Ivoire"
      }, {
        code: 'DK',
        name: 'Denmark'
      }, {
        code: 'DJ',
        name: 'Djibouti'
      }, {
        code: 'DM',
        name: 'Dominica'
      }, {
        code: 'DO',
        name: 'Dominican Republic'
      }, {
        code: 'TL',
        name: 'East Timor'
      }, {
        code: 'EC',
        name: 'Ecuador'
      }, {
        code: 'EG',
        name: 'Egypt'
      }, {
        code: 'SV',
        name: 'El Salvador'
      }, {
        code: 'GQ',
        name: 'Equatorial Guinea'
      }, {
        code: 'ER',
        name: 'Eritrea'
      }, {
        code: 'EE',
        name: 'Estonia'
      }, {
        code: 'ET',
        name: 'Ethiopia'
      }, {
        code: 'FK',
        name: 'Falkland Islands'
      }, {
        code: 'FO',
        name: 'Faroe Islands'
      }, {
        code: 'FJ',
        name: 'Fiji'
      }, {
        code: 'FI',
        name: 'Finland'
      }, {
        code: 'FR',
        name: 'France'
      }, {
        code: 'GF',
        name: 'French Guiana'
      }, {
        code: 'PF',
        name: 'French Polynesia'
      }, {
        code: 'TF',
        name: 'French Southern Territories'
      }, {
        code: 'GA',
        name: 'Gabon'
      }, {
        code: 'GM',
        name: 'Gambia'
      }, {
        code: 'GE',
        name: 'Georgia'
      }, {
        code: 'DE',
        name: 'Germany'
      }, {
        code: 'GH',
        name: 'Ghana'
      }, {
        code: 'GI',
        name: 'Gibraltar'
      }, {
        code: 'GR',
        name: 'Greece'
      }, {
        code: 'GL',
        name: 'Greenland'
      }, {
        code: 'GD',
        name: 'Grenada'
      }, {
        code: 'GP',
        name: 'Guadeloupe'
      }, {
        code: 'GU',
        name: 'Guam'
      }, {
        code: 'GT',
        name: 'Guatemala'
      }, {
        code: 'GG',
        name: 'Guernsey and Alderney'
      }, {
        code: 'GN',
        name: 'Guinea'
      }, {
        code: 'GW',
        name: 'Guinea-Bissau'
      }, {
        code: 'GY',
        name: 'Guyana'
      }, {
        code: 'HT',
        name: 'Haiti'
      }, {
        code: 'HM',
        name: 'Heard and McDonald Islands'
      }, {
        code: 'HN',
        name: 'Honduras'
      }, {
        code: 'HK',
        name: 'Hong Kong'
      }, {
        code: 'HU',
        name: 'Hungary'
      }, {
        code: 'IS',
        name: 'Iceland'
      }, {
        code: 'IN',
        name: 'India'
      }, {
        code: 'ID',
        name: 'Indonesia'
      }, {
        code: 'IR',
        name: 'Iran'
      }, {
        code: 'IQ',
        name: 'Iraq'
      }, {
        code: 'IE',
        name: 'Ireland'
      }, {
        code: 'IM',
        name: 'Isle of Man'
      }, {
        code: 'IL',
        name: 'Israel'
      }, {
        code: 'IT',
        name: 'Italy'
      }, {
        code: 'JM',
        name: 'Jamaica'
      }, {
        code: 'JP',
        name: 'Japan'
      }, {
        code: 'JE',
        name: 'Jersey'
      }, {
        code: 'JO',
        name: 'Jordan'
      }, {
        code: 'KZ',
        name: 'Kazakhstan'
      }, {
        code: 'KE',
        name: 'Kenya'
      }, {
        code: 'KI',
        name: 'Kiribati'
      }, {
        code: 'KP',
        name: 'Korea (North)'
      }, {
        code: 'KR',
        name: 'Korea (South)'
      }, {
        code: 'KW',
        name: 'Kuwait'
      }, {
        code: 'KG',
        name: 'Kyrgyzstan'
      }, {
        code: 'LA',
        name: 'Laos'
      }, {
        code: 'LV',
        name: 'Latvia'
      }, {
        code: 'LB',
        name: 'Lebanon'
      }, {
        code: 'LS',
        name: 'Lesotho'
      }, {
        code: 'LR',
        name: 'Liberia'
      }, {
        code: 'LY',
        name: 'Libya'
      }, {
        code: 'LI',
        name: 'Liechtenstein'
      }, {
        code: 'LT',
        name: 'Lithuania'
      }, {
        code: 'LU',
        name: 'Luxembourg'
      }, {
        code: 'MO',
        name: 'Macao'
      }, {
        code: 'MK',
        name: 'Macedonia'
      }, {
        code: 'MG',
        name: 'Madagascar'
      }, {
        code: 'MW',
        name: 'Malawi'
      }, {
        code: 'MY',
        name: 'Malaysia'
      }, {
        code: 'MV',
        name: 'Maldives'
      }, {
        code: 'ML',
        name: 'Mali'
      }, {
        code: 'MT',
        name: 'Malta'
      }, {
        code: 'MH',
        name: 'Marshall Islands'
      }, {
        code: 'MQ',
        name: 'Martinique'
      }, {
        code: 'MR',
        name: 'Mauritania'
      }, {
        code: 'MU',
        name: 'Mauritius'
      }, {
        code: 'YT',
        name: 'Mayotte'
      }, {
        code: 'MX',
        name: 'Mexico'
      }, {
        code: 'FM',
        name: 'Micronesia'
      }, {
        code: 'MD',
        name: 'Moldova'
      }, {
        code: 'MC',
        name: 'Monaco'
      }, {
        code: 'MN',
        name: 'Mongolia'
      }, {
        code: 'MS',
        name: 'Montserrat'
      }, {
        code: 'MA',
        name: 'Morocco'
      }, {
        code: 'MZ',
        name: 'Mozambique'
      }, {
        code: 'MM',
        name: 'Myanmar'
      }, {
        code: 'NA',
        name: 'Namibia'
      }, {
        code: 'NR',
        name: 'Nauru'
      }, {
        code: 'NP',
        name: 'Nepal'
      }, {
        code: 'NL',
        name: 'Netherlands'
      }, {
        code: 'AN',
        name: 'Netherlands Antilles'
      }, {
        code: 'NC',
        name: 'New Caledonia'
      }, {
        code: 'NZ',
        name: 'New Zealand'
      }, {
        code: 'NI',
        name: 'Nicaragua'
      }, {
        code: 'NE',
        name: 'Niger'
      }, {
        code: 'NG',
        name: 'Nigeria'
      }, {
        code: 'NU',
        name: 'Niue'
      }, {
        code: 'NF',
        name: 'Norfolk Island'
      }, {
        code: 'MP',
        name: 'Northern Mariana Islands'
      }, {
        code: 'NO',
        name: 'Norway'
      }, {
        code: 'OM',
        name: 'Oman'
      }, {
        code: 'PK',
        name: 'Pakistan'
      }, {
        code: 'PW',
        name: 'Palau'
      }, {
        code: 'PS',
        name: 'Palestine'
      }, {
        code: 'PA',
        name: 'Panama'
      }, {
        code: 'PG',
        name: 'Papua New Guinea'
      }, {
        code: 'PY',
        name: 'Paraguay'
      }, {
        code: 'PE',
        name: 'Peru'
      }, {
        code: 'PH',
        name: 'Philippines'
      }, {
        code: 'PN',
        name: 'Pitcairn'
      }, {
        code: 'PL',
        name: 'Poland'
      }, {
        code: 'PT',
        name: 'Portugal'
      }, {
        code: 'PR',
        name: 'Puerto Rico'
      }, {
        code: 'QA',
        name: 'Qatar'
      }, {
        code: 'RO',
        name: 'Romania'
      }, {
        code: 'RU',
        name: 'Russia'
      }, {
        code: 'RW',
        name: 'Rwanda'
      }, {
        code: 'RE',
        name: 'Réunion'
      }, {
        code: 'BL',
        name: 'Saint Barthélemy'
      }, {
        code: 'SH',
        name: 'Saint Helena'
      }, {
        code: 'KN',
        name: 'Saint Kitts and Nevis'
      }, {
        code: 'LC',
        name: 'Saint Lucia'
      }, {
        code: 'MF',
        name: 'Saint Martin'
      }, {
        code: 'PM',
        name: 'Saint Pierre and Miquelon'
      }, {
        code: 'VC',
        name: 'Saint Vincent and the Grenadines'
      }, {
        code: 'WS',
        name: 'Samoa'
      }, {
        code: 'SM',
        name: 'San Marino'
      }, {
        code: 'SA',
        name: 'Saudi Arabia'
      }, {
        code: 'SN',
        name: 'Senegal'
      }, {
        code: 'RS',
        name: 'Serbia'
      }, {
        code: 'SC',
        name: 'Seychelles'
      }, {
        code: 'SL',
        name: 'Sierra Leone'
      }, {
        code: 'SG',
        name: 'Singapore'
      }, {
        code: 'SX',
        name: 'Sint Maarten'
      }, {
        code: 'SK',
        name: 'Slovakia'
      }, {
        code: 'SI',
        name: 'Slovenia'
      }, {
        code: 'SB',
        name: 'Solomon Islands'
      }, {
        code: 'SO',
        name: 'Somalia'
      }, {
        code: 'ZA',
        name: 'South Africa'
      }, {
        code: 'GS',
        name: 'South Georgia and the South Sandwich Islands'
      }, {
        code: 'SS',
        name: 'South Sudan'
      }, {
        code: 'ES',
        name: 'Spain'
      }, {
        code: 'LK',
        name: 'Sri Lanka'
      }, {
        code: 'SD',
        name: 'Sudan'
      }, {
        code: 'SR',
        name: 'Suriname'
      }, {
        code: 'SJ',
        name: 'Svalbard and Jan Mayen'
      }, {
        code: 'SZ',
        name: 'Swaziland'
      }, {
        code: 'SE',
        name: 'Sweden'
      }, {
        code: 'CH',
        name: 'Switzerland'
      }, {
        code: 'SY',
        name: 'Syria'
      }, {
        code: 'ST',
        name: 'São Tomé and Príncipe'
      }, {
        code: 'TW',
        name: 'Taiwan'
      }, {
        code: 'TJ',
        name: 'Tajikistan'
      }, {
        code: 'TZ',
        name: 'Tanzania'
      }, {
        code: 'TH',
        name: 'Thailand'
      }, {
        code: 'TG',
        name: 'Togo'
      }, {
        code: 'TK',
        name: 'Tokelau'
      }, {
        code: 'TO',
        name: 'Tonga'
      }, {
        code: 'TT',
        name: 'Trinidad and Tobago'
      }, {
        code: 'TN',
        name: 'Tunisia'
      }, {
        code: 'TR',
        name: 'Turkey'
      }, {
        code: 'TM',
        name: 'Turkmenistan'
      }, {
        code: 'TC',
        name: 'Turks and Caicos Islands'
      }, {
        code: 'TV',
        name: 'Tuvalu'
      }, {
        code: 'UG',
        name: 'Uganda'
      }, {
        code: 'UA',
        name: 'Ukraine'
      }, {
        code: 'AE',
        name: 'United Arab Emirates'
      }, {
        code: 'GB',
        name: 'United Kingdom'
      }, {
        code: 'UM',
        name: 'United States Minor Outlying Islands'
      }, {
        code: 'US',
        name: 'United States of America'
      }, {
        code: 'UY',
        name: 'Uruguay'
      }, {
        code: 'UZ',
        name: 'Uzbekistan'
      }, {
        code: 'VU',
        name: 'Vanuatu'
      }, {
        code: 'VA',
        name: 'Vatican City'
      }, {
        code: 'VE',
        name: 'Venezuela'
      }, {
        code: 'VN',
        name: 'Vietnam'
      }, {
        code: 'VI',
        name: 'Virgin Islands of the United States'
      }, {
        code: 'WF',
        name: 'Wallis and Futuna'
      }, {
        code: 'EH',
        name: 'Western Sahara'
      }, {
        code: 'YE',
        name: 'Yemen'
      }, {
        code: 'ZM',
        name: 'Zambia'
      }, {
        code: 'ZW',
        name: 'Zimbabwe'
      }, {
        code: 'AX',
        name: 'Åland Islands'
      }
    ];    
    return JSON.stringify(this.Countries);    
  }

  get fn() { return this.companyForm.controls; }  

  validateRights() {
    this.RightsError=false;
    this.fn.Monitoring.setErrors(null);
    var chkd = this.fn.Monitoring.value || this.fn.Scan.value;
    if (chkd != true) {
      this.fn.Monitoring.setErrors([null,[Validators.required]]);       
      this.RightsError=true;      
    }    
  }

  SetLicenceExpiryDate():void{  
    this.DefaultExpiryDate= new Date(new Date().setFullYear(new Date().getFullYear() + 1))  
    this.licence.LicenceExpiryDate = this.DefaultExpiryDate;  
  }
  GenerateAutoPass(): void {   
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 4;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
    }
    var defaultChar='C@r3'
    this.generatedPassword = defaultChar + randomstring;
    this.adminInfo.Password=this.generatedPassword;   
  }
  keyPress(event: any) {    
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  } 
  AvoidSpace(event) {
    var k = event ? event.which : event.keyCode;
    if (k == 32) return false;
  }
  InsertCompany(companyForm)  
  { 
    this.errorMessage=null; 
    this.validateRights();    
        if (this.companyForm.invalid) {         
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1].focus();        
          return;
        }          
        if (this.CompanyIdUpdate != "0") {
          this.companyInfo.CompanyId =atob(this.CompanyIdUpdate);  
        }         

        this.apiService.createCompany(this.companyInfo,this.adminInfo,this.role ,this.licence ).subscribe(  
          (data:any)=>  
          {
            if (data != null){
              if(data.Status != 1 && data.ErrorMessage !=""  ){
                this.errorMessage = data.ErrorMessage;
              }
              else{                                         
                if (this.CompanyIdUpdate == "0") {  
                  this.successMessage = 'Saved Successfully';  
                }  
                else  
                {  
                  this.successMessage = 'Updated Successfully';  
                }                   
                setTimeout(() => {
                  this.companyForm.reset(); 
                  this.successMessage = null;
                  this.router.navigate(['ManageCompany']);
                }, 1000);
              }
            }              
          },           
          err => {
            if (err.error) {
              this.errorMessage = err.error.ErrorMessage;
            }
          }      
        )  
  }       
  onFormSubmit(form) {          
      this.InsertCompany(form);  
  }   
  CompanyEdit(CompanyId: string,AdminId :string) {  
    $("#AdminUserSection").css("display","none");              
    var ComapnyData;       
    this.apiService.GetCompanyDetail(this.CompanyId,this.AdminId).subscribe(
      (Comp: any) => {  
        
      ComapnyData = Comp;
      
      if (ComapnyData.Status == '1'){
        this.CompanyIdUpdate=CompanyId;          
        let companyInfo=ComapnyData.CompanyDetail.companyInfo[0]
        let AdminInfo =ComapnyData.CompanyDetail.adminInfo[0]
        let Licence =ComapnyData.CompanyDetail.licence[0]
        let Role =ComapnyData.CompanyDetail.role[0]
        this.companyForm.controls['CompanyName'].setValue(companyInfo.CompanyName);  
        this.companyForm.controls['PhoneNumber'].setValue(companyInfo.PhoneNumber);  
        this.companyForm.controls['CompanyEmail'].setValue(companyInfo.CompanyEmail);  
        this.companyForm.controls['Country'].setValue(companyInfo.Country);  
        this.companyForm.controls['Address'].setValue(companyInfo.Address);  
        this.companyForm.controls['State'].setValue(companyInfo.State);  
        this.companyForm.controls['City'].setValue(companyInfo.City); 
        this.companyForm.controls['IsActive'].setValue(companyInfo.IsActive); 
      

        this.companyForm.controls['Monitoring'].setValue(Role.Monitoring);  
        this.companyForm.controls['Scan'].setValue(Role.Scan);           
        this.companyForm.controls['LicenceExpiryDate'].setValue(new Date(Licence.LicenceExpiryDate)); 

        this.companyForm.controls['AdminId'].setValue(AdminInfo.AdminId); 
        this.companyForm.controls['FirstName'].setValue(AdminInfo.FirstName);  
        this.companyForm.controls['LastName'].setValue(AdminInfo.LastName);  
        this.companyForm.controls['AdminEmail'].setValue(AdminInfo.Email);  
        this.companyForm.controls['Password'].setValue(AdminInfo.Password);  
        this.companyForm.controls['IsMailSend'].setValue(AdminInfo.IsMailSend);          
      }
    });           
  }

  autoCompleteAddress(){    
    let city: string; 
	  let country: string;	
    let state: string; 

    this.mapsAPILoader.load().then(() => {
     
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {          
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();          
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }          
          for(let i=0; i < place.address_components.length; i++ ){            
            if (place.address_components[i].types[0] == "locality"){
              city= place.address_components[i].long_name;
            }
            else if(place.address_components[i].types[0]== "administrative_area_level_1"){
              state= place.address_components[i].long_name;
            }
            else if(place.address_components[i].types[0]== "country"){
              country= place.address_components[i].short_name;
            }
          }
          this.companyForm.controls['Address'].setValue(place.formatted_address);  
          this.companyForm.controls['City'].setValue(city);
          this.companyForm.controls['State'].setValue(state);  
          this.companyForm.controls['Country'].setValue(country);                                   
        });
      });
    });

  }
 
}
