import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { Router , ActivatedRoute } from "@angular/router";
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-manage-company',
  templateUrl: './manage-company.component.html',
  styleUrls: ['./manage-company.component.css']
})
export class ManageCompanyComponent implements OnInit {

  CompanyList;
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  constructor(private orderPipe: OrderPipe,private router: Router, private apiService: UserService,private route: ActivatedRoute) { }
  SearchText: string='';
  order: string = 'CompanyName';
  reverse: boolean = false;
  
  setOrder(value: string) {
    if (this.order === value) { 
      this.reverse = !this.reverse;
    }
    this.order = value;
  
    this.CompanyList.sort((a, b) => {
      const dateA = this.parseTimestamp(a.Timestamp);
      const dateB = this.parseTimestamp(b.Timestamp);
  
      if (!dateA || !dateB) {
        return 0;
      }
  
      if (this.reverse) {
        return dateB.getTime() - dateA.getTime();
      } else {
        return dateA.getTime() - dateB.getTime();
      }
    });
    console.log('After sorting:', this.CompanyList);
  }
  
  parseTimestamp(timestampString: string): Date {
    const parts = timestampString.split(/[\s/:\-]+/);
    const month = parseInt(parts[0]);
    const day = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    const timeParts = parts[3].split(':');
    let hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    const ampm = parts[4].toUpperCase();
  
    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }
  
    return new Date(year, month - 1, day, hour, minute);
  }
  ngOnInit(): void {
    this.getCompanyList();   
  }
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  } 

  EditCompany(CompanyId: string, AdminId:string ) {                
    this.router.navigate(['AddNewCompany'], {
      queryParams: {        
        "CompanyId": btoa(CompanyId), "AdminId": btoa(AdminId)
      }
    });    
  }
  
  ManageAdminUser(CompanyId: string,CompanyName:string) {            
    this.router.navigate(['AdminUserList'], {
      queryParams: {        
        "CompanyId": btoa(CompanyId),
        "CompanyName": btoa(CompanyName),
        "redirectFrom": btoa('ManageCompany')
      }
    });    
  }

  getCompanyList(){            
    this.apiService.GetCompanyList().subscribe((data)=>{    
    if(data){
    //if(data.Status == 1 ){    
    this.CompanyList = data['UserList'];
     
    //}
   }
  }); 
}
SetActiveInactive(event,CompanyId: string,IsActive : boolean){      
  this.errorMessage=null;
  let status= IsActive ? "Activate" : "Deactivate";
  if(confirm("Are you sure you want to " + status + " this Company?")) {  
  this.apiService.SetActiveInactive(CompanyId,IsActive).subscribe(
    (data:any)=>  
            {
              if (data != null){
                if(data.Status != 1 && data.ErrorMessage !=""  ){
                  this.errorMessage = data.ErrorMessage;
                }
                else{                                  
                  this.getCompanyList();                            
                }                
              }              
            },           
            err => {
              if (err.error) {
                this.errorMessage = err.error.ErrorMessage;
              }
          }
      )
    }
    else{     
      event.target.checked=  IsActive? false : true;      
    }
 }

}
