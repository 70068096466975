import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-push-notification-list',
  templateUrl: './push-notification-list.component.html',
  styleUrls: ['./push-notification-list.component.css']
})
export class PushNotificationListComponent implements OnInit {

  pagedItems: Array<any>;

  constructor(private router: Router,private route: ActivatedRoute, private apiService: UserService) { }
  SearchText: string='';
  notifications=[];
  order: string = 'PushDate';
  reverse: boolean = false;
  

  ngOnInit(): void
  {
  this.getPushNotificationList();
    
  }
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;  
} 

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  
  AddPushNotification(){
    this.router.navigate(['PushNotification'], {
      queryParams: {        
        // "CompanyId": this.CompanyId, 
        // "CompanyName" : btoa(this.CompanyName),
        // "redirectFrom" : btoa(this.redirectFrom)
      }
    });    
  }
  
  getPushNotificationList(){    
    let SearchKey=btoa(this.SearchText? this.SearchText : "");
       
    let PageNo=1;
    this.apiService.getPushNotificationList(SearchKey,PageNo).subscribe((data)=>{    
    if(data){
    console.log(PageNo);
    //if(data.Status == 1 ){
    //console.log(data);    
    this.notifications = data['AppHistoryList'];
    //}
   }
  }); 


}
}
