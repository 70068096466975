import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { NgSelectModule } from "@ng-select/ng-select";


import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from '@angular/material/radio'; 
import {MatInputModule } from '@angular/material/input'; 
import {MatSelectModule } from '@angular/material/select'; 
import {MatFormFieldModule} from '@angular/material/form-field'; 
import { MatNativeDateModule } from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';  
import { AppRoutingModule } from './app-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {UserSignInService } from './Services/user-sign-in.service'
import { JwPaginationModule  } from 'jw-angular-pagination';  
import {NgxPaginationModule} from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { UserSignInComponent } from './Component/user-sign-in/user-sign-in.component';
import { UserListDashboardComponent } from './Component/user-list-dashboard/user-list-dashboard.component';
import { PageNotFoundComponent } from './Component/page-not-found/page-not-found.component';
import { UserHealthDashboardComponent } from './Component/user-health-dashboard/user-health-dashboard.component';
import { HeaderComponent } from './Component/header/header.component';
import { FooterComponent } from './Component/footer/footer.component';
// Firebase
import { AngularFireModule  } from '@angular/fire';
import { AngularFireDatabaseModule  } from '@angular/fire/database';
import { AngularFirestoreModule  } from '@angular/fire/firestore'; 
import { environment } from '../environments/environment';
import { HighchartsChartModule } from 'highcharts-angular';
import { SetNewPasswordComponent } from './Component/set-new-password/set-new-password.component';
import { ManageCompanyComponent } from './Component/manage-company/manage-company.component';
import { NewCompanyComponent } from './Component/new-company/new-company.component';
import { DatePipe } from '@angular/common'
import { OrderModule } from 'ngx-order-pipe';
import { AdminUserListComponent } from './Component/admin-user-list/admin-user-list.component';
import { NewAdminUserComponent } from './Component/new-admin-user/new-admin-user.component';
import { MyProfileComponent } from './Component/my-profile/my-profile.component';
import { BranchListComponent } from './Component/branch-list/branch-list.component';
import { NewBranchComponent } from './Component/new-branch/new-branch.component';
import { EmployeeListComponent } from './Component/employee-list/employee-list.component';
import { NgQRCodeReaderModule } from 'ng2-qrcode-reader';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { CorporateDashboardComponent } from './Component/corporate-dashboard/corporate-dashboard.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { BranchEmployeeListComponent } from './Component/branch-employee-list/branch-employee-list.component';
import { AvatarModule } from 'ngx-avatar';
import { CompanyDetailsComponent } from './Component/company-details/company-details.component';
import { ServiceDashboardComponent } from './Component/service-dashboard/service-dashboard.component';
import { UserHealthStatusComponent } from './Component/user-health-status/user-health-status.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxSpinnerModule } from "ngx-spinner";  
import { FirmwareSettingComponent } from './Component/firmware-setting/firmware-setting.component';
import { PushNotificationComponent } from './Component/push-notification/push-notification.component';
import { PushNotificationListComponent } from './Component/push-notification-list/push-notification-list.component';
import { CorporatePushNotificationComponent} from './Component/corporate-push-notification/corporate-push-notification.component';
import { CorporatePushNotificationListComponent } from './Component/corporate-push-notification-list/corporate-push-notification-list.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ChallengesComponent } from './Component/challenges/challenges.component';
import { ReportsComponent } from './reports/reports.component';
import { ParticipantslistComponent } from './participantslist/participantslist.component';
import {MatSortModule} from '@angular/material/sort';
import { UserService } from './Services/user.service';
import {MatIconModule} from '@angular/material/icon';
import { EditchallengesComponent } from './editchallenges/editchallenges.component';
 
 

// import { JwPaginationComponent } from 'path-to-jw-pagination-component'; // Import the component




 


// import { FormsModule } from '@angular/forms';
@NgModule({   
  declarations: [
    AppComponent,
    UserSignInComponent,
    UserListDashboardComponent,
    PageNotFoundComponent,
    UserHealthDashboardComponent,
    HeaderComponent,
    FooterComponent,
    SetNewPasswordComponent,
    ManageCompanyComponent,
    NewCompanyComponent,
    AdminUserListComponent,
    NewAdminUserComponent,
    MyProfileComponent,
    BranchListComponent,
    NewBranchComponent,
    EmployeeListComponent,    
    CorporateDashboardComponent,
    BranchEmployeeListComponent,
    CompanyDetailsComponent,
    ServiceDashboardComponent,
    UserHealthStatusComponent,
    FirmwareSettingComponent,
    PushNotificationComponent,
    PushNotificationListComponent,
    CorporatePushNotificationComponent,
    CorporatePushNotificationListComponent,
    ChallengesComponent,
    ParticipantslistComponent,
    EditchallengesComponent,
    MyProfileComponent,
    ReportsComponent,
    
    
    
              
  ],
  imports: [
    MatSnackBarModule,
    NgSelectModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    Ng2OrderModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig,'CareAtWork'),
    AngularFireDatabaseModule ,
    AngularFirestoreModule ,
    HighchartsChartModule,
    JwPaginationModule,
    NgxPaginationModule,
    MatButtonModule,       
    MatRadioModule,  
    MatDatepickerModule,  
    MatFormFieldModule,  
    MatInputModule,
    //MatMenuModule, 
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatSelectCountryModule,
    MatSelectModule,
    OrderModule,
    AngularFontAwesomeModule,
    AgmCoreModule.forRoot(  {
     apiKey: 'AIzaSyBNL_CTNBb6z97FwNIvxXRE3f7KMMuhQcM',//google api key for auto fill address
	   libraries: ["places"]
    }),
    NgQRCodeReaderModule,
    NgQrScannerModule,
    SlickCarouselModule,
    TagCloudModule,
    AvatarModule,
    QRCodeModule,
    NgxSpinnerModule,
    AngularMultiSelectModule,
    //MatIconModule, 
    //MatCardModule,
    //MatSidenavModule,
    //MatTooltipModule,  
    //MatToolbarModule,
    MatIconModule,
    
   ],
  providers: [UserSignInService,UserService,{provide : LocationStrategy , useClass:HashLocationStrategy},MatFormFieldModule,MatDatepickerModule,DatePipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }
