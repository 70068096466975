import { Component, OnInit,ElementRef, ViewChild, Renderer2,VERSION } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { Employee } from '../../Class/Employee/Employee'
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
// import * as $ from 'jquery';
import { analytics } from 'firebase';
import {QrScannerComponent} from 'angular2-qrscanner';
import { JsonPipe } from '@angular/common';
import { event } from 'jquery';

declare var $ :any;

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  styleUrls: ['./service-dashboard.component.css']
})
export class ServiceDashboardComponent implements OnInit {
  showModalBox: boolean = false;
  elementType = 'url';  
  public imagePath;  
  QRValue : any;  
  @ViewChild('result') resultElement: ElementRef;  
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
  showQRCode: boolean = false; 
  QRData:any; 
  canvasHidden:boolean=true;
   
  constructor(private renderer: Renderer2,private orderPipe: OrderPipe,private router: Router, private apiService: UserService,private formBuilder: FormBuilder,private route: ActivatedRoute) {     }

  UserList;
  BranchList;
  // EmployeeForm:FormGroup;
  employeeInfo: Employee =new Employee();
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  EMPsuccessMessage:string;
  EMPerrorMessage:string;
  CompanyId:string;  
  AdminId:string;
  CompanyName;
  SearchText: string='';
  order: string = 'ScanDate';
  reverse: boolean = false;
  
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  

  ngOnInit(): void {           
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.AdminId= atob(sessionStorage.getItem("uid"));
    this.GetUserHistoryList(this.CompanyId,this.AdminId);     
  }
    
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  }  
  ShowUserHealthDetails(UID:string, UserName:string) {           
    this.router.navigate(['../UserHealthStatus'], {
      queryParams: {        
        "UID": UID, "User": encodeURIComponent(UserName)
      }
    });    
  }   
  GetUserHistoryList(CompanyId:string,AdminId:string){              
    this.apiService.GetUserHistoryList(CompanyId,AdminId).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.UserList = data['ScanHistoryList'];
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
SaveEmployee()  
  {     
        this.EMPerrorMessage=null;
        // if (this.EmployeeForm.invalid) {         
        //     let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        //     invalidFields[1].focus();        
        //   return;
        // }
        if(!this.QRData){          
          this.EMPerrorMessage="Please Scan your QR Code";          
          return;
          if (!this.QRData.UID){
            this.EMPerrorMessage="Please Scan your QR Code";            
            return;
          }  
        }       
        this.employeeInfo.UID=this.QRData.UID
        this.employeeInfo.CompanyId=this.CompanyId        
        this.employeeInfo.AdminId= this.AdminId
        
        this.apiService.createServiceUser(this.employeeInfo).subscribe(  
          (data:any)=>  
          {
            if (data != null){
              if(data.Status != 1 && data.ErrorMessage !=""  ){
                this.EMPerrorMessage = data.ErrorMessage;
              }
              else{                                       
               // window.location.reload(); 
                //this.EmployeeForm.reset();                                   
                  // this.EMPsuccessMessage="Employee enrolled Sucessfully"
                  // setTimeout(() => {
                  //   this.EmployeeForm.reset(); 
                  //   this.EMPsuccessMessage = null;
                  //   window.location.reload();                    
                  // }, 1000);
              }
            }              
          },           
          err => {
            if (err.error) {
              this.EMPerrorMessage = err.error.ErrorMessage;
            }
          }      
        )  
  }
 
  // For QR Code start

  preview(files) {              
    this.QRValue=null; 
    this.QRData=null; 
    if (files.length === 0)  
      return;  
    var mimeType = files[0].type;  
    if (mimeType.match(/image\/*/) == null) {  
      alert("Only images are supported.");        
      return;  
    }  
    var reader = new FileReader();  
    reader.readAsDataURL(files[0]);  
    reader.onload = (_event) => {                    
      this.QRValue = reader.result;  
      //console.log(reader.result);  
     //this.showQRCode = true;  
    }  
  }  
  render(e) {              
    let data= e.result
    console.log(data)    
    if(data.indexOf('UID') == -1 && data.indexOf('FullName') == -1){        
        this.ShowHideErrorForUpload();
        this.QRValue=null;                        
        return;
    }    
    this.QRData=JSON.parse(data);  
    this.SaveEmployee();
    this.closeModal();
    this.ShowUserHealthDetails(this.QRData.UID, this.QRData.FullName);    
  }    
  //For Qr Code end

  //For Qr Code camera

ScanQRCode(){    
  $("#uploadfile").val("");
  $("#uploadfile").show();
  $("#QRScanner").show();
  $("#CameraQRCodeInvalid").hide();
  $("#UploadQRCodeInvalid").hide(); 
  $("#NoCameraFound").hide();
  this.EMPerrorMessage=null;
  this.QRData=null; 
  this.qrScannerComponent.getMediaDevices().then(devices => {    
    this.qrScannerComponent.videoWrapper.nativeElement.hidden=false;
    this.qrScannerComponent.qrCanvas.nativeElement.autofocus=true;    
    const videoDevices: MediaDeviceInfo[] = [];
    for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
            videoDevices.push(device);
        }
    }
    if (videoDevices.length > 0){
        let choosenDev;
        for (const dev of videoDevices){
            if (dev.label.includes('front')){
                choosenDev = dev;
                break;
            }
        }
        if (choosenDev) {
            this.qrScannerComponent.chooseCamera.next(choosenDev);
        } else {
            this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
        }
    }
    else{
      this.NoCameraFound();
      return;
    }
  });
  this.qrScannerComponent.capturedQr.subscribe(result => {                
      let data= result
      console.log(data)
      if(data.indexOf('UID') == -1 && data.indexOf('FullName') == -1){          
         this.InvalidQRCode();              
          return;
      }      
      this.QRData=JSON.parse(data);       
      this.qrScannerComponent.videoWrapper.nativeElement.hidden=true                 
      this.SaveEmployee();
      this.closeModal();
      this.ShowUserHealthDetails(this.QRData.UID, this.QRData.FullName);
  });
}
//For Qr Code camera  
StartScaning(){  
  $("#CameraQRCodeInvalid").hide();
  $("#NoCameraFound").hide();
  //document.getElementById("ScanQRCode").click();  
}
InvalidQRCode(){  
  $("#CameraQRCodeInvalid").show();
  $("#QRScanner").hide();  
}
NoCameraFound(){
  $("#NoCameraFound").show();
}
ShowHideErrorForUpload(){  
  $("#uploadfile").val("");
  $("#uploadfile").toggle();
  $("#UploadQRCodeInvalid").toggle();  
}
HideScanner(){
  $("#CameraQRCodeInvalid").hide();
  $("#UploadQRCodeInvalid").hide();  
  $("#NoCameraFound").hide();
  $("#uploadfile").val("");
  $("#QRScanner").hide();
}
closeModal(){      
  this.HideScanner();
  $("#EnrollEmployeeModal").modal('hide'); 
}

}
