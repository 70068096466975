import { Component, OnInit,AfterViewInit} from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap  } from "@angular/router";
import { observable } from 'rxjs';
import { map, timestamp } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSpinnerService } from "ngx-spinner";  
import { Console, debug } from 'console';
import { data } from 'jquery';
import { ChangeDetectorRef } from '@angular/core';
import { Data } from '../../../Scripts/index';

 

import HC_exporting from 'highcharts/modules/exporting';
import HC_data from 'highcharts/modules/data';
HC_exporting(Highcharts);
HC_data(Highcharts);
import { Options } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import fullscreen from 'highcharts/modules/full-screen';
 
declare var $:any;

declare var require: any;

declare const CreateChart :any;
declare const CreateArrayForData:any;
declare const ReCreateBodyTemperatureChart:any;
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

@Component({
  selector: 'app-user-health-dashboard',
  templateUrl: './user-health-dashboard.component.html',
  styleUrls: ['./user-health-dashboard.component.css']
  
})
export class UserHealthDashboardComponent implements OnInit { 
  
  // new changes
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  calorieschartOptions:Highcharts.Options = {};
  DistancechartOptions: Highcharts.Options = {}; 

  matchingStepValue: number | undefined;
  matchingCalorieValue:number | undefined;
  matchingDistanceValue:number | undefined;
  highcharts = Highcharts;
  
  ChartDataFromDate = Date.now();
  ChartDataToDate;
  term: string;
  users;//=User;
  UserName: string ;
  HealthStatus:string;
  UID : string;  
  ApiUserHealthData ; 
  TravelHistoryList;
  VaccinationTestDetail;  
  checked=false;
  errorMessage: string;
  StatusToShow:string;
  infoToShow:string;
  calvalue: any;
  rating: string;
  
  reason: string;
  ActivityData: any;
  FromDate: Date;
  ToDate: Date;
  AnotherData: any;
  caloriesDetails: any[] = [];
   
   
  step: any[]=[];
entry: any;
  distanceDetails: any;
  stepDetails: any;
  distanceValue: any;
  stepValue: any;
  noDataAvailable: boolean;
  // calorieschartOptions: Highcharts.Options;
  // DistancechartOptions: Highcharts.Options;
  cgenerateData: any;
  dgenerateData: any;
  CalorieValue: any;
  DistanceValue: any;
  chartType: string = 'DayWise';
  chart: any;
  
   

  constructor(private SpinnerService: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef , private router: Router, private route : ActivatedRoute , private apiService: UserService ,private fireStore : AngularFirestore) {     
     
  }


 
  ngOnInit() {   
     
    // new changes 
    const startDate = new Date();
    const endDate = new Date();

    this.init(startDate, endDate,[],[],[]);
    // this.init(startDate, endDate,  [] ,[], []);
    // this.init(startDate, endDate, [],  [], []);
    
   

  //   $(document).ready(function() {
  //     debugger;
  //     setTimeout(()=> {
  //          $("#statusbtn").tooltip();
  //         console.log("Tooltip Loaded");
  //      }
  //      ,2000); 
  // });
    
    console.log('this.reason:', this.reason);
      
    this.UID = this.route.snapshot.queryParams.UID; 
    this.UserName  = decodeURIComponent(this.route.snapshot.queryParams.User); 
    this.HealthStatus= atob(this.route.snapshot.queryParams.HealthStatus);
    this.GetApiUserHealthData(this.UID,'DayWise'); 
      
    if(this.HealthStatus == 'Risk'|| this.HealthStatus == 'Red')
    {
      this.StatusToShow = 'At Risk';
      this.infoToShow='true';
     
    }
    else if(this.HealthStatus == 'Cautious'|| this.HealthStatus =='Orange')
    {
      this.StatusToShow  = 'At Caution';
      this.infoToShow='true';
     
    }
    else if(this.HealthStatus == 'Normal'|| this.HealthStatus == 'Green')
    {
      this.StatusToShow = 'Reading Normal';
      this.infoToShow='false';
      
    }
    else
    {
      this.StatusToShow = 'No Status Available';
      this.infoToShow='false';
     
    }

 
    
    this.ChartDataFromDate = Number($("#ChartDataFromDate").text());       
    this.ChartDataToDate=Number($("#ChartDataToDate").text());
    this.getTravelHistoryList(this.UID);
    this.getVaccinationTestDetails(this.UID);
   
    // this.SetupTooltip();
   }
  
  //  SetupTooltip(){
  //     const button = document.querySelector('#statusbtn');
    
  //  }

  //  CreateChart(DataFor):void{
         
  //    this.checked=false;     
  //   this.GetApiUserHealthData(this.UID,DataFor);  
    
  //   this.ChartDataFromDate = Number($("#ChartDataFromDate").text());       
  //   this.ChartDataToDate=Number($("#ChartDataToDate").text());
    
  //  }  



  CreateChart(DataFor: string): void {
    this.checked = false;
    this.GetApiUserHealthData(this.UID, DataFor);

    this.ChartDataFromDate = Number($("#ChartDataFromDate").text());
    this.ChartDataToDate = Number($("#ChartDataToDate").text());

    // Add logic to set chartType based on DataFor
    if (DataFor === 'DayWise') {
        this.chartType = 'DayWise';
    } else if (DataFor === 'WeekWise') {
        this.chartType = 'WeekWise';
    } else if (DataFor === 'MonthWise') {
        this.chartType = 'MonthWise';
    }
}






   


 
















   
   getUserList(){  
    
    let SearchKey=btoa(this.term? this.term : "");       
    let PageNo=0;
    this.apiService.getUserList(SearchKey,PageNo).subscribe((data)=>{    
    if(data){
    
    this.router.navigate(['UserListDashboard']);
    this.users = data['UserList'];    
    //}
   }
  }); 
}

get sortData() {
  if(this.TravelHistoryList){
  return this.TravelHistoryList.sort((a, b) => {
    return <any>new Date(b.DepartureDate) - <any>new Date(a.DepartureDate);
  });
}
}
get sortVaccinationData() {
  if(this.VaccinationTestDetail){
  return this.VaccinationTestDetail.sort((a, b) => {
    return <any>new Date(b.Date) - <any>new Date(a.Date);
  });
//  return this.VaccinationTestDetail.sort((a,b) => a.Date.localeCompare(b.Date));
}
}

getTravelHistoryList(UID:string){            
  this.apiService.getTravelHistoryList(UID).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.TravelHistoryList = data['TravelHistory'];         
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
}

getVaccinationTestDetails(UID:string){            
  this.apiService.getVaccinationTestDetails(UID).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != 1 && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.VaccinationTestDetail = data['VaccinationDetail'];         
        }
      }              
    },          
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
}





 



GetUserHealthFilter(DataFor:string):any{            
  var DateRange = [];
  var FromDate, ToDate;
  const options = { weekday :'short' , month : 'short', day : 'numeric', year : 'numeric', hour : '2-digit' , minute : '2-digit', second : '2-digit'}
  switch (DataFor) {
      case "DayWise" : {
          var d = new Date();
          var d1 = new Date();
          FromDate= d.setHours(0,0,0,0);
          ToDate= d1.setHours(23,59,0,0);    
          $("#ChartDataFromDate").text(new Date(FromDate).toDateString());
          $("#ChartDataToDate").text(new Date(ToDate).toDateString());        
          // DateRange.push(FromDate/1000);
          // DateRange.push(ToDate/1000);
          //  DateRange.push(new Date(FromDate));
          //  DateRange.push(new Date(ToDate));
          DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          break;
      }
      case "WeekWise": {
          var d = new Date();
          var d1= new Date();
          d.setDate( d.getDate() - 6 );  
          FromDate=d.setHours(0,0,0,0);            
          ToDate =d1.setHours(23,59,0,0)
          $("#ChartDataFromDate").text(new Date(FromDate).toDateString());
          $("#ChartDataToDate").text(new Date(ToDate).toDateString());               
          // DateRange.push(FromDate/1000);
          // DateRange.push(ToDate/1000);
          // DateRange.push(new Date(FromDate));
          // DateRange.push(new Date(ToDate));
          DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          break;
      }
      case "MonthWise": {
          var d = new Date();
          var d1= new Date();
          d.setDate( d.getDate() - 30 );  
          FromDate=d.setHours(0,0,0,0);            
          ToDate =d1.setHours(23,59,0,0)
          $("#ChartDataFromDate").text(new Date(FromDate).toDateString());
          $("#ChartDataToDate").text(new Date(ToDate).toDateString());        
          // DateRange.push(FromDate/1000);
          // DateRange.push(ToDate/1000);
          // DateRange.push(new Date(FromDate));
          // DateRange.push(new Date(ToDate));
          DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
          break;
      }     
      case "PreviousWise": {
          var TabSelect = this.TabSelected();  
        //   if(TabSelect=="MonthWise"){
        //     return false;
        // }
        let maxBackdate = new Date();
        maxBackdate.setDate( maxBackdate.getDate() - 30 );
        let maxBackdatee = new Date(maxBackdate).toDateString();
        var d = new Date($("#ChartDataFromDate").text());
        var d1= new Date($("#ChartDataToDate").text());  
        // if (d <= new Date(maxBackdatee)){                 
        //     return;
        // }                 
        $("#hdnFromDate").val(d);
        $("#hdnToDate").val(d1);                                   
        DateRange = this.DatesCalculate(TabSelect,DateRange,"Previous");            
        break;
      }     
      case "NextWise": {
        var TabSelect = this.TabSelected();
        // if(TabSelect=="MonthWise"){
        //   return false;
        // }
        var today=new Date().toDateString();
        var d = new Date($("#ChartDataFromDate").text());
        var d1= new Date($("#ChartDataToDate").text());                   
        if (d1 >= new Date(today)){                 
             return;
        }
        $("#hdnFromDate").val(d);
        $("#hdnToDate").val(d1);                          
          DateRange = this.DatesCalculate(TabSelect,DateRange, "Next");            
          break;
      }            
  }
  return DateRange;
}

DatesCalculate(TabSelected,DateRange,Type):any{    
const options = { weekday :'short' , month : 'short', day : 'numeric', year : 'numeric', hour : '2-digit' , minute : '2-digit', second : '2-digit'}
if (TabSelected=="DayWise"){
    var d = new Date($("#hdnFromDate").val());
    var d1= new Date($("#hdnToDate").val());
    if(Type == "Previous"){
        d.setDate( d.getDate() - 1 );
        d1.setDate( d1.getDate() - 1 );  
    }
    else{
        d.setDate( d.getDate() + 1 );
        d1.setDate( d1.getDate() + 1 );  
    }
    let FromDate=d.setHours(0,0,0,0);            
    let ToDate =d1.setHours(23,59,0,0)
    $("#ChartDataFromDate").text(new Date(d).toDateString());
    $("#ChartDataToDate").text(new Date(d1).toDateString());  

    DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
    DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
}
else if(TabSelected=="WeekWise"){
    var d = new Date($("#hdnFromDate").val());
    var d1= new Date($("#hdnToDate").val());
    if(Type == "Previous"){
        d.setDate( d.getDate() - 6 );
        d1.setDate( d1.getDate() - 6 );  
    }
    else{
        d.setDate( d.getDate() + 6 );
        d1.setDate( d1.getDate() + 6 );  
    }
    let FromDate=d.setHours(0,0,0,0);            
    let ToDate =d1.setHours(23,59,0,0)
    $("#ChartDataFromDate").text(new Date(d).toDateString());
    $("#ChartDataToDate").text(new Date(d1).toDateString());        
    // DateRange.push(FromDate/1000);
    // DateRange.push(ToDate/1000);        
    // DateRange.push(new Date(FromDate));
    // DateRange.push(new Date(ToDate));    
    DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
    DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
}
else{
  var d = new Date($("#hdnFromDate").val());
  var d1= new Date($("#hdnToDate").val());
  if(Type == "Previous"){
      d.setDate( d.getDate() - 30 );
      d1.setDate( d1.getDate() - 30 );  
  }
  else{
      d.setDate( d.getDate() + 30 );
      d1.setDate( d1.getDate() + 30 );  
  }
  let FromDate=d.setHours(0,0,0,0);            
  let ToDate =d1.setHours(23,59,0,0)
  $("#ChartDataFromDate").text(new Date(d).toDateString());
  $("#ChartDataToDate").text(new Date(d1).toDateString());        
 ;    
  DateRange.push(new Date(FromDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
  DateRange.push(new Date(ToDate).toLocaleDateString('en-GB',options).replace('Sept','Sep'))
}
return DateRange;
}
TabSelected(){    
    var TabSelected;
    if ($("#DayWise").hasClass("active") ) 
    {
    TabSelected="DayWise";
    }
    else if ($("#WeekWise").hasClass("active") ) 
    {
    TabSelected="WeekWise";
    }
    else{
    TabSelected="MonthWise";
    }
    return TabSelected;
}

 

GetApiUserHealthData(UId, DataFor) {
  var DateFilter;
  var userHealthData;
  var userProfile;
  var reason;
  this.SpinnerService.show();
  DateFilter = this.GetUserHealthFilter(DataFor);
  if (!DateFilter) {
    this.SpinnerService.hide();
    return false;
  }
  let FromDate = DateFilter[0];
  let ToDate = DateFilter[1];

  // Convert FromDate and ToDate to Date objects
  this.FromDate = new Date(FromDate);
  this.ToDate = new Date(ToDate);

  this.apiService.GetUserHealthReading(UId, FromDate, ToDate)
    .subscribe((res) => {
      userHealthData = res;
      if (userHealthData) {
        CreateArrayForData(userHealthData, DataFor);
        this.SpinnerService.hide();

 
        





      }
    });

   




  this.apiService.GetActivityData(UId, FromDate, ToDate)
  .subscribe((data: any) => {
    console.log("Raw API Response", data);

     
    this.stepDetails = data.Data.summary;
    this.caloriesDetails = data.Data.summary;
    this.distanceDetails = data.Data.summary;

    console.log("Calories Details", this.caloriesDetails);
    console.log("Distance Details", this.distanceDetails);
    console.log("Step Details", this.stepDetails);

    const filteredStepDetails = this.stepDetails.filter((item: { Type: string; TimeStamp: string; }) => item.Type === 'Step');
    const filteredcaloriesDetails = this.caloriesDetails.filter((item: { Type: string; TimeStamp: string; }) => item.Type === 'Calorie');
    const filtereddistanceDetails = this.distanceDetails.filter((item: { Type: string; TimeStamp: string; }) => item.Type === 'Distance');

   


    // Log the filtered results
    console.log(filteredStepDetails);
    console.log(filteredcaloriesDetails);
    console.log(filtereddistanceDetails);

    const startDate = this.FromDate;
    const endDate = this.ToDate;

    var stepsData,caloriesData,distanceData;


    if (filtereddistanceDetails.length > 0) {
      // Process 'Distance' items
      distanceData = filtereddistanceDetails.map(item => ({
        value: item.Value,
        timestamp: item.TimeStamp
      }));

      

    }


    

    if (filteredcaloriesDetails.length > 0) {
      // Process 'Distance' items
      caloriesData = filteredcaloriesDetails.map(item => ({
        value: item.Value,
        timestamp: item.TimeStamp
      }));

     

    }

    if (filteredStepDetails.length > 0) {
      // Process 'Distance' items
      stepsData = filteredStepDetails.map(item => ({
        value: item.Value,
        timestamp: item.TimeStamp
      }));
      
      console.log("Processed 'Step' values:", stepsData.map(step => step.value));
       

      console.log("StepsDetails: "+startDate+" - "+endDate);
 
    }
    // this.init(startDate, endDate, [], caloriesData, []);
    this.init(startDate, endDate, stepsData, caloriesData, distanceData);
    // this.init(startDate, endDate, [], [], distanceData);


// Log 'Step' values for the specific start date
 

 // Replace 'desiredDate' with the date you're looking for
const desiredDate = new Date(startDate);

// Find the object with the matching timestamp
const matchingStep = stepsData.find(step => {
  const stepDate = new Date(step.timestamp);
  return stepDate.toDateString() === desiredDate.toDateString();
});
 
if (matchingStep) {
  this.matchingStepValue = matchingStep.value;
} else {
  this.matchingStepValue = undefined;
}

 
const desiredDates = new Date(startDate);

// Find the object with the matching timestamp for caloriesData
const matchingCalories = caloriesData.find(calorie => {
  const caloriesDate = new Date(calorie.timestamp);
  return caloriesDate.toDateString() === desiredDates.toDateString();
});

// Access the 'Calories' value if a matching object is found
if (matchingCalories) {
  this.matchingCalorieValue = matchingCalories.value;
  console.log(`Calories value for ${desiredDates.toDateString()}:`, this.matchingCalorieValue);
} else {
  this.matchingCalorieValue = undefined;
  console.log(`No 'Calories' item found for ${desiredDates.toDateString()}`);
}

 // Find the object with the matching timestamp for distanceData
const matchingDistance = distanceData.find(distance => {
  const distanceDate = new Date(distance.timestamp);
  return distanceDate.toDateString() === desiredDates.toDateString();
});

// Access the 'Distance' value if a matching object is found
if (matchingDistance) {
  this.matchingDistanceValue = matchingDistance.value;
  console.log(`Distance value for ${desiredDates.toDateString()}:`, this.matchingDistanceValue);
} else {
  this.matchingDistanceValue = undefined;
  console.log(`No 'Distance' item found for ${desiredDates.toDateString()}`);
}


 

  });


    

  this.apiService.GetUserProfile(UId).subscribe(
    (response) => {
      userProfile = response;
      console.log(userProfile);

      this.calculateRating(userProfile.UserProfileInfo.LastHealthRiskRating);
      this.reason = userProfile.UserProfileInfo.ReasonToShow;
      debugger;
      console.log(this.reason);
      this.changeDetectorRef.detectChanges();

      setTimeout(() => {
        const statusButton = document.getElementById('statusbtn');
        if (statusButton) {
          statusButton.setAttribute('title', this.reason);
          console.log("tooltip loaded");
        }
        $("#statusbtn").tooltip();
      }, 2000);
    },
    (error) => {
      console.error('Error fetching data:', error);
    }
  );
}
 
init(startDate: Date, endDate: Date, stepData: any, caloriesData: any, distanceData: any) {
  const stepChartData = this.generateData(startDate, endDate, stepData);
  const caloriesChartData = this.generateData(startDate, endDate, caloriesData);
  const distanceChartData = this.generateData(startDate, endDate, distanceData);

console.log("jgyfy",stepChartData)
  //const options: Options[] = [];
  Highcharts.getOptions().exporting.menuItemDefinitions.viewData = null;

  // Chart configuration for Steps
  const stepsChart: Options = {
    chart: {
      type: 'column',
      backgroundColor: 'white',
    },
    title: {
      text: ' ',
      style: {
        color: 'black',
      },
    },
    xAxis: {
      categories: stepChartData.map((day) => `${day.day} ${this.getMonthName(day.month)}`),
      title: {
        // text: 'Date',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Value',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          color: 'white',
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Steps',
        data: stepChartData.map((day) => day.value),
        color: '#00a5ba',
      },
    ],
    tooltip: {
      formatter: function () {
        return `<b>Steps</b><br/>${this.x}: ${this.y}`;
      },
    },
    legend: {
      enabled: false, // Hide the legend
    },
  };

  
  const caloriesChart: Options = {
    chart: {
      type: 'column',
      backgroundColor: 'white',
    },
    title: {
      text: ' ',
      style: {
        color: 'black',
      },
    },
    xAxis: {
      categories: caloriesChartData.map((day) => `${day.day} ${this.getMonthName(day.month)}`),
      title: {
        // text: 'Date',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Value',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          color: 'white',
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Calories',
        data: caloriesChartData.map((day) => day.value),
        color: '#f25504',
      },
    ],
    tooltip: {
      formatter: function () {
        return `<b>Calories</b><br/>${this.x}: ${this.y}`;
      },
    },
    legend: {
      enabled: false, // Hide the legend
    },
  };

  //options.push(caloriesChart);

  // Chart configuration for Distance
  const distanceChart: Options = {
    chart: {
      type: 'column',
      backgroundColor: 'white',
    },
    title: {
      text: ' ',
      style: {
        color: 'black',
      },
    },
    xAxis: {
      categories: distanceChartData.map((day) => `${day.day} ${this.getMonthName(day.month)}`),
      title: {
        // text: 'Date',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Value',
        style: {
          color: 'black',
        },
      },
      labels: {
        style: {
          color: 'black',
        },
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          color: 'white',
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Steps',
        data: distanceChartData.map((day) => day.value),
        color: '#6964ff',
      },
    ],
    tooltip: {
      formatter: function () {
        return `<b>Distance</b><br/>${this.x}: ${this.y}`;
      },
    },
    legend: {
      enabled: false, // Hide the legend
    },
  };

 

  //options.push(distanceChart);
  this.calorieschartOptions = caloriesChart;
  this.chartOptions = stepsChart;
  this.DistancechartOptions = distanceChart;
   
  
}

 

onResize() {
  if (this.chart && this.chart.chart) {
    setTimeout(() => {
      this.chart.chart.reflow();
    }, 0);
  }
}

generateData(startDate: Date, endDate: Date, rawData: any): any[] {
  const data = [];
  let currentDate = new Date(startDate);
console.log("rawdata",rawData);
  while (currentDate.getTime() <= endDate.getTime()) {
    const matchingData = Array.isArray(rawData) && rawData.find((item) => {
      const timestampDate = new Date(item.timestamp);

      // Compare the full date, including year, month, and day
      return (
        currentDate.getFullYear() === timestampDate.getFullYear() &&
        currentDate.getMonth() === timestampDate.getMonth() &&
        currentDate.getDate() === timestampDate.getDate()
      );
    });

    // Push data with value 0 if matchingData is not available
    data.push({
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      value: matchingData ? matchingData.value : 0,
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return data;
}

getMonthName(month: number): string {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[month - 1];
}

 
// new changes last above


  calculateRating(rating){
    this.rating = rating;
    console.log(rating);
    if (rating == 'Risk' || rating == 'Red')
    {
      this.StatusToShow = 'At Risk';
      this.infoToShow='true';
    }
    else if(rating == 'Cautious' || rating == 'Orange')
    {
      this.StatusToShow  = 'At Caution';
      this.infoToShow='true';
     
    }
    else if (rating == 'Normal' || rating == 'Green')
    {
      this.StatusToShow = 'Reading Normal';
      this.infoToShow='false';
      
    }
    else
    {
      this.StatusToShow = 'No Status Available';
      this.infoToShow='false';
     
    }
  }

   
   
 




























  TempratureConversion(event){
    
    var ShowChartIn='';
    if(event.target.checked){
      ShowChartIn='Fahrenheit';
      this.checked=true;
    }
    else{
      ShowChartIn='Celsius';
      this.checked=false;
    }
    var TabSelect = this.TabSelected(); 
    ReCreateBodyTemperatureChart(TabSelect,ShowChartIn);
    return true;
  }
 

}

function getActivityData() {
  throw new Error('Function not implemented.');
}
