import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
declare var $ :any;

@Component({ 
  selector: 'app-corporate-push-notification-list',
  templateUrl: './corporate-push-notification-list.component.html',
  styleUrls: ['./corporate-push-notification-list.component.css']
})
export class CorporatePushNotificationListComponent implements OnInit {
  CompanyId:string;
  pagedItems: Array<any>;
  modal:any;

  constructor(private router: Router,private route: ActivatedRoute, private apiService: UserService) { }
  SearchText: string='';
  notifications=[];
  order: string = 'PushDate';
  reverse: boolean = false;

  

  ngOnInit(): void
  {
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.CorporategetPushNotificationList(this.CompanyId);
    
    $("#bigform").modal('hide'); 
    
  }
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;  
} 

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  
  AddPushNotification(){
    this.router.navigate(['CorporatePushNotification'], {
      queryParams: {        
        // "CompanyId": this.CompanyId, 
        // "CompanyName" : btoa(this.CompanyName),
        // "redirectFrom" : btoa(this.redirectFrom)
      }
    });    
  }
  
  CorporategetPushNotificationList(CompanyId:string){    
    let SearchKey=btoa(this.SearchText? this.SearchText : "");
       
    let PageNo=1;
    this.apiService.CorporategetPushNotificationList(SearchKey,PageNo,CompanyId).subscribe((data)=>{    
    if(data){
    //console.log(data);
    //if(data.Status == 1 ){
    //console.log(data);    
    this.notifications = data['AppHistoryList'];
    //}
   }
  }); 


}
}
