import { Component, OnInit  ,  ElementRef, Inject } from '@angular/core';
import {UserSignInService} from '../../Services/user-sign-in.service';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

declare var $ :any;
declare var customjs_On_Load : any 
declare var call_WaveJS: any

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  LoginUserName: string;
  LoginEmail : string;
  CompanyName:string;

  constructor(private elementRef: ElementRef,@Inject(DOCUMENT) private doc,private UserService : UserSignInService, private router:Router ) { }

    ngOnInit() {        
        if(localStorage.getItem("rememberMe")){
            this.LoginUserName= atob(localStorage.getItem("userName"));
            this.LoginEmail= atob(localStorage.getItem("emailAddress"));   
        }
        else{
            this.LoginUserName= atob(sessionStorage.getItem("userName"));
            this.LoginEmail= atob(sessionStorage.getItem("emailAddress"));   
        }
        this.CompanyName=atob(sessionStorage.getItem("CompanyName"));
        this.callCustomeJS();   
        this.CustomeJsCode(); 
        this.wavejs();
        this.showHideMenu();   
    }

    callCustomeJS(){
        customjs_On_Load();
    }
  
    CustomeJsCode(){
        jQuery(document).on('click', '.mega-dropdown', function(e) {
            e.stopPropagation()
            });
        
            $(".nav-lock").on('click', function() {                
                $("body").toggleClass("lock-nav");
                $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
                $("body, .page-wrapper").trigger("resize");
            });
        
        
            $(".sidebartoggler").on('click', function() {
                $("body").toggleClass("mini-sidebar");
            });
        
            $(".nav-toggler").on('click', function() {
                $("body").toggleClass("show-sidebar");
        
                $(".nav-toggler i").toggleClass("ti-menu");
            });
        
            $(".search-box a, .search-box .app-search .srh-btn").on('click', function() {
                $(".app-search").toggle(200);
                $(".app-search input").focus();
            });
            $(".right-side-toggle").click(function() {
                $(".right-sidebar").slideDown(50);
                $(".right-sidebar").toggleClass("shw-rside");
            });
        
            $('.floating-labels .form-control').on('focus blur', function(e) {            
                $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || $(this).val.length > 0));
            }).trigger('blur');
        
            $(function() {
                $('[data-toggle="tooltip"]').tooltip()
            })
        
            $(function() {
                $('[data-toggle="popover"]').popover()
            })
        
            $('.scroll-sidebar, .right-side-panel, .message-center, .right-sidebar').perfectScrollbar();
        
            $("body, .page-wrapper").trigger("resize");
        
            $(".list-task li label").click(function() {
                $(this).toggleClass("task-done");
            });
        
            $('a[data-action="collapse"]').on('click', function(e) {
                e.preventDefault();
                $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
                $(this).closest('.card').children('.card-body').collapse('toggle');
            });
        
            $('a[data-action="expand"]').on('click', function(e) {
                e.preventDefault();
                $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
                $(this).closest('.card').toggleClass('card-fullscreen');
            });
        
            $('a[data-action="close"]').on('click', function() {
                $(this).closest('.card').removeClass().slideUp('fast');
            });
            
            $("#themecolors").on("click", "a", function() {
                $("#themecolors li a").removeClass("working"),
                    $(this).addClass("working")
            }) 
    }
    wavejs(){
            $('#sidebarnav a').on('click', function (e) {         
                if (!$(this).hasClass("active")) {
                    // hide any open menus and remove all other classes
                    $("ul", $(this).parents("ul:first")).removeClass("in");
                    $("a", $(this).parents("ul:first")).removeClass("active");
                    
                    // open our new menu and add the open class
                    $(this).next("ul").addClass("in");
                    $(this).addClass("active");                 
                }
                else if ($(this).hasClass("active")) {
                    $(this).removeClass("active");
                    $(this).parents("ul:first").removeClass("active");
                    $(this).next("ul").removeClass("in");
                }
        })
        $('#sidebarnav >li >a.has-arrow').on('click', function (e) {
            e.preventDefault();
        });
    }    
    showHideMenu(){  
        let Monitoring=sessionStorage.getItem("Monitoring");
        let Scaning=sessionStorage.getItem("Scan");
        let UserType=sessionStorage.getItem("UserType");
        
        console.log(UserType);


        if (Monitoring == "true" && Scaning == "false" && UserType==""){    
            $("#reports").hide();       
            $("#ManageCompany").hide();
            $("#ServiceProDashboard").hide();            
            $("#AdminDashboard").hide();  
            $("#ScanHistory").hide(); 
            $("#FirewareSetting").hide();
            $("#PushNotificationList").hide();           
        }
        else if (Scaning == "true" && Monitoring == "false" && UserType==""){
            $("#CorporateDashboard").hide();
            $("#reports").hide();  
            $("#ManageCompany").hide(); 
            $("#ManageBranch").hide();  
            $("#ManageEmployee").hide();
            $("#AdminDashboard").hide();  
            $("#ScanHistory").hide(); 
            $("#FirewareSetting").hide();
            $("#PushNotificationList").hide();   
        }
        else if (Scaning == "true" && Monitoring == "true" && UserType==""){
            $("#reports").hide();  
            $("#ManageCompany").hide();
            $("#ServiceProDashboard").hide();            
            $("#AdminDashboard").hide();
            $("#FirewareSetting").hide();
            $("#PushNotificationList").hide();

            // $("#CorporatePushNotificationList").hide();
              
        }
        else{
            $("#CorporatePushNotificationList").hide();
            $("#ServiceProDashboard").hide(); 
            $("#ServiceProDashboard").html("Service Dashboard <i class='ti-dashboard'></i>");     
            $("#CorporateDashboard").html("Corporate Dashboard <i class='ti-dashboard'></i>");       
        }        
    }
    redirectToMenu(){      
        let Monitoring=sessionStorage.getItem("Monitoring");
        let Scaning=sessionStorage.getItem("Scan");
        let UserType=sessionStorage.getItem("UserType");
    
        if (Monitoring == "true" && Scaning == "false" && UserType==""){
          this.router.navigate(['CorporateDashboard']);
        }
        else if (Scaning == "true" && Monitoring == "false" && UserType==""){
          this.router.navigate(['ServiceDashboard']);
        }
        else if (Scaning == "true" && Monitoring == "true" && UserType==""){
          this.router.navigate(['CorporateDashboard']);
        }
        else{
          this.router.navigate(['UserListDashboard']);
        }
      } 
    Logout(){    
        this.UserService.logout();
        this.router.navigate(['/Home']);
    } 

}
