import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
//import { User } from '../../class/user';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';

@Component({
  selector: 'app-user-list-dashboard',
  templateUrl: './user-list-dashboard.component.html',
  styleUrls: ['./user-list-dashboard.component.css']
})
export class UserListDashboardComponent implements OnInit {  

  UID:string ;
  UserName: string;
  Email: string;
  pagedItems: Array<any>;  
  
  constructor(private router: Router, private apiService: UserService,private route: ActivatedRoute) { }
  SearchText: string='';
  users=[];
  order: string = 'FullName';
  reverse: boolean = false;
  
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  
  
  ngOnInit() {           
        this.getUserList();      
  }
  beginPagination(pagedItems: Array<any>) {  
      this.pagedItems = pagedItems;  
  } 

  ShowUserHealthDetails(UID, UserName, Email,HealthStatus ) {     
    this.router.navigate(['../UserHealthDashboard'], {
      queryParams: {        
        "UID": UID, "User": encodeURIComponent(UserName),"HealthStatus": btoa(HealthStatus)
      }
    });    
  }
 
  getUserList(){    
    let SearchKey=btoa(this.SearchText? this.SearchText : "");
   
    let PageNo=1;
    this.apiService.getUserList(SearchKey,PageNo).subscribe((data)=>{    
    if(data){
    
    this.users = data['UserList'];  
   }
  }); 
}

onSearchChange(value: string): void {
  if (value.length >= 3) {

    this.getUserList();
  } else {
 
    this.users = [];
  }
}


}
