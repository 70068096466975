 
import { Component, OnInit } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
 
import { HttpErrorResponse } from '@angular/common/http';
 
import { Router,ActivatedRoute } from "@angular/router";
import * as $  from 'jquery';
import { DatePipe } from '@angular/common'

import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { Observable } from 'rxjs';
import { UserService } from '../Services/user.service';
 
 
import { HttpClient } from '@angular/common/http';
 
import { AbstractControl } from '@angular/forms';
 
import { OrderPipe } from 'ngx-order-pipe';
 
import { v4 as uuidv4 } from 'uuid';
 
 
import { data, event } from 'jquery';
 
import { MatSnackBar } from '@angular/material/snack-bar';
 
import { MatTableDataSource } from '@angular/material/table';
 
 
import * as _ from 'lodash';


@Component({
  selector: 'app-editchallenges',
  templateUrl: './editchallenges.component.html',
  styleUrls: ['./editchallenges.component.css']
})
export class EditchallengesComponent implements OnInit {

    
  ChallengeList
  ChallengeForm:FormGroup;
  Challenge: any = {};
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  isValidate=true;
  UserService: any;
  CompanyId;
  BranchId;
  
  BranchIdUpdate = "0";  
  Isactive:boolean= true;    
  public Countries ;
  private geoCoder;

	@ViewChild('search')
  public searchElementRef: ElementRef;
  branchInfo: any;
  SearchText: any;
  Challenges: any;
  currentDate: string;
  searchDate: any;
  filteredChallenges: any;
 
  

  constructor(
    private mapsAPILoader: MapsAPILoader,
     private ngZone: NgZone,
     public datepipe: DatePipe,
     private formBuilder: FormBuilder,
     private router: Router,
      private route:ActivatedRoute, 
      private apiService: UserService,
      
      private http: HttpClient,
      private orderPipe: OrderPipe,
      
      private datePipe: DatePipe,
      private snackBar: MatSnackBar






      ) { 
        const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
    const day = String(today.getDate()).padStart(2, '0');
    
    this.currentDate = `${year}-${month}-${day}`;
     
  }


  get f() {
    return this.ChallengeForm.controls;
  }
  
  

  ngOnInit(): void {

    this.ChallengeForm = this.formBuilder.group({
      challengeTitle: new FormControl('', [Validators.required]),
      challengeStartdate: new FormControl('',[Validators.required]),
     challengeEnddate: new FormControl('', [Validators.required]),
     challengeId: new FormControl('',),
     challengeDetails: new FormControl('', [Validators.required, Validators.maxLength(50)]),
     creationDate: new FormControl('',),
     createdBy: new FormControl('',),
     challengeTarget: new FormControl('',[Validators.required]),
 
     challengeType: new FormControl(''), 
     
    
     
     
    });
     
  


  this.route.params.subscribe(params => {
  console.log('URL Parameters:', params);
  const name = params['name'];
  const startDate = params['startDate'];
  const endDate = params['endDate'];
   
  const challengeDetails = params['challengeDetails'];
  const creationDate = params['creationDate'];
  const challengeTarget = params['challengeTarget'];
  const createdBy = params['createdBy'];
   const challengeType = params['challengeType'];
  const  challengeId = params['challengeId'];


  // Parse the date and remove the time part
  const parsedStartDate = new Date(startDate);
  const parsedEndDate = new Date(endDate);

  // Format the dates to 'yyyy-MM-dd' (or any desired format)
  const formattedStartDate = this.formatDate(parsedStartDate);
  const formattedEndDate = this.formatDate(parsedEndDate);

  console.log('Retrieved Data:', name, formattedStartDate, formattedEndDate);

  // Set the form values
  this.ChallengeForm.patchValue({
    challengeTitle: name,
    challengeStartdate: formattedStartDate,
    challengeEnddate: formattedEndDate,
     
    challengeDetails: challengeDetails,
    creationDate:creationDate,
    challengeTarget:challengeTarget,
    createdBy:createdBy,
     challengeType:challengeType,
    challengeId: challengeId,

    

  });
});

 
 

}
formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

 


// Savechallenge() {

//   console.log(' form:', FormData);

//   this.errorMessage = null;

//   if (this.ChallengeForm.invalid) {
//     // Find the first invalid control in the form
//     const invalidControl = Object.values(this.ChallengeForm.controls).find(control => control.invalid);

//     if (invalidControl) {
//       // Focus on the first invalid control
//       invalidControl.markAsTouched();
//       invalidControl.markAsDirty();
//       invalidControl.updateValueAndValidity();
//       return; // Stop here if the form is invalid
//     }
//   }

//   if (this.ChallengeForm.valid) {
//     // Create a FormData object
//     const formData = new FormData();
     
//     formData.append('challengeTitle', this.ChallengeForm.value.challengeTitle);
//     formData.append('challengeType', this.ChallengeForm.value.challengeType);
//     formData.append('challengeStartDate', this.ChallengeForm.value.challengeStartdate);
//     formData.append('challengeEndDate', this.ChallengeForm.value.challengeEnddate);
     
//     formData.append('challengeDetails', this.ChallengeForm.value.challengeDetails);  
//     formData.append('createdBy', this.ChallengeForm.value.createdBy);
//     formData.append('creationDate', this.ChallengeForm.value.creationDate);
//     formData.append('challengeTarget', this.ChallengeForm.value.challengeTarget);
//     formData.append('challengeId', this.ChallengeForm.value.challengeId);

//     console.log(' form:',FormData )

//     // Add more fields as needed

//     // Send the form data to your API
//     // this.apiService.updateChallenges(formData).subscribe(
//     //   (data: Response) => {
//         // Handle API response here


//         this.apiService.updateChallenges(formData).subscribe(
//           (data: Response) => {
//         console.log('API Response:', data);

//         // Reset the form or navigate to a different page on success
//         if (data.status === 1) {
//           this.successMessage = 'Challenge saved successfully';
//           this.ChallengeForm.reset(); // Reset the form
//         } else {
//           this.errorMessage = 'Failed to save the challenge';
//         }
//       },
//       (error) => {
//         console.error('API Error:', error);
//         this.errorMessage = 'An error occurred while saving the challenge';
//       }
//     );
//   }
// }
// Savechallenge() {
//   this.errorMessage = null;

//   if (this.ChallengeForm.invalid) {
//     // Find the first invalid control in the form
//     const invalidControl = Object.values(this.ChallengeForm.controls).find(control => control.invalid);

//     if (invalidControl) {
//       // Focus on the first invalid control
//       invalidControl.markAsTouched();
//       invalidControl.markAsDirty();
//       invalidControl.updateValueAndValidity();
//       return; // Stop here if the form is invalid
//     }
//   }


//   if (this.ChallengeForm.valid) 
//   {
//     console.log(this.ChallengeForm.value);
//     console.log("form submitted");
     
//   } 
//     formData.append('challengeTitle', this.ChallengeForm.value.challengeTitle);
//     formData.append('challengeType', this.ChallengeForm.value.challengeType);
//     formData.append('challengeStartDate', this.ChallengeForm.value.challengeStartdate);
//     formData.append('challengeEndDate', this.ChallengeForm.value.challengeEnddate);
     
//     formData.append('challengeDetails', this.ChallengeForm.value.challengeDetails);  
//     formData.append('createdBy', this.ChallengeForm.value.createdBy);
//     formData.append('creationDate', this.ChallengeForm.value.creationDate);
//     formData.append('challengeTarget', this.ChallengeForm.value.challengeTarget);
//     formData.append('challengeId', this.ChallengeForm.value.challengeId);

//     console.log('form:', formData); // Corrected the variable name here

//     // Add more fields as needed

//     // Send the form data to your API
//     this.apiService.updateChallenges(formData).subscribe(
//       (data:Response) => {
//         console.log('API Response:', data);

//         // Reset the form or navigate to a different page on success
//         if (data.status === 1) {
//           this.successMessage = 'Challenge saved successfully';
//           this.ChallengeForm.reset(); // Reset the form
//         } else {
//           this.errorMessage = 'Failed to save the challenge';
//         }
//       },
//       (error) => {
//         console.error('API Error:', error);
//         this.errorMessage = 'An error occurred while saving the challenge';
//       }
//     );
  
// }

// Savechallenge() {
//   this.errorMessage = null;

//   if (this.ChallengeForm.invalid) {
//     // Find the first invalid control in the form
//     const invalidControl = Object.values(this.ChallengeForm.controls).find(control => control.invalid);

//     if (invalidControl) {
//       // Focus on the first invalid control
//       invalidControl.markAsTouched();
//       invalidControl.markAsDirty();
//       invalidControl.updateValueAndValidity();
//       return; // Stop here if the form is invalid
//     }
//   }
//   if (this.ChallengeForm.value.challengeTitle != null) {
    
//   // Repeat this for other fields as well
  
//   // Create a FormData object
//   const formData = new FormData();

//   // Append form values to formData
//   formData.append('challengeTitle', this.ChallengeForm.value.challengeTitle);
//   formData.append('challengeType', this.ChallengeForm.value.challengeType);
//   formData.append('challengeStartDate', this.ChallengeForm.value.challengeStartdate);
//   formData.append('challengeEndDate', this.ChallengeForm.value.challengeEnddate);
//   formData.append('challengeDetails', this.ChallengeForm.value.challengeDetails);
//   formData.append('createdBy', this.ChallengeForm.value.createdBy);
//   formData.append('creationDate', this.ChallengeForm.value.creationDate);
//   formData.append('challengeTarget', this.ChallengeForm.value.challengeTarget);
//   formData.append('challengeId', this.ChallengeForm.value.challengeId);

//   // Add more fields as needed

//   // Send the form data to your API
//   this.apiService.updateChallenges(formData).subscribe(
//     (data: any) => {
//       console.log('API Response:', data);

//       // Reset the form or navigate to a different page on success
//       if (data.Status === 'Success') { // Assuming 'Status' property is used in the response
//         this.successMessage = 'Challenge saved successfully';
//         this.ChallengeForm.reset(); // Reset the form
//       } else {
//         this.errorMessage = data.Message; // Display error message from the API
//       }
//     },
//     (error: HttpErrorResponse) => {
//       console.error('API Error:', error);
//       this.errorMessage = 'An error occurred while saving the challenge';
//     }
//   );
// }
// }


// getMinEndDate(): string {
//   const startDate = this.ChallengeForm.get('challengeStartdate')?.value;

//   if (startDate) {
//     const startDateObj = new Date(startDate);

//     // Add two days to the start date
//     startDateObj.setDate(startDateObj.getDate() + 2);

//     // Format the minimum end date as a string in the "yyyy-MM-dd" format
//     return startDateObj.toISOString().split('T')[0];
//   }

//   // If there is no start date selected, return an empty string or a default value as needed
//   return '';
// }


// getMinEndDate(): Date {
//   const startDate = this.ChallengeForm.get('challengeStartdate')?.value;

//   if (startDate) {
//     const startDateObj = new Date(startDate);

//     // Add two days to the start date
//     startDateObj.setDate(startDateObj.getDate() + 2);

//     return startDateObj; // Return the date object
//   }

//   return null; // If there's no start date selected, return null or adjust as needed
// }

// getMinEndDate(): Date | null {
//   const startDate = this.ChallengeForm.get('challengeStartdate')?.value;

//   if (startDate) {
//     const startDateObj = new Date(startDate);

//     // Add two days to the start date
//     startDateObj.setDate(startDateObj.getDate() + 2);

//     return startDateObj;
//   }

//   return null;
// }

// getMinEndDate(): string {
//   const today = new Date(); // Get today's date
//   const year = today.getFullYear();
//   const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Adding 1 because months are zero-based
//   const day = `${today.getDate()}`.padStart(2, '0');
//   return `${year}-${month}-${day}`; // Format date in YYYY-MM-DD
// }

getMinEndDate(): string {
  const startDate = this.ChallengeForm.get('challengeStartdate')?.value;

  if (startDate) {
    const startDateObj = new Date(startDate);
    startDateObj.setDate(startDateObj.getDate() + 2); // Add two days to the start date

    const year = startDateObj.getFullYear();
    const month = `${startDateObj.getMonth() + 1}`.padStart(2, '0');
    const day = `${startDateObj.getDate()}`.padStart(2, '0');
    
    return `${year}-${month}-${day}`; // Format date in YYYY-MM-DD for the minimum allowed date
  }

  const today = new Date(); // If no start date is chosen, disable past dates from today
  const year = today.getFullYear();
  const month = `${today.getMonth() + 1}`.padStart(2, '0');
  const day = `${today.getDate()}`.padStart(2, '0');

  return `${year}-${month}-${day}`; // Format date in YYYY-MM-DD for the minimum allowed date as today
}


filterByDate() {
  if (this.searchDate) {
    // Use JavaScript's Date object to parse the searchDate string
    const selectedDate = new Date(this.searchDate);

    // Filter the Challenges array to include only items with challengeStartdate matching the selected date
    this.filteredChallenges = this.Challenges.filter(challenge => {
      const challengeDate = new Date(challenge.challengeStartdate);
      return challengeDate.toDateString() === selectedDate.toDateString();
    });
  } else {
    // If no date is selected, show all challenges
    this.filteredChallenges = this.Challenges;
  }
}


Savechallenge() {

  this.errorMessage = null;

  if (this.ChallengeForm.invalid) {
    // Find the first invalid control in the form
    const invalidControl = Object.values(this.ChallengeForm.controls).find(control => control.invalid);

    if (invalidControl) {
      // Focus on the first invalid control
      invalidControl.markAsTouched();
      invalidControl.markAsDirty();
      invalidControl.updateValueAndValidity();
      return; // Stop here if the form is invalid
    }
  }









  this.errorMessage = null;

  if (this.ChallengeForm.invalid) {
    // Handle form validation as needed
  }

  // Create a JSON object with your data
  const jsonData = {
    challengeTitle: this.ChallengeForm.value.challengeTitle,
    challengeType: this.ChallengeForm.value.challengeType,
    challengeStartDate: this.ChallengeForm.value.challengeStartdate,
    challengeEndDate: this.ChallengeForm.value.challengeEnddate,
    challengeDetails: this.ChallengeForm.value.challengeDetails,
    createdBy: this.ChallengeForm.value.createdBy,
    creationDate: this.ChallengeForm.value.creationDate,
    challengeTarget: this.ChallengeForm.value.challengeTarget,
    challengeId: this.ChallengeForm.value.challengeId,
    // Add more fields as needed
  };

  // Send the JSON data to your API
  this.apiService.updateChallenges(jsonData).subscribe(
    (data: any) => {
      console.log('API Response:', data);

      // Reset the form or navigate to a different page on success
      if (data.Status === 'Success') { // Assuming 'Status' property is used in the response
        this.successMessage = 'Challenge saved successfully';
        this.ChallengeForm.reset(); // Reset the form
      } else {
        this.errorMessage = data.ErrorMessage; // Display error message from the API
      }
    },
    (error: HttpErrorResponse) => {
      console.error('API Error:', error);
      this.errorMessage = 'An error occurred while saving the challenge';
    }
  );
  if (data != null) {
  
    this.snackBar.open('Challenge Updated successfully', 'Close', {
      duration: 4000, // Duration in milliseconds (adjust as needed)
      panelClass: ['success-snackbar'],
    });

    // Reset the form or perform any other necessary actions
    //  window.location.reload();
    
    this.router.navigate(['/CorporateChallenges'], { queryParams: { serviceId: 4} });
}

(error) => {
console.error('Error creating challenge', error);
// Handle the error as needed
}








}

   



  get fn() { return this.ChallengeForm.controls; }
  


  getChallenges() {
    // Encode the search text to be sent to the API
    let SearchKey = btoa(this.SearchText ? this.SearchText : '');
    

    let PageNo = 1; // You can adjust the page number if needed

    this.apiService.GetChallenges(SearchKey, PageNo).subscribe(
      (data: any) => {
        // Handle the data received from the API here
        console.log('Data from API:', data);

        if (data) {
          // Check the response structure and handle it accordingly
          // Assuming the response contains a 'GetChallenges' property:
          this.Challenges = data['GetChallenges'];
          console.log('Challenges:', this.Challenges);
        }
      },
      (error: any) => {
        // Handle any errors that may occur during the subscription
        console.error('Error:', error);
      }
    );
  }
 
  
  GoToBranchList(){    
    this.router.navigate(['BranchList'], {
      queryParams: {        
        "CompanyId": this.CompanyId
      }
    });    
  }  
  





}
