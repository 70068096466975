import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { Router, ActivatedRoute, ParamMap  } from "@angular/router";

declare var $ :any;

@Component({
  selector: 'app-user-health-status',
  templateUrl: './user-health-status.component.html',
  styleUrls: ['./user-health-status.component.css']
})
export class UserHealthStatusComponent implements OnInit {
 public myAngularxQrCode :any;
 UserName: string;
 UID : string;
 CompanyId:string;
 errorMessage:string;
 successMessage:string;
 UserHealthDetails:any;
 TravelHistoryList;
 VaccinationTestDetail;  

  constructor(private router: Router, private route : ActivatedRoute , private apiService: UserService) { }

  ngOnInit(): void {    
    this.UID = this.route.snapshot.queryParams.UID; 
    this.UserName  = atob(this.route.snapshot.queryParams.User); 
    this.myAngularxQrCode = '{ "UID" :' + this.UID + ', "FullName" :' + this.UserName + '}';
    this.CompanyId= atob(sessionStorage.getItem("CompanyId"));
    //this.GetUserHealthStatus(this.CompanyId,this.UID)
    this.getTravelHistoryList(this.UID);
    this.getVaccinationTestDetails(this.UID);
  }
  GetUserHealthStatus(CompanyId:string,UID:string){          
    this.apiService.GetUserHealthStatus(CompanyId,UID).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.UserHealthDetails = data['UserHealthDatails'];
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
get sortData() {
  if(this.TravelHistoryList){
  return this.TravelHistoryList.sort((a, b) => {
    return <any>new Date(b.DepartureDate) - <any>new Date(a.DepartureDate);
  });
}
}
get sortVaccinationData() {
  if(this.VaccinationTestDetail){
  return this.VaccinationTestDetail.sort((a, b) => {
    return <any>new Date(b.Date) - <any>new Date(a.Date);
  });
}
}

getTravelHistoryList(UID:string){            
  this.apiService.getTravelHistoryList(UID).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.TravelHistoryList = data['TravelHistory']; 
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
  }
getVaccinationTestDetails(UID:string){            
  this.apiService.getVaccinationTestDetails(UID).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.VaccinationTestDetail = data['VaccinationDetail'];          
        }
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
}

}
