import { Component, OnInit,ElementRef, ViewChild, Renderer2,VERSION } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { Employee } from '../../Class/Employee/Employee'
import { ExportEmployee } from '../../export-employee';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import { TableUtil } from "./tableUtil";
//import * as $ from 'jquery';
import { analytics } from 'firebase';
import {QrScannerComponent} from 'angular2-qrscanner';
import { JsonPipe } from '@angular/common';
import { event } from 'jquery';

declare var $ :any;

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  showModalBox: boolean = false;
  exportToExcel:boolean=false;
  elementType = 'url';  
  public imagePath;  
  QRValue : any;  
  @ViewChild('result') resultElement: ElementRef;  
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
  showQRCode: boolean = false; 
  QRData:any; 
  canvasHidden:boolean=true;
  
  // order: string = 'LastHealthNumProfile';
  // reverse: boolean = false;
  order: string = 'RegistrationDate';
  reverse: boolean = false;
  
  mySelect="0";
  selectedDesignation: any;

  // For Export To Excel

  // @ViewChild('TABLE', { static: false }) TABLE: ElementRef;  
  // title = 'Excel';  
  // ExportTOExcel() { 
  //   debugger
  //   this.exportToExcel=true;  
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  // XLSX.utils.json_to_sheet(wb, ws, 'Sheet1');    
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
   
  //   XLSX.writeFile(wb, 'EmployeeSheet.xlsx'); 
  //   this.exportToExcel=false;     
  // }  

// For Export To Excel

  empdesignation = [
    {
      id: 0,
     
      Designation: 'Select Designation'
    },
    {
      id: 1,
     
      Designation: 'Software Developer'
    },
    {
      id: 2,
     
      Designation: 'Executive'
    },
    {
      id: 3,
      
      Designation: 'Sr. Executive'
    },
    {
      id: 4,
      
      Designation: 'Assistant Manager'
    },
    {
      id: 5,
      
      Designation: 'Manager'
    },
    {
      id: 6,
      
      Designation: 'Sr. Manager'
    },
    {
      id: 7,
      
      Designation: 'General Manager'
    },
    {
      id: 8,
      
      Designation: 'Head of Department'
    },
    {
      id: 9,
      
      Designation: 'Vice President'
    }
    
  ];
  items: any[];

  
  constructor(private renderer: Renderer2,private orderPipe: OrderPipe,private router: Router, private apiService: UserService,private formBuilder: FormBuilder,private route: ActivatedRoute) {     
   }

  EmployeeList;
  BranchList;
  EmployeeForm:FormGroup;
  employeeInfo: Employee =new Employee();
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  EMPsuccessMessage:string;
  EMPerrorMessage:string;
  CompanyId:string;
  BranchId:string;
  CompanyName;
  SearchText: string='';
  EmployeeData:any;
  list:any[];

  // setOrder(value: string) {
  //   if (this.order === value) {
  //     let newarry = this.list.sort((a,b)=> b.LastHealthNumProfile - a.LastHealthNumProfile);
  //     this.list = newarry;
     // this.order = value;
     // this.reverse = !this.reverse;
    // }
    // else{
    //   let newarry = this.list.sort((a,b)=> b.LastHealthNumProfile - a.LastHealthNumProfile);
    //   this.list = newarry;
    // }
    //this.reverse = !this.reverse;
  // }

 
  
  // setOrder(value: string) {
  //   if (this.order === value) {
  //     this.reverse = !this.reverse;
  //   }
  //   this.order = value;
  // }



  // setOrder(column: string): void {
  //   if (this.order === column) {
  //     this.reverse = !this.reverse;
  //   } else {
  //     this.order = column;
  //     this.reverse = false;
  //   }
  //   this.fetchData();
  // }



  setOrder(column: string) {
    // Check if the clicked column is not 'RegistrationDate' or 'LastReading'
    if (column !== 'RegistrationDate' && column !== 'LastReading') {
      return;
    }
  
    // Your existing sorting logic for other columns goes here
    // ...
  
    // Update the 'order' property only for allowed columns
    this.order = column;
  
    // Reverse the sorting order if the same column is clicked again
    this.reverse = !this.reverse;
  }
  
  
  fetchData(): void {
    // Implement logic to fetch or sort your data based on this.order and this.reverse.
    // For example, if you have an array of items:
    this.items = this.sortData(this.items);
  }
  
  sortData(data: any[]): any[] {
    // Implement sorting logic based on this.order and this.reverse.
    // You may use JavaScript's Array.sort() method for this.
    return data.sort((a, b) => {
      const dateA = new Date(a[this.order]);
      const dateB = new Date(b[this.order]);
  
      if (this.reverse) {
        return dateB.getTime() - dateA.getTime();
      } else {
        return dateA.getTime() - dateB.getTime();
      }
    });
  }




  ngOnInit(): void {   
    this.EmployeeForm = this.formBuilder.group({
      // CompanyId: {value: null, disabled:true},
      BranchId: [null,[Validators.required]],                           
      EmployeeCode: [''],
      DDLEMPDES:['']
      //UID: ['',[Validators.required]]                           
    });    
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.BranchId= this.route.snapshot.queryParams.BranchId; 

    this.getEmployeeList(this.CompanyId); 
    this.getBranchList(this.CompanyId);     
    this.dropifySetup();
  }

  DesignationChange() {
    debugger;
    this.selectedDesignation = this.apiService.getDropDownText(this.mySelect, this.empdesignation)[0].Designation;
}


exportArray() {

 this.getEmployeeList(this.CompanyId);
 const onlyNameAndSymbolArr: Partial<ExportEmployee>[] = this.list.map(x => ({
  EmployeeName: x.EmployeeName,
  Branch:x.BranchName,
  Designation:x.Designation,
  Email:x.EmailId,
  EmployeeCode:x.EmployeeCode,
  RegistrationDate:x.RegistrationDate,
  LastReading:x.LastReading,
  VaccinationStatus:x.VaccinationStatus
  
}));

 TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "EmployeeSheet");
}


  dropifySetup(){
    $('.dropify').dropify();

    // Translated
    $('.dropify-fr').dropify({
        messages: {
            default: 'Glissez-déposez un fichier ici ou cliquez',
            replace: 'Glissez-déposez un fichier ou cliquez pour remplacer',
            remove: 'Supprimer',
            error: 'Désolé, le fichier trop volumineux'
        }
    });

    // Used events
    var drEvent = $('#input-file-events').dropify();

    drEvent.on('dropify.beforeClear', function(event, element) {
        return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
    });

    drEvent.on('dropify.afterClear', function(event, element) {
        alert('File deleted');
    });

    drEvent.on('dropify.errors', function(event, element) {
        console.log('Has Errors');
    });

    var drDestroy = $('#input-file-to-destroy').dropify();
    drDestroy = drDestroy.data('dropify')
    $('#toggleDropify').on('click', function(e) {
        e.preventDefault();
        if (drDestroy.isDropified()) {
            drDestroy.destroy();
        } else {
            drDestroy.init();
        }
    });
  }

  get fn() { return this.EmployeeForm.controls; }  
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  }  
  // ShowUserHealthDetails(UID:string, UserName:string) {           
  //   this.router.navigate(['../UserHealthDashboard'], {
  //     queryParams: {        
  //       "UID": UID, "User": btoa(UserName)
  //     }
  //   });    
  // }   
  ShowUserHealthDetails(UID:string, UserName:string,HealthStatus:string ) {
    debugger;           
    this.router.navigate(['../UserHealthDashboard'], {
      queryParams: {        
        "UID": UID, "User": encodeURIComponent(UserName), "HealthStatus": btoa(HealthStatus)
      }
    });    
  }
  getBranchList(CompanyId:string){            
    this.apiService.GetBranchList(CompanyId).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.BranchList = data['BranchList'];
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
} 
  getEmployeeList(CompanyId:string){   
         
    this.apiService.GetEmployeeList(CompanyId,'0','All').subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.EmployeeList = data['EmployeeList'];
            this.list = data['EmployeeList'];
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}

deleteEmployee(CompanyId:string,UID:string,BranchId:string ) {     
  if(confirm("Are you sure you want to delete this Employee?")) {     
      this.apiService.deleteEmployee(CompanyId,UID,BranchId).subscribe(   
        (data:any)=>  
        {
          if (data != null){
            if(data.Status =! "1" && data.ErrorMessage !=""  ){
              this.errorMessage = data.ErrorMessage;
            }
            else{                             
              window.location.reload();
            }
          }              
        },           
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }    
      ); 
   }
}
// AddNewEmployee(){
//   this.router.navigate(['AddNewEmployee'], {
//     queryParams: {        
//       "CompanyId": this.CompanyId//, "BranchId" : btoa(this.BranchId)
//     }
//   });    
// }
CheckEmployeeExists(){
  let exist:boolean=false;
  for(let i=0; i < this.EmployeeList.length;i++){
    if (this.QRData.UID==this.EmployeeList[i].UID){
      exist=true;
      break;
    }    
  }
  return exist;
}

SaveEmployee(EmployeeForm)  
  {         
        let IsExists:Boolean = false;
        this.EMPerrorMessage=null;
        this.errorMessage=null;
        if (this.EmployeeForm.invalid) {         
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1].focus();        
          return;
        }
        if(!this.QRData){          
          this.EMPerrorMessage="Please Scan your QR Code";          
          return;
          if (!this.QRData.UID){
            this.EMPerrorMessage="Please Scan your QR Code";            
            return;
          }  
        }
        IsExists= this.CheckEmployeeExists();       

        this.employeeInfo.UID=this.QRData.UID
        this.employeeInfo.CompanyId=this.CompanyId
        this.employeeInfo.BranchId=EmployeeForm.BranchId;
        this.employeeInfo.EmployeeCode=EmployeeForm.EmployeeCode;
        this.employeeInfo.Designation=this.selectedDesignation;
        this.employeeInfo.AdminId='0' //no need adminId for now        
        if (IsExists){
          if(confirm('This Employee already exists in some other branch and will get removed if you add this employee. Are you sure you want to continue?'))
          {
            this.SaveData();
          }
          return false;
        }
        else{
          this.SaveData();
        }        
  }
 
  SaveData(){
    debugger;
    this.apiService.createEmployee(this.employeeInfo).subscribe(  
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != 1 && data.ErrorMessage !=""  ){
            this.EMPerrorMessage = data.ErrorMessage;
          }
          else{                                       
            window.location.reload(); 
            this.EmployeeForm.reset();                                                    
          }
        }              
      },           
      err => {
        if (err.error) {
          this.EMPerrorMessage = err.error.ErrorMessage;
        }
      }      
    )
  }

  sendEmail(CompanyId: string,BranchId:string,UID:string,EmailId:string,ProfileStatus:string,EmployeeName:string){  
    ProfileStatus='Orange';
    var EmployeeData = [];  
    this.errorMessage=null;
    this.successMessage = null;
  
    this.EmployeeData = JSON.stringify({CompanyId:CompanyId,BranchId:BranchId, UID:UID,EmailId : EmailId,ProfileStatus:ProfileStatus,EmployeeName: EmployeeName });
    this.apiService.SendSingleHealthStatusMail(this.EmployeeData).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status =! "1" || data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                             
            this.successMessage='Mail sent successfully';
            setTimeout(() => {            
              this.successMessage = null;            
             }, 5000);
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
    ); 
  }

  // For QR Code start

  preview(files) { 
    this.errorMessage=null;
    this.EMPerrorMessage=null;            
    this.QRValue=null; 
    this.QRData=null; 
    if (files.length === 0)  
      return;  
    var mimeType = files[0].type;  
    if (mimeType.match(/image\/*/) == null) {  
      alert("Only images are supported.");        
      return;  
    }  
    var reader = new FileReader();  
    reader.readAsDataURL(files[0]);  
    reader.onload = (_event) => {                    
      this.QRValue = reader.result;  
      console.log(reader.result);  
     //this.showQRCode = true;  
    }  
  }  
  render(e) {              
    let data= e.result
    console.log(data)    
    if(data.indexOf('UID') == -1 && data.indexOf('FullName') == -1){        
        this.ShowHideErrorForUpload();
        this.QRValue=null;                        
        return;
    }    
    this.QRData=JSON.parse(data);  
    //  element.innerHTML = e.result;  
    //  this.renderElement(element);  
  }  
  
  
//   reader.onload = function (event) {
//   var image = new Image();
  
//   image.onload=function(){
//       document.getElementById("original-Img").src=image.src;
//       var canvas=document.createElement("canvas");
//       var context=canvas.getContext("2d");
//       canvas.width=image.width/4;
//       canvas.height=image.height/4;
//       context.drawImage(image,
//           0,
//           0,
//           image.width,
//           image.height,
//           0,
//           0,
//           canvas.width,
//           canvas.height
//       );
      
//       document.getElementById("upload-Preview").src = canvas.toDataURL();
//   }
//   image.src=event.target.result;
// };
  // renderElement(element) {    //   
  //   for (let node of this.resultElement.nativeElement.childNodes) {  
  //     this.renderer.removeChild(this.resultElement.nativeElement, node);  
  //   }  
  //   this.renderer.appendChild(this.resultElement.nativeElement, element);  
  // }  

  //For Qr Code end

  //For Qr Code camera

ScanQRCode(){    
  $("#uploadfile").val("");
  $("#uploadfile").show();
  $("#QRScanner").show();
  $("#CameraQRCodeInvalid").hide();
  $("#NoCameraFound").hide();
  $("#UploadQRCodeInvalid").hide(); 
  this.EMPerrorMessage=null;
  this.QRData=null; 
  this.qrScannerComponent.getMediaDevices().then(devices => {    
    this.qrScannerComponent.videoWrapper.nativeElement.hidden=false;
    this.qrScannerComponent.qrCanvas.nativeElement.autofocus=true;    
    const videoDevices: MediaDeviceInfo[] = [];
    for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
            videoDevices.push(device);
        }
    }
    if (videoDevices.length > 0){
        let choosenDev;
        for (const dev of videoDevices){
            if (dev.label.includes('front')){
                choosenDev = dev;
                break;
            }
        }
        if (choosenDev) {
            this.qrScannerComponent.chooseCamera.next(choosenDev);
        } else {
            this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
        }
    }else{
      this.NoCameraFound();
      return;
    }
    
  });
  this.qrScannerComponent.capturedQr.subscribe(result => {                
      let data= result
      console.log(data)
      if(data.indexOf('UID') == -1 && data.indexOf('FullName') == -1){          
         this.InvalidQRCode();              
          return;
      }      
      this.QRData=JSON.parse(data); 
      //this.qrScannerComponent.stopScanning();
      this.qrScannerComponent.videoWrapper.nativeElement.hidden=true                 
  });
}
//For Qr Code camera  
StartScaning(){  
  $("#CameraQRCodeInvalid").hide();
  $("#NoCameraFound").hide();
 // document.getElementById("ScanQRCode").click();  
}
InvalidQRCode(){  
  $("#CameraQRCodeInvalid").show();
  $("#QRScanner").hide();  
}
NoCameraFound(){
  $("#NoCameraFound").show();
}
ShowHideErrorForUpload(){  
  $("#uploadfile").val("");
  $("#uploadfile").toggle();
  $("#UploadQRCodeInvalid").toggle();  
}
HideScanner(){
  this.qrScannerComponent.stopScanning();
  $("#CameraQRCodeInvalid").hide();
  $("#UploadQRCodeInvalid").hide();  
  $("#NoCameraFound").hide();
  $("#uploadfile").val("");
  $("#QRScanner").hide();
}
  
closeModal(){ 
  this.EMPerrorMessage=null;
  this.EmployeeForm.reset();  
  this.QRValue=null;  
  this.QRData=null; 
  this.HideScanner()  
  $("#EnrollEmployeeModal").modal('hide'); 
}

}
