import { Component, OnInit } from '@angular/core';
import { AdminInfo } from '../../Class/Company/company'
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder,FormArray } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import * as $  from 'jquery';
import { DatePipe } from '@angular/common'
import { debug } from 'console';

@Component({
  selector: 'app-new-admin-user',
  templateUrl: './new-admin-user.component.html',
  styleUrls: ['./new-admin-user.component.css']
})
export class NewAdminUserComponent implements OnInit {

  adminInfo: AdminInfo = new AdminInfo();  
  
  userForm:FormGroup;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  isValidate=true;
  generatedPassword:string;
  CompanyId:string;
  AdminId;
  CompanyIdUpdate = "0";
  AdminIdUpdate="0";
  CompanyName:string;
  ExistingPassword:string;
  redirectFrom:string;
  BranchList;
  SelectedBranch : string[];
  BranchId:string;
  BranchIds:string="";
  
  constructor(public datepipe: DatePipe,private formBuilder: FormBuilder,private router: Router, private route:ActivatedRoute, private apiService: UserService) { }
  
  ngOnInit(): void {
    this.userForm = this.formBuilder.group({            
      AdminId:[],
      FirstName: ['',[Validators.required]],
      LastName: ['',[Validators.required]],
      Email: ['',[Validators.required, Validators.email]],
      Password: ['',[Validators.required ,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#~^{}])[A-Za-z\\d$@$!%*?&#~^].{7,}')]],            
      PhoneNumber: ['',[Validators.required,Validators.maxLength(13),Validators.minLength(10),Validators.pattern("^((\\91-?)|0)?[0-9]{10}$")]],       
      IsActive: [],
      Branchids:""            
    });
   
  
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.AdminId = this.route.snapshot.queryParams.AdminId; 
   
debugger;
    if(this.AdminId != null)  
    {    
      this.CompanyId = atob(this.route.snapshot.queryParams.CompanyId); 
      this.AdminId = atob(this.route.snapshot.queryParams.AdminId); 
      this.CompanyName= this.route.snapshot.queryParams.CompanyName;
      
      this.redirectFrom=atob(this.route.snapshot.queryParams.redirectFrom);
      this.UserEdit(this.CompanyId,this.AdminId) ;  

    }   
    else{   
      this.getBranchList(this.CompanyId);   
      this.GenerateAutoPass();         
    }                

  }
  get fn() { return this.userForm.controls; }

  keyPress(event: any) {    
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.which == 32)
            return false;
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
 AvoidSpace(event) {
    var k = event ? event.which : event.keyCode;
    if (k == 32) return false;
  }
  GenerateAutoPass(): void {   
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 4;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
    }
    var defaultChar='C@r3'
    this.generatedPassword = defaultChar + randomstring;
    this.adminInfo.Password=this.generatedPassword;   
  }
  InsertAdminUser(userForm) { 
    debugger;
     
        this.errorMessage=null;

        if (this.AdminIdUpdate != "0") {                  
          this.fn.Password.clearValidators();
          this.fn.Password.updateValueAndValidity();
        }          
        if (this.userForm.invalid) {         
          let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
          invalidFields[1].focus();        
         return;
        }          
        if (this.AdminIdUpdate != "0") {
          this.adminInfo.AdminId =atob(this.AdminIdUpdate);
          if(userForm.Password != "")  
          {
            this.adminInfo.Password=userForm.Password
          }
          else{
            this.adminInfo.Password= this.ExistingPassword;
          }
        }  
        
        this.adminInfo.CompanyId =(this.CompanyId);          
        this.adminInfo.IsMailSend=true;
        this.adminInfo.Branchids=(this.selectedBranches.join());

        this.apiService.createAdminUser(this.adminInfo).subscribe(  
          (data:any)=>  
          {
            if (data != null){
              if(data.Status != 1 && data.ErrorMessage !=""  ){
                this.errorMessage = data.ErrorMessage;
              }
              else{                                          
                if (this.AdminIdUpdate == "0") {  
                  this.successMessage = 'Saved Successfully';  
                }  
                else  
                {  
                  this.successMessage = 'Update Successfully';  
                }                                  
                setTimeout(() => {
                  this.userForm.reset();
                  this.successMessage = null;
                  this.GoToUserList();
                }, 1000);
              }
            }                               
          },           
          err => {
            if (err.error) {
              this.errorMessage = err.error.ErrorMessage;
              
            }
          }      
        )             
    }  
  onFormSubmit(form) {          
    this.InsertAdminUser(form);  
    console.log(this.userForm.value)
  }
  
  UserEdit(CompanyId: string,AdminId :string) {          
    var UserData;       
    $("#eyeField").css("display","none");
    this.apiService.GetAdminUserDetail(this.CompanyId,this.AdminId).subscribe(
      (User: any) => {          
      UserData = User;      
      if (UserData.Status == '1'){
        this.AdminIdUpdate=AdminId;                 
        let AdminInfo =UserData.AdminDetail                

        this.userForm.controls['AdminId'].setValue(AdminInfo.AdminId); 
        this.userForm.controls['FirstName'].setValue(AdminInfo.FirstName);  
        this.userForm.controls['LastName'].setValue(AdminInfo.LastName);  
        this.userForm.controls['Email'].setValue(AdminInfo.Email);  
        this.userForm.controls['Password'].setValue("");//AdminInfo.Password
        this.userForm.controls['PhoneNumber'].setValue(AdminInfo.PhoneNumber);      
        this.ExistingPassword= AdminInfo.Password;

        this.BranchIds = AdminInfo.Branchid;
         this.getBranchList(this.CompanyId);
debugger;

      }
     
    });           
  } 
  GoToUserList(){    
    this.router.navigate(['AdminUserList'], {
      queryParams: {        
        "CompanyId": this.CompanyId, "CompanyName" : this.CompanyName,
        "redirectFrom" : btoa(this.redirectFrom)
      }
    });    
  }  

  getBranchList(CompanyId:string){
    debugger            
    this.apiService.GetBranchList(CompanyId).subscribe(   
      (data:any)=>  
      {
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.BranchList = data['BranchList'];
            debugger;
            if(this.BranchIds!=undefined && this.BranchIds!="")
            { 
            if(this.BranchIds.length>0)
            {
          
                this.BranchIds.split(',').forEach(obj => {
                  for (let n = 0; n < this.BranchList.length; ++n) {
                    if(obj==this.BranchList[n].BranchId)
                    {
                      this.BranchList[n].IsSelected = true;
                    }
                  }
                 
               });
              }
            
             
            }
           

          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
} 
get selectedBranches() { // right now: ['1','3']

  debugger;
 return this.BranchList
 .filter(opt => opt.IsSelected)
 .map(opt => opt.BranchId)
}
    

}
