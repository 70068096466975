import { Component, OnInit } from '@angular/core';
import { CompanyInfo } from '../../Class/Company/company'
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";

import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  //companyInfo: CompanyInfo = new CompanyInfo();  
  companyForm:FormGroup;
  constructor(public datepipe: DatePipe,private formBuilder: FormBuilder,private router: Router, private route:ActivatedRoute, private apiService: UserService) { }
  CompanyId:string;
  CompanyName:string;

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({      
      CompanyName: [''],      
      PhoneNumber: [''],       
      CompanyEmail: [''],      
      Address: [''],
      City: [''],
      State: [''],
      Country: [''],      
      LicenceExpiryDate: ['']
    })
    this.CompanyId = sessionStorage.getItem("CompanyId");
    if(this.CompanyId != null )  
    {  
      this.GetCompanyDetails(this.CompanyId) ;        
    } 
  }

  ManageAdminUser() {     
    this.router.navigate(['AdminUserList'], {
      queryParams: {        
        "CompanyId": this.CompanyId,
        "CompanyName": btoa(this.CompanyName),
        "redirectFrom": btoa('CompanyDetails')
      }
    });    
  }

  GetCompanyDetails(CompanyId){
    var ComapnyData;       
    this.apiService.GetCompanyDetail(this.CompanyId,btoa('0')).subscribe(
      (Comp: any) => {          
        ComapnyData = Comp;      
        if (ComapnyData.Status == '1'){        
          let companyInfo=ComapnyData.CompanyDetail.companyInfo[0]        
          let Licence =ComapnyData.CompanyDetail.licence[0]        
          this.companyForm.controls['CompanyName'].setValue(companyInfo.CompanyName);  
          this.companyForm.controls['PhoneNumber'].setValue(companyInfo.PhoneNumber);  
          this.companyForm.controls['CompanyEmail'].setValue(companyInfo.CompanyEmail);  
          this.companyForm.controls['Country'].setValue(companyInfo.Country);  
          this.companyForm.controls['Address'].setValue(companyInfo.Address);  
          this.companyForm.controls['State'].setValue(companyInfo.State);  
          this.companyForm.controls['City'].setValue(companyInfo.City); 
          //this.companyForm.controls['IsActive'].setValue(companyInfo.IsActive); 
          this.companyForm.controls['LicenceExpiryDate'].setValue(Licence.LicenceExpiryDate);  
          this.CompanyName=companyInfo.CompanyName;        
      }
    });     
  }
  // BackToDashboard() {           
  //   this.router.navigate(['../CorporateDashboard'], { });    
  // }
  BackToDashboard(){    
    let Monitoring=sessionStorage.getItem("Monitoring");
    let Scaning=sessionStorage.getItem("Scan");
    let UserType=sessionStorage.getItem("UserType");

    if (Monitoring == "true" && Scaning == "false" && UserType==""){
      this.router.navigate(['../CorporateDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "false" && UserType==""){
      this.router.navigate(['../ServiceDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "true" && UserType==""){
      this.router.navigate(['../CorporateDashboard']);
    }
    else{
      this.router.navigate(['../UserListDashboard']);
    }
  }

}
