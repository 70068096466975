import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms';
import { PushNotificationService } from '../../Class/PushNotification/push-notification.service';
import { NgxSpinnerService } from "ngx-spinner";  

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {

  FrmGrpPushNotification: FormGroup;
  UploadFile:any;
  rdo_opt_value:any;
  rdo_target_value:any;
  DynNotificationTitle:string = '';
  DynNotificationMSG:string ='';
  ShParam: boolean = false;
  hiddenrichcontent: boolean = false;
  hiddentextmedia: boolean = false;
  hiddentextonly: boolean = false;
  IsTargetSelected: boolean = false;
  FlagFileUpload: boolean = false;
  FlagNotificationTitle: boolean = false;
  FlagNotificationMSG: boolean =false;
  FlagFileformat:boolean=false;
  FlagFilelimit:boolean=false;
  errorMessage:string;
  successMessage:string;
  isSelectImage:boolean=false;
  isTextOnly=false;
  isMedia=false;
  isRichontent=false;
  isShowTargetplatform: boolean;

  constructor(private SpinnerService: NgxSpinnerService, public fb: FormBuilder,public us: UserService,private router: Router) 
  { 
       
  }

  ngOnInit(): void {

    this.FrmGrpPushNotification = this.fb.group({

      rdo_opt:[''],
      DDLAppTrgtTyp:[''],
      // NotificationTitle:['', Validators.required , Validators.maxLength(500)],
      // NotificationMSG:['', Validators.required, Validators.maxLength(500) ],

      NotificationTitle: ['', [Validators.required, Validators.maxLength(500)]],
      NotificationMSG: ['', [Validators.required, Validators.maxLength(500)]],
      FUPNotificationBanner:[''],
      notification_events:[''],
      rdo_target:['']     

    });

    this.ShParam = false; 
    let defaultId = "1";
   this.FrmGrpPushNotification.controls['DDLAppTrgtTyp'].setValue(defaultId);
   this.FrmGrpPushNotification.controls['notification_events'].setValue(defaultId);
   

  }

  NotificationTitle_KeyUp()
  {    
    if (this.FrmGrpPushNotification.value["NotificationTitle"] != null || this.FrmGrpPushNotification.value["NotificationTitle"].length > 0 || this.FrmGrpPushNotification.value["NotificationTitle"] != "")
    {
          this.FlagNotificationTitle = false;
          //return false;          
    }
    else
    {
      this.FlagNotificationTitle = true;
    }
  }

  NotificationMSG_KeyUp()
  {    
    if (this.FrmGrpPushNotification.value["NotificationMSG"] != null || this.FrmGrpPushNotification.value["NotificationMSG"].length > 0 || this.FrmGrpPushNotification.value["NotificationMSG"] != "")
    {
          this.FlagNotificationMSG = false;
          //return false;          
    }
    else
    {
      this.FlagNotificationMSG = true;
    }
  }
//   NotificationTitle_KeyUp() {
//     this.DynNotificationTitle = this.FrmGrpPushNotification.value["NotificationTitle"];
//     console.log(this.DynNotificationTitle); // Check if the value is being captured
//   }
  
  
 
//   NotificationMSG_KeyUp() {
//     this.DynNotificationMSG = this.FrmGrpPushNotification.value['NotificationMSG'];
//     console.log(this.DynNotificationMSG); // Check if the value is being captured
// }

  


  onImageClick(e:HTMLElement){
    console.log(e.id, e.className);
    if(e.id=="lbl_textonly")
    {
      this.isTextOnly=true;
      this.isMedia=false;
      this.isRichontent=false;
      this.rdo_opt_value=1;
    }
    else if(e.id=="lbl_textmedia")
    {
      this.isTextOnly=false;
      this.isMedia=true;
      this.isRichontent=false;
      this.rdo_opt_value=2;
    } 
    else if(e.id=="lbl_richcontent")
    {
      this.isTextOnly=false;
      this.isMedia=false;
      this.isRichontent=true;
      this.rdo_opt_value=3;
    } 



     

        

    if(this.rdo_opt_value == 1)
    {
      this.ShParam = true; 
      this.FlagFileUpload = false;
      this.hiddentextmedia=true;
      this.hiddenrichcontent=true;
      this.hiddentextonly = false;
    }
    else if(this.rdo_opt_value == 2)
    {
      this.ShParam = false; 
      this.FlagFileUpload = false;

      this.hiddentextmedia=false;
      this.hiddenrichcontent=true;
      this.hiddentextonly = true;
    }
    else if(this.rdo_opt_value == 3)
    {
      this.ShParam = false; 
      this.hiddentextmedia=true;
      this.hiddenrichcontent=false;
      this.hiddentextonly = true;
    }

    /////////
}

  BtnSubmit_Click()
  {
    console.log(this.FrmGrpPushNotification.value)
    

    if (this.UploadFile ==  undefined && this.rdo_opt_value == 3)
    {
          this.FlagFileUpload = true;
          return false;          
    }

    if (this.FrmGrpPushNotification.value["NotificationTitle"] == null || this.FrmGrpPushNotification.value["NotificationTitle"].length == 0 || this.FrmGrpPushNotification.value["NotificationTitle"] == "")
    {
          this.FlagNotificationTitle = true;
          return false;          
    }
    else
    {
      this.FlagNotificationTitle = false;
    }

    if (this.FrmGrpPushNotification.value["NotificationMSG"] == null || this.FrmGrpPushNotification.value["NotificationMSG"].length == 0 || this.FrmGrpPushNotification.value["NotificationMSG"] == "")
    {
          this.FlagNotificationMSG = true;
          return false;          
    }
    else
    {
      this.FlagNotificationMSG = false;
    }

     if (this.FrmGrpPushNotification.value["rdo_target"]!=null && this.FrmGrpPushNotification.value["rdo_target"]!="" )
    {
          this.IsTargetSelected = false;
          //return false;          
    }
    else
    {
      if(this.isShowTargetplatform==false)
      {
        this.IsTargetSelected = true;
  
      }
     else{
      this.IsTargetSelected = true;
       return false;
     }
    }

    let formData:FormData = new FormData();
    formData.append("NotificationType", this.rdo_opt_value);
    formData.append("AppTarget",this.FrmGrpPushNotification.value["DDLAppTrgtTyp"]);
    formData.append("NotificationTitle",this.FrmGrpPushNotification.value["NotificationTitle"]);
    formData.append("NotificationMessage",this.FrmGrpPushNotification.value["NotificationMSG"]);
    formData.append("NotificationImg", (this.UploadFile == undefined || this.UploadFile == null) ?  "" : this.UploadFile[0] );
    formData.append("TargetPlatform",this.rdo_target_value);
    formData.append("PushDate",this.FrmGrpPushNotification.value["notification_events"]);
    this.SpinnerService.show();

  //   this.us.CreatePushNotification(formData).subscribe( 
      
      
       
  //     (data:any)=>
        
  //     {
  //       if (data != null)
  //           {
  //         if(data.Status != 1 && data.ErrorMessage !=""  ){
  //           this.errorMessage = data.ErrorMessage;
  //         }
  //         else{ 
  //           this.SpinnerService.hide();                                                    
  //           this.successMessage = 'Notification Sent';  
  //           setTimeout(() => {
  //           this.FrmGrpPushNotification.reset();
  //           this.successMessage = null;
  //           this.router.navigate(['PushNotificationList']);
  //         }, 1000);
  //       }
  //     }                               
  //     },           
  //     err => {
  //       if (err.error) 
  //       {
          
  //       }
  //     }      
  //   )     

  // }


  this.us.CreatePushNotification(formData).subscribe(
    (data: any) => {
      if (data != null) {
        if (data.Status != 1 && data.ErrorMessage !== "") {
          this.errorMessage = data.ErrorMessage;
        } else {
          this.SpinnerService.hide();
          this.successMessage = 'Notification Sent';
  
          setTimeout(() => {
            this.FrmGrpPushNotification.reset();
            this.successMessage = null;
            this.router.navigate(['PushNotificationList']);
          }, 1000);
        }
      }
    },
    (err) => {
      // Handle error response
      if (err.error != null) {
        // Additional error handling if needed
        console.error("Error:", err.error);
      }
    }
  );
  }  

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg') {
        return true;
    }
else {
    return false;
}
}

  Filevalidation(fileInput: any)
  { 
       
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e : any) {
          $('#previewtextmedia').attr('src', e.target.result);
          $('#previewrichcontent').attr('src', e.target.result);
      }

      if (!this.validateFile(fileInput.target.files[0].name)) {
        // alert('Selected file format is not supported');
        this.FlagFileformat =true;
        this.UploadFile.value='';
        return false;
        
    }
    if(fileInput.target.files[0].size > 1572864)
{
    // alert("File size exceeds the limit.");
    this.FlagFilelimit =true;
    this.UploadFile.value='';
    return false;
}

      reader.readAsDataURL(fileInput.target.files[0]);
      this.UploadFile = fileInput.target.files; 

  }  
  }

Rdo_Change(element)
{     
    this.rdo_opt_value = element.target.value;      

    if(this.rdo_opt_value == 1)
    {
      this.ShParam = true; 
      this.FlagFileUpload = false;
      this.hiddentextmedia=true;
      this.hiddenrichcontent=true;
      this.hiddentextonly = false;
    }
    else if(this.rdo_opt_value == 2)
    {
      this.ShParam = false; 
      this.FlagFileUpload = false;

      this.hiddentextmedia=false;
      this.hiddenrichcontent=true;
      this.hiddentextonly = true;
    }
    else if(this.rdo_opt_value == 3)
    {
      this.ShParam = false; 
      this.hiddentextmedia=true;
      this.hiddenrichcontent=false;
      this.hiddentextonly = true;
    }

}
// Rdo_TChange(element)
// {
//   this.rdo_target_value =element.target.value;
// }

Rdo_TChange(element)
{
  debugger;
  this.rdo_target_value =element.target.value;
  this.IsTargetSelected=false;
}

GoToPushNList(){    
  this.router.navigate(['PushNotificationList'], {
    queryParams: {        
      
    }
  });    
}  
//#df4242;red colour at risk
}



