// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig:{
    apiKey: "AIzaSyAietUS5SxCbjpFheBoWPyzsyThP0aRRFg",
    authDomain: "carework-532b6.firebaseapp.com",
    databaseURL: "https://carework-532b6.firebaseio.com",
    projectId: "carework-532b6",
    storageBucket: "carework-532b6.appspot.com",
    messagingSenderId: "22308178809",
    appId: "1:22308178809:web:a709ecddd639e2c079bda8",
    measurementId: "G-4BX1WNRVKK"
  }  
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
