import { Component, OnInit } from '@angular/core';
import * as $  from 'jquery';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  CopyrightDate;

  constructor() { }

  ngOnInit() {
    var dteNow = new Date();
    var intYear = dteNow.getFullYear();  
    this.CopyrightDate= intYear;
  }

}
