import { Component, OnInit } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import {UserSignInService} from '../../Services/user-sign-in.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.css']
})
export class SetNewPasswordComponent implements OnInit {
  resetForm:FormGroup;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  isValidate=true;

  constructor(private formBuilder: FormBuilder,private UserService : UserSignInService , private router: Router) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({           
      OldPassword: ['',[Validators.required]],
      NewPassword: ['',[Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#~^{}])[A-Za-z\\d$@$!%*?&#~^].{7,}') ]],
      ConfirmPassword: ['',[Validators.required]]
    });

  }
  get fn() { return this.resetForm.controls; }  

  Validate() { 
    this.isValidate = true;         
    const new_password = this.fn.NewPassword.value
    const confirm_password =this.fn.ConfirmPassword.value

    if (confirm_password !== new_password) {      
        this.isValidate = false;  
        this.errorMessage='New Password and Confirm Password does not match'           
    }
   return this.isValidate;    
  }

  SetNewPassword(form) {        
    this.errorMessage=null;
    if (form.valid) {      
      if(this.Validate()){
        this.IsvalidForm = true;
        this.UserService.SetNewPassword(this.fn.NewPassword.value,this.fn.OldPassword.value).subscribe(
        (data: any) => {
          if (data.body != null){
          if(data.body.Status != 1 && data.body.ErrorMessage !=""  ){
            this.errorMessage = data.body.ErrorMessage;
          }
          else{
            this.resetForm.reset();          
            this.successMessage = 'Your password has changed successfully';
            localStorage.setItem("IsTemporyPwd","false");
            this.UserService.logout();    
            setTimeout(() => {
              this.successMessage = null;
              this.router.navigate(['Home']);
            }, 3000);
          }
        }
      },
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }
      );
     }
    } else { this.IsvalidForm = false; }
  }
  redirectToMenu(){    
    let Monitoring=sessionStorage.getItem("Monitoring");
    let Scaning=sessionStorage.getItem("Scan");
    let UserType=sessionStorage.getItem("UserType");

    if (Monitoring == "true" && Scaning == "false" && UserType==""){
      this.router.navigate(['CorporateDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "false" && UserType==""){
      this.router.navigate(['ServiceDashboard']);
    }
    else if (Scaning == "true" && Monitoring == "true" && UserType==""){
      this.router.navigate(['CorporateDashboard']);
    }
    else{
      this.router.navigate(['UserListDashboard']);
    }
  }

}
