import { Component, OnInit } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { UserProfile } from '../../Class/UserProfile/user-profile'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import * as $  from 'jquery';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  userProfile: UserProfile = new UserProfile();

  constructor(private formBuilder: FormBuilder,private router: Router, private route:ActivatedRoute, private apiService: UserService) { }
  
  profileForm:FormGroup;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  LoginEmail;

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({      
      FirstName: ['',[Validators.required]], 
      LastName: ['',[Validators.required]],      
      PhoneNumber: ['',[Validators.required,Validators.maxLength(13),Validators.minLength(10),Validators.pattern("^((\\91-?)|0)?[0-9]{10}$")]],       
      Email: ['',[Validators.required, Validators.email]]      
    });
    this.LoginEmail= sessionStorage.getItem("emailAddress"); 
    this.getProfileDetail(this.LoginEmail)
  }

  keyPress(event: any) {    
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.which == 32)
            return false;
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  get fn() { return this.profileForm.controls; }  

  getProfileDetail(Email: string) {                
    var UserData;   
    this.errorMessage=null;    
    this.apiService.GetUserProfileDetail(Email).subscribe(
      (Data: any) => {          
        UserData = Data;
        if (UserData.Status != 1 && UserData.ErrorMessage !=""  ){
          this.errorMessage = UserData.ErrorMessage;
        }
        else 
        {
          let UserInfo=UserData.AdminDetail;      
          this.userProfile.AdminId= UserInfo.AdminId;
          this.profileForm.controls['FirstName'].setValue(UserInfo.FirstName); 
          this.profileForm.controls['LastName'].setValue(UserInfo.LastName); 
          this.profileForm.controls['Email'].setValue(UserInfo.Email);  
          this.profileForm.controls['PhoneNumber'].setValue(UserInfo.PhoneNumber);
      }
    });           
  }

  UpdateProfile(profileForm)  
  {      
    this.errorMessage=null;
        if (this.profileForm.invalid) {         
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1].focus();        
          return;
        }                
        this.apiService.updateUserProfile(this.userProfile).subscribe(  
          
          (data:any)=>  
          {
            debugger;
            if (data != null){
              if(data.Status != 1 && data.ErrorMessage !=""  ){
                this.errorMessage = data.ErrorMessage;
              }
              else{                                                                        
                  this.successMessage = 'Updated Successfully';                  
                  setTimeout(() => {
                    this.profileForm.reset(); 
                    this.successMessage = null;
                    this.getProfileDetail(this.LoginEmail)
                    //this.router.navigate(['UserListDashboard']);
                  }, 1000);
              }
            }              
          },           
          err => {
            if (err.error) {
              this.errorMessage = err.error.ErrorMessage;
            }
          }      
        )  
    } 
    redirectToMenu(){      
      let Monitoring=sessionStorage.getItem("Monitoring");
      let Scaning=sessionStorage.getItem("Scan");
      let UserType=sessionStorage.getItem("UserType");
  
      if (Monitoring == "true" && Scaning == "false" && UserType==""){
        this.router.navigate(['CorporateDashboard']);
      }
      else if (Scaning == "true" && Monitoring == "false" && UserType==""){
        this.router.navigate(['ServiceDashboard']);
      }
      else if (Scaning == "true" && Monitoring == "true" && UserType==""){
        this.router.navigate(['CorporateDashboard']);
      }
      else{
        this.router.navigate(['UserListDashboard']);
      }
    } 

}
