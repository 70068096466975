import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder,FormControl, Validators,ValidatorFn  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { OrderPipe } from 'ngx-order-pipe';
 
import { v4 as uuidv4 } from 'uuid';
 import { FormGroup} from '@angular/forms';
import { DataService } from '../../Services/data.service';
import { data, event } from 'jquery';
import { MapsAPILoader} from '@agm/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from '../../Class/Company/company';
import { MatTableDataSource } from '@angular/material/table';

 
import * as _ from 'lodash';
import { Console } from 'console';


 



declare var $: any;

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.css']
})

export class ChallengesComponent implements OnInit {
onStartDateChange() {
throw new Error('Method not implemented.');
}
 
  currentPage: number = 1;
  CreateReleaseForm: FormGroup;
  CreateRelease: any = {};
  // @Input() min: any;
  // yesterday = new Date();
  imageSrc: string;
  order: string;
  reverse: boolean;
  challengeImage: any;
  UserService: any;
  selectedFile: File | null = null;
  selectedBadge: any;
  // serviceId: any[] =[];
  serviceId: any[] = []; 
  ChallengeList;
  selectedBadge1: any;
  selectedBadge2: any;
  selectedBadge3: any;
  selectedCompletionBadge: any;
  badgeList: any[] = [];
  CompanyList: any[] = [];
  SearchText: string = '';
  pageOfItems: any[] = []; 
   challenges
   searchDate: string = '';
  filteredChallenges: any[] = [];

   
  // currentDate: any = new Date()
  creationDate: any = new Date()
  createdBy:any;
  // ViewChallengeHistory:any;
  lastUpdatedBy:any;
  group_uid:any;
  group_name:any;
  @ViewChild('badgeModal', { static: false }) badgeModal: ElementRef;
  @ViewChild('elementName', { static: false }) elementRef: ElementRef;
  companyForm: any;
  RightsError: boolean;
  url: any;
  currentDate: string;
  submitted = false;
onEndDateChange: any;
allBadgesSelected = false;
CompanyName:string;
  corporate_id: null;
  isSuperAdmin: boolean;
  UserType:"";
  resetchallengeType: any;
  challengeType:any;
  Challenges: any[] = [];
  MyChallenges: any[] = [];
   // Define the available challenge types
  challengeTypes: string[] = ['CORPORATE', 'GLOBAL'];
  

  // Initialize the selected challenge type
   selectedFilter: string;
  selectedChallengeType: string = ''; // To store the selected challenge type.
  originalChallenges: any[] = []; // Copy of the original data.
  ChallengeForm: any;
  CorporateChallenges: any;
  otherServiceId: any[];
   itemsPerPage = 20; // Number of challenges per page
  totalItems = 0; // Total number of challenges available
   
  
  
 
 
  

  constructor(
    private DataService:DataService,
    
    
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private orderPipe: OrderPipe,
    private router: Router,
    private apiService: UserService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    
    // private viewservice:UserService,
    
     //private viewdata:ViewChallengeHistory,
  ) 
  
  
  { 

 
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's zero-based
    const day = String(today.getDate()).padStart(2, '0');
    
    this.currentDate = `${year}-${month}-${day}`;

 
  }
  
      pagedItems: Array<any>; 
      errorMessage:string;
      successMessage: string; 
     
      viewList: any;
      CompanyId;
  dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const challengeStartDate = control.get('challengeStartDate')?.value;
    const challengeEndDate = control.get('challengeEndDate')?.value;
    if (challengeStartDate && challengeEndDate && challengeStartDate > challengeEndDate) {
      return { 'invalidDateRange': true };
    }
    return null;
  }
  setOrder(value: string): void {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

   

  getMinEndDate(): string {
    const startDate = this.CreateReleaseForm.get('challengeStartDate')?.value;
  
    if (startDate) {
      const startDateObj = new Date(startDate);
  
      // Add two days to the start date
      startDateObj.setDate(startDateObj.getDate() + 2);
  
      // Format the minimum end date as a string in the "yyyy-MM-dd" format
      return startDateObj.toISOString().split('T')[0];
    }
  
    // If there is no start date selected, return an empty string or a default value as needed
    return '';
  }

  get f() {
    return this.CreateReleaseForm.controls;
  }
  
  

  ngOnInit(): void {
    
    

    this.route.queryParams.subscribe(params => {
      const serviceIdParam: any = params['serviceId'];
    
      if (typeof serviceIdParam === 'string' || serviceIdParam instanceof String) {
        // Push the string into the array
        this.serviceId.push(serviceIdParam);
      }
    
      // Checking if serviceId is an array of strings and '4' is present
      if (Array.isArray(this.serviceId) && this.serviceId.includes('4')) {
        $('#myTab a:last').tab('show'); // Activate the last tab
      }
    });

      
    console.log(this.serviceId);
    console.log('Form Data:', this.serviceId );

     
     

    
    console.log(this.serviceId);
    console.log('Form Data:', this.serviceId );


    this.CompanyName=atob(sessionStorage.getItem("CompanyName"));
    this.CompanyId=atob(sessionStorage.getItem("CompanyId"));

  
    // this.getChallenges();
    this.getMyChallenges()

    
    
      let Monitoring=sessionStorage.getItem("Monitoring");
      let Scaning=sessionStorage.getItem("Scan");
      let UserType=sessionStorage.getItem("UserType");
      
       console.log(UserType);
  
  
      
    if (Monitoring == "true" && Scaning == "false" && UserType == "") {
      this.isSuperAdmin = false;
    } else if (Scaning == "true" && Monitoring == "false" && UserType == "") {
      this.isSuperAdmin = false;
    } else if (Scaning == "true" && Monitoring == "true" && UserType == "") {
      this.isSuperAdmin = false;
    } else {
      this.isSuperAdmin = true; // For all other cases, including UserType not empty
    }
              
       
     console.log(UserType);
    
     
    if (this.isSuperAdmin) {
      // For superadmins
      this.CreateReleaseForm = this.formBuilder.group({
        challengeTitle: new FormControl('', [Validators.required]),
        challengeType: new FormControl('',[Validators.required]),
         
        corporate_id: new FormControl('',  ),
        corporate_name: new FormControl('', ),
        challengeStartDate: new FormControl('', [Validators.required]),
        challengeEndDate: new FormControl('', [Validators.required]),
        challengeTarget: new FormControl('', [Validators.required]),
        ChallengeDetails: new FormControl('', [Validators.required, Validators.maxLength(500)]),
         challengeImage: new FormControl('', [Validators.required]),
        badge_one: new FormControl('', ),
        badge_two: new FormControl('' ),
        badge_three: new FormControl('' ),
        badge_completion: new FormControl(''),
        createdBy:[uuidv4()],
        creationDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
        lastUpdatedBy:[uuidv4()],
      },{ validator: this.dateValidator });
      this.badgeList = [];
    this.CompanyList = [];
    // this.getChallenges()
    this.getMyChallenges()
    }



    
  
      
     else     {
      // For non-superadmins
      this.CreateReleaseForm = this.formBuilder.group({
        challengeTitle: new FormControl('', [Validators.required]),
        challengeType: new FormControl('CORPORATE', [Validators.required]),
        corporate_id: new FormControl(this.CompanyId, [Validators.required]),
        corporate_name: new FormControl(this.CompanyName, [Validators.required]),
        challengeStartDate: new FormControl('', [Validators.required]),
        challengeEndDate: new FormControl('', [Validators.required]),
        challengeTarget: new FormControl('', [Validators.required]),
        ChallengeDetails: new FormControl('', [Validators.required, Validators.maxLength(500)]),
         challengeImage: new FormControl('', [Validators.required]),
        badge_one: new FormControl('', ),
        badge_two: new FormControl('' ),
        badge_three: new FormControl('' ),
        badge_completion: new FormControl(''),
        createdBy:[uuidv4()],
        creationDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
        lastUpdatedBy:[uuidv4()],

      },{ validator: this.dateValidator });
      this.badgeList = [];
      this.CompanyList = [];
      this.getMyChallenges()
    }






    
  }



  

  
 
resetChallengeType() {
  // Reset the challengeType control to an initial value (e.g., '')
  this.CreateReleaseForm.controls['challengeType'].setValue('');
}




 

  
  
async create() {
  console.log(this.challengeType);
  console.log(this.serviceId);
    
    
 


    this.errorMessage = null;

if (this.CreateReleaseForm.invalid) {
  // Find the first invalid control in the form
  const invalidControl = Object.values(this.CreateReleaseForm.controls).find(control => control.invalid);

  if (invalidControl) {
    // Focus on the first invalid control
    invalidControl.markAsTouched();
    invalidControl.markAsDirty();
    invalidControl.updateValueAndValidity();
    // You can optionally return here to prevent the code below from running
    return;
  }
}

// All form validation is complete
if (!this.selectedBadge1) {
  alert('Please select the first batch.');
  return;
}

 
    
    if (this.CreateReleaseForm.valid && this.selectedFile) 
  {
    console.log(this.CreateReleaseForm.value);
    console.log("form submitted");
     }
    const formData = new FormData();
    if (this.selectedBadge) {
      const badgeRank = this.selectedBadge.rank;
      formData.append('BadgeRank', badgeRank);
    }
    formData.append('challengeTitle', this.CreateReleaseForm.value.challengeTitle);

   console.log(this.CreateReleaseForm.value.badge_one);
    console.log(this.CreateReleaseForm.value.ChallengeDetails);
    

    formData.append('challengeType', this.CreateReleaseForm.value.challengeType);
    formData.append('challengeStartDate', this.CreateReleaseForm.value.challengeStartDate);
    formData.append('challengeEndDate', this.CreateReleaseForm.value.challengeEndDate);
    formData.append('challengeTarget', this.CreateReleaseForm.value.challengeTarget);
    formData.append('ChallengeDetails', this.CreateReleaseForm.value.ChallengeDetails);


      formData.append('challengeImage', this.selectedFile);

    //  formData.append('challengeImage', this.CreateReleaseForm.value.challengeImage);
    formData.append('badge_one', this.selectedBadge1.id);

    
 

    if (this.selectedBadge2) {
      formData.append('badge_two', this.selectedBadge2.id);
  } else {
      formData.append('badge_two', ''); // Append an empty string when the batch is not selected
  }
  
  if (this.selectedBadge3) {
      formData.append('badge_three', this.selectedBadge3.id);
  } else {
      formData.append('badge_three', ''); // Append an empty string when the batch is not selected
  }
  
  if (this.selectedCompletionBadge) {
      formData.append('badge_completion', this.selectedCompletionBadge.id);
  } else {
      formData.append('badge_completion', ''); // Append an empty string when the batch is not selected
  }
  


    formData.append('corporate_id', this.CreateReleaseForm.value.corporate_id);
    formData.append('corporate_name', this.CreateReleaseForm.value.corporate_name);
 
    
    formData.append('createdBy', uuidv4());
    formData.append('creationDate', this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    formData.append('lastUpdatedBy', uuidv4());


    // this.selectedFile = null;
    this.url = null;


    interface ApiResponse {
      Status: number;
      // Add other properties as needed
    }  
    
 // Reset selected badges and other data
 this.resetData();

    console.log('Form Data:', formData);


    this.apiService.createChallenges(formData).subscribe(
      (data:ApiResponse )=> {


console.log(this.challengeType);
        setTimeout(() => {
          this.CreateReleaseForm.reset();
          this.imageSrc = null;
          this.resetchallengeType();
    
          // Navigate to another page (e.g., 'other-page' route)
          // this.router.navigate(['/CorporateChallenges']);
        }, 2000);
    
      (_error) => {
        // Your error handling logic here
      }

 
        
      //  prompt("challenge Created Successfully"); 
        console.log('Challenges created successfully', data);
        this.CreateReleaseForm.reset();
        this.imageSrc = null;

        console.log('Challenge created successfully', data);

        // this.resetChallengeType();
         
 
        if (!this.isSuperAdmin) {


          
          // Reset other form controls first
          this.resetData();
      
          // After resetting the form, selectively set values for specific form controls
          this.CreateReleaseForm.get('corporate_name').setValue(this.CompanyName);
          this.CreateReleaseForm.get('corporate_id').setValue(this.CompanyId);
          this.CreateReleaseForm.get('challengeType').setValue('CORPORATE');
      }
      


           
console.log('Form Data:', formData);


 
if (data != null) {
  
    this.snackBar.open('Challenge created successfully', 'Close', {
      duration: 4000, // Duration in milliseconds (adjust as needed)
      panelClass: ['success-snackbar'],
    });

    // Reset the form or perform any other necessary actions
      // window.location.reload();
       location.reload();
     
    // this.router.navigate(['/CorporateChallenges'], { queryParams: { serviceId: 4} });

}
},
(error) => {
console.error('Error creating challenge', error);
// Handle the error as needed
}

  
    
      








      // },
      
    );
   
  }
  
  

   

  onFileSelected(event: any): void{
    this.selectedFile = event.target.files[0] as File;
if(event.target.files){
  var reader = new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload=(event:any)=>{
    this.url=event.target.result;
  }
}
  }

 

  resetData() {
     
    this.selectedBadge1 = null;
    this.selectedBadge2 = null;
    this.selectedBadge3 = null;
    this.selectedCompletionBadge = null;
   
  }
 
  
  

  onCompanySelected() {
    const selectedCompanyId = this.CreateReleaseForm.controls['corporate_id'].value;
    var selectedCompanyName = '';

    for (let index = 0; index < this.CompanyList.length; index++) {
      const element = this.CompanyList[index];
      if(element.CompanyId == selectedCompanyId){
        selectedCompanyName = element.CompanyName;
      }
    }
    console.log(selectedCompanyId);
    this.CreateReleaseForm.controls['corporate_name'].setValue(selectedCompanyName);
    console.log(selectedCompanyName);
  }



 
  
   


  deleteMChallenge(challengeId: string) {
    const selectedChallenge = this.Challenges.find(challenge => challenge.id === challengeId);
    
    if (selectedChallenge && selectedChallenge.Participants === 0) {
      if (confirm('Are you sure you want to delete this challenge?')) {
        this.apiService.deleteChallenge(challengeId).subscribe(
          (data: any) => {
            console.log('Challenge deleted successfully', data);
            // Handle UI updates (e.g., remove the challenge from the list)
            
            const index = this.Challenges.findIndex(challenge => challenge.id === challengeId);
            if (index > -1) {
              this.Challenges.splice(index, 1);
              // Handle UI updates after successful deletion
              window.location.reload();
             }
          },
          (error) => {
            console.error('Error deleting challenge', error);
            alert('An error occurred while deleting the challenge.');
          }
        );
      } else {
        alert('Challenge cannot be deleted as there are participants.');
      }
    } else {
      alert('Challenge cannot be deleted as there are participants.');
    }
    this.router.navigate(['/CorporateChallenges'], { queryParams: { serviceId: 4} });

  }
  
   
  // getChallenges(): void {
  //   if (this.isSuperAdmin) {
  //     let SearchKey = this.SearchText ? btoa(this.SearchText) : '';
  //     let PageNo = this.currentPage;

  //     this.apiService.GetChallenges(SearchKey, PageNo).subscribe(
  //       (data: any) => {
  //         if (data && data['GetChallenges']) {
  //           this.Challenges = data['GetChallenges'];
  //           // Assuming the API sends the total count for pagination
  //           this.totalItems = data['TotalCount'];
  //           console.log('page:', PageNo);

  //           // Update the current page number after successfully fetching challenges
  //           this.currentPage = PageNo;

  //           // Update the page of items with the newly fetched challenges
  //           this.pageOfItems = this.Challenges; // Assuming pageOfItems is used to display data
  //         }
  //       },
  //       (error: any) => {
  //         console.error('Error:', error);
  //       }
  //     );
  //   }
  // }

  // onPageChange(pageOfItems: Array<any>) {
  //   // Update the current page of items
  //   this.pageOfItems = pageOfItems;
  // }


  

  
  getMyChallenges() {
    // This code block will be executed if this.isSuperAdmin is false
    // Encode the search text to be sent to the API
    let SearchKey = btoa(this.SearchText ? this.SearchText : '');
  
    let PageNo = 1; // You can adjust the page number if needed
  
    this.apiService.GetMyChallenges(SearchKey, PageNo,this.CompanyId).subscribe(
      (data: any) => {
        // Handle the data received from the API here
        console.log('Data from API:', data);
  
        if (data) {
          // Check the response structure and handle it accordingly
          // Assuming the response contains a 'GetMyChallenge' property:
          this.Challenges = data['GetMyChallenges'];
          this.totalItems = data['TotalCount'];
            console.log('page:', PageNo);
            this.currentPage = PageNo;
          console.log('Challenges:', this.Challenges);
          console.log('CompanyId :',  this.CompanyId);
          this.pageOfItems = this.Challenges; 
        }
      },
      (error: any) => {
        // Handle any errors that may occur during the subscription
        console.error('Error:', error);
      }
    );
  }

  onPageChange(pageOfItems: Array<any>) {
    // Update the current page of items
    this.pageOfItems = pageOfItems;
  } 
 
  
  
  
applyFilter() {
    // If originalChallenges is empty, make a copy of Challenges.
    if (this.originalChallenges.length === 0) {
      this.originalChallenges = [...this.Challenges];
    }

    // Create a filtered version of your challenges array based on the selected challenge type.
    if (this.selectedChallengeType) {
      this.Challenges = this.originalChallenges.filter(challenge => challenge.challengeType === this.selectedChallengeType);
    } else {
      // If no specific challenge type is selected, show all challenges from the original data.
      this.Challenges = [...this.originalChallenges];
    }
  }

  
  
  EditChallenges( ) {          
    this.router.navigate(['AddNewBranch'], {
     
    });    
  }
 
  filterByDate() {
    if (this.searchDate) {
      // Use JavaScript's Date object to parse the searchDate string
      const selectedDate = new Date(this.searchDate);

      // Filter the Challenges array to include only items with challengeStartdate matching the selected date
      this.filteredChallenges = this.Challenges.filter(challenge => {
        const challengeDate = new Date(challenge.challengeStartdate);
        return challengeDate.toDateString() === selectedDate.toDateString();
      });
    } else {
      // If no date is selected, show all challenges
      this.filteredChallenges = this.Challenges;
    }
  }
  buildRouterParams(challenge: any): any {
    const params: any = { title: challenge.challengeTitle, group: challenge.groupName };
  
    if (challenge.challengeType === 'CORPORATE') {
      params.corporate = challenge.corporateName;
    }
  
    return params;
  }
  

  


  
  onCorporateSelected() {

    const selectedType = this.CreateReleaseForm.controls['challengeType'].value;
    console.log(selectedType);
    if (selectedType === 'CORPORATE') {

      this.apiService.GetCompanyList().subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.CompanyList = data.UserList;
          console.log(this.CompanyList);
        }
        (error) => {
          console.error('Error fetching employee list:', error);
        }
      });
    } else {
    }
  }

  get fn() { return this.CreateReleaseForm.controls; } 

  
    filterData() {
      if (this.selectedFilter === 'all') {
        // If 'All' is selected, clear the filter and show all items
        this.filteredChallenges = [...this.Challenges]; // Create a copy of the original data
      } else {
        this.filteredChallenges = this.Challenges.filter(challenge => {
          if (this.selectedFilter === 'global') {
            return challenge.isGlobal === true;
          } else if (this.selectedFilter === 'corporate') {
            return challenge.isCorporate === true;
          }
        });
      }
    }
    
    
  
    navigateToParticipantsList() {
      this.router.navigate(['/participantslist']);
    }  
    

    navigateToSection() {
      if (Array.isArray(this.serviceId) && this.serviceId.length === 1 && this.serviceId[0] === 4) {
        const element = document.getElementById('profile2');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    

 


  
  selectBadge(rank: any) {
    
    if (typeof rank !== 'number') {
      return;
    }
    console.log('Selected badge rank:', rank);
    
    this.apiService.getAllBadges(rank).subscribe(
      (badgeData: any) => {
        console.log('Badge data:', badgeData);
        this.badgeList = badgeData.GetAllBadge;
        const selectedBadge = badgeData.GetAllBadge;
        console.log(badgeData);
        $('#exampleModalCenter').modal('hide');
      },
      (error) => {
        console.error('Error fetching badge data:', error);
        this.badgeList = [];
      }
    );
  }
  setSelected(badge: any) {
    if (!badge) {
      return;
    }
    console.log('setSelected() function called with badge:', badge);
    console.log('badge.Rank:', badge.Rank);
    console.log( 'badge.id:',badge.id);
    switch (badge.Rank) {
      case 1:
        this.selectedBadge1 = badge;

        break;
      case 2:
        this.selectedBadge2 = badge;
        break;
      case 3:
        this.selectedBadge3 = badge;
        break;
      case -1:
        this.selectedCompletionBadge = badge;
        break;
      default:
        break;
        
    }
    console.log('this.selectedBadge1:', this.selectedBadge1);
   
    console.log(this.creationDate);
    console.log('this.selectedCompletionBadge:', this.selectedCompletionBadge);
    $('#exampleModalCenter').modal('hide');
  }
}






function showHideMenu() {
  throw new Error('Function not implemented.');
}








                                                            

