import { Component, OnInit } from '@angular/core';
import {FormsModule,FormControl ,Validators, FormGroup ,FormBuilder } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../Services/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import { AppUpdateHistory } from '../../Class/AppUpdateHistory/app-update-history';
import { FirmwareRelease } from '../../Class/FirmwareRelease/firmware-release';
declare var $: any;
@Component({
  selector: 'app-firmware-setting',
  templateUrl: './firmware-setting.component.html',
  styleUrls: ['./firmware-setting.component.css']
})
export class FirmwareSettingComponent implements OnInit {
  releaseForm:FormGroup;
  FirmwareReleaseForm:FormGroup;
  errorMessage: string;
  successMessage: string;
  SearchText: string=''; 
  IsvalidForm = true;
  startDate: Date;

  AppHistoryList;

  pagedItems: Array<any>; 
  AppHistory : AppUpdateHistory = new AppUpdateHistory();
  FirmwareRelease : FirmwareRelease =new FirmwareRelease();
  order: string = 'ReleaseDate';
  reverse: boolean = false;
  CompanyId:string;
  AdminId:string;
  UploadFile:any;

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  } 

  constructor(public datepipe: DatePipe,private formBuilder: FormBuilder,private router: Router, private route:ActivatedRoute, private apiService: UserService) { }

  ngOnInit(): void {
    this.startDate =  new Date();
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    this.AdminId = atob(sessionStorage.getItem("uid"));
    this.releaseForm = this.formBuilder.group({            
      ReleaseDate: ['',[Validators.required]],  
      AppVersion: ['',[Validators.required]],
      OSType: [null,[Validators.required]],
      BuildNumber: [""],            
      IsForcefullUpdate: [false]       
    });
    this.FirmwareReleaseForm  = this.formBuilder.group({            
      ReleaseFile: ['',[Validators.required]],  
      FileSize: [''],
      VersionNumber: ['',[Validators.required]],      
      SendNotification: [false]       
    });
    this.dropifySetup();
    this.getAppHistoryList(this.CompanyId,'0')
  }
  
  get fn() { return this.releaseForm.controls; } 
  get ffn() { return this.FirmwareReleaseForm.controls; }  

  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  } 

  closeModal(){ 
    this.errorMessage=null;
    this.successMessage=null;
    this.releaseForm.reset();      
    $("#releaseModal").modal('hide'); 
  }

  CreateHistory(releaseForm) {            
    this.errorMessage=null;  
    if (this.releaseForm.invalid) {         
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();        
     return;
    }          
    this.AppHistory.CompanyId=this.CompanyId;
    this.AppHistory.AdminId=this.AdminId;

      this.apiService.createAppHistory(this.AppHistory).subscribe(   
        (data:any)=>  
        {
          if (data != null){
            if(data.Status != 1 && data.ErrorMessage !=""  ){
              this.errorMessage = data.ErrorMessage;
            }
            else{                                                     
                this.successMessage = 'Saved Successfully';  
                setTimeout(() => {
                this.releaseForm.reset();
                this.successMessage = null;
                this.closeModal();
                this.getAppHistoryList(this.CompanyId,'0')
              }, 1000);
            }
          }                               
        },           
        err => {
          if (err.error) {
            this.errorMessage = err.error.ErrorMessage;
          }
        }      
      )        
}
getAppHistoryList(CompanyId:string,AdminId:string){                
  this.apiService.GetAppHistoryList_Web(CompanyId,AdminId).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != "1" && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                           
          this.AppHistoryList = data['AppHistoryList'];                      
        }          
      }              
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }    
); 
}
UploadFirmwareRelease(FirmwareForm) {  
  

  this.errorMessage=null;
  if (this.FirmwareReleaseForm.invalid) {         
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    invalidFields[1].focus();        
   return;
  }              
    
  let formData:FormData = new FormData();      
  formData.append('ReleaseFile', this.UploadFile[0]);  
  formData.append('FileSize', $("#filesize").val());  
  formData.append('VersionNumber', FirmwareForm.VersionNumber);  
  formData.append('IsNotificationSent', FirmwareForm.SendNotification);    
  formData.append('UploadedBy', this.AdminId);  
  if(confirm("Are you sure you want to upload this release. If you upload this release existing release will be replaced!")) {
  this.apiService.UploadReleaseFile(formData).subscribe(   
    (data:any)=>  
    {
      if (data != null){
        if(data.Status != 1 && data.ErrorMessage !=""  ){
          this.errorMessage = data.ErrorMessage;
        }
        else{                                                     
            this.successMessage = 'Release Uploaded Successfully';  
            setTimeout(() => {
            this.FirmwareReleaseForm.reset();
            this.successMessage = null; 
            $('.dropify-clear').click();
           }, 3000);
        }
      }                               
    },           
    err => {
      if (err.error) {
        this.errorMessage = err.error.ErrorMessage;
      }
    }      
  )  
  }           
}


  dropifySetup(){
    $('.dropify').dropify();

    // Translated
    $('.dropify-fr').dropify({
      messages: {
        'default': 'Drag and drop a file here or click',
        'replace': 'Drag and drop or click to replace',
        'remove':  'Remove',
        'error':   'Ooops, something wrong happended.'
        }
    });

    // Used events
    var drEvent = $('#input-file-events').dropify();

    drEvent.on('dropify.beforeClear', function(event, element) {
        return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
    });

    drEvent.on('dropify.afterClear', function(event, element) {
        alert('File deleted');
    });

    drEvent.on('dropify.errors', function(event, element) {
        console.log('Has Errors');
    });

    var drDestroy = $('#input-file-to-destroy').dropify();
    drDestroy = drDestroy.data('dropify')
    $('#toggleDropify').on('click', function(e) {
        e.preventDefault();
        if (drDestroy.isDropified()) {
            drDestroy.destroy();
        } else {
            drDestroy.init();
        }
    });
  }
  
  Filevalidation(element){     
            this.UploadFile = element.target.files;     
            if (this.UploadFile.length > 0) { 
                for (let i = 0; i <= this.UploadFile.length - 1; i++) {       
                    const fsize = this.UploadFile.item(i).size; 
                    const file = Math.round((fsize / 1024));                          
                    $('#filesize').val( file + ' KB') ;                                         
                } 
            } 
      }

}
