import { Injectable, ErrorHandler } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'; 
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FormGroup  } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class UserSignInService {

  // //public rootUrl = "http://35.213.167.134";
                 public rootUrl ="https://care.tres.in/CareAtwork";
      //  public rootUrl = "http://preproduction-k/CareAPI";   
      // public rootUrl = "https://localhost:44398"; 
        //  public rootUrl = "http://konnect.quadlabs.net/CareAPI";
  isLoggedIn : boolean=false;
  
  constructor(private http: HttpClient, private router: Router) { }

  userAuthentication(userName:string,password:string): Observable<object> {        
    userName= btoa(userName.trim());
    password= btoa(password.trim());

    let data = "UserName=" + userName + "&Password=" + password + "&Source=&FullName=&CLIENT_Id=client1&ClientSecret=secret&grant_type=password&IS_FromWeb=true";   
    debugger;    
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic Y2xpZW50MTpzZWNyZXQ=' });
    
    return this.http.post(this.rootUrl + "/API/Login", data, { headers: reqHeader, observe: 'response' })            
    .pipe(
     map(data => data ),
       catchError(this.errorHandler)
    )  
}
forgotPassword(userEmail:string): Observable<object> {    
  userEmail= btoa(userEmail.trim());  
  let data = "Emailid=" + userEmail
  let reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  
  return this.http.post(this.rootUrl + "/API/v1/Service/ForgotPassword_Web", data, { headers: reqHeader, observe: 'response' })            
  .pipe(
   map(data => data ),
  
     catchError(this.errorHandler)
  )  
}
SetNewPassword(NewPassword:string, OldPassword: string){    
  OldPassword= btoa(OldPassword.trim());  
  NewPassword= btoa(NewPassword.trim());  
  
  let userEmail = sessionStorage.getItem("emailAddress");  
  let UID = sessionStorage.getItem("uid");  
  let data = "UID="+ UID +  "&EmailId=" + userEmail + "&NewPassword=" + NewPassword + "&OldPassword=" + OldPassword ;       
  let reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});
  
  return this.http.post(this.rootUrl + "/API/v1/Service/SavePassword_web", data, { headers: reqHeader, observe: 'response' })            
  .pipe(
   map(data => data ),
     catchError(this.errorHandler)
  )
}
 public isAuthenticated():boolean{
  return this.getToken() !==null;
 }
 getToken(){
   return sessionStorage.getItem("userToken");
 }
 storeToken(token : string,username: string,email:string,uid: string,IsTemporyPwd:string, rememberMe: string, Password:string,Scan:string,Monitoring:string,CompanyName:string,CompanyId:string,UserType:string){        
   this.isLoggedIn=true;
   if(rememberMe){    
      localStorage.setItem("userToken",token);      
      localStorage.setItem("userName",username);
      localStorage.setItem("emailAddress",email);      
      localStorage.setItem("IsTemporyPwd",IsTemporyPwd);
      localStorage.setItem("rememberMe",rememberMe);
      localStorage.setItem("Password",btoa(Password));
      
      sessionStorage.setItem("userToken",token);
      sessionStorage.setItem("userName",username);
      sessionStorage.setItem("emailAddress",email);
      sessionStorage.setItem("uid",btoa(uid));
      sessionStorage.setItem("IsTemporyPwd",IsTemporyPwd);
      sessionStorage.setItem("Scan",Scan);
      sessionStorage.setItem("Monitoring",Monitoring);
      sessionStorage.setItem("CompanyName",CompanyName);
      sessionStorage.setItem("CompanyId",CompanyId);
      sessionStorage.setItem("UserType",UserType);      
   }else{
      localStorage.removeItem('userToken');
      localStorage.removeItem("userName");
      localStorage.removeItem("emailAddress");      
      localStorage.removeItem("IsTemporyPwd");
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("Password");
      
      sessionStorage.setItem("userToken",token);
      sessionStorage.setItem("userName",username);
      sessionStorage.setItem("emailAddress",email);
      sessionStorage.setItem("uid",btoa(uid));
      sessionStorage.setItem("IsTemporyPwd",IsTemporyPwd);
      sessionStorage.setItem("Scan",Scan);
      sessionStorage.setItem("Monitoring",Monitoring);
      sessionStorage.setItem("CompanyName",CompanyName);
      sessionStorage.setItem("CompanyId",CompanyId);
      sessionStorage.setItem("UserType",UserType); 
   }
 }
 logout():void {
    this.isLoggedIn=false;
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("emailAddress");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("IsTemporyPwd");
    sessionStorage.removeItem("Scan");
    sessionStorage.removeItem("Monitoring");
    sessionStorage.removeItem("CompanyName");
    sessionStorage.removeItem("CompanyId");
    sessionStorage.removeItem("UserType"); 
 }
errorHandler(error : Response){
  console.log(error);
  return throwError(error);
}

}
