import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router , ActivatedRoute } from "@angular/router";
import { observable } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
declare var $:any;

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css']
})
export class BranchListComponent implements OnInit {

  constructor(private orderPipe: OrderPipe,private router: Router, private apiService: UserService,private route: ActivatedRoute ) { }
  BranchList;
  pagedItems: Array<any>; 
  errorMessage: string;
  successMessage: string; 
  CompanyId;
  CompanyName;
  SearchText: string='';
  order: string = 'BranchName';
  reverse: boolean = false;
  
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }  

  ngOnInit(): void {    
    this.CompanyId = atob(sessionStorage.getItem("CompanyId"));
    //this.CompanyName= atob(this.route.snapshot.queryParams.CompanyName); 

    this.getBranchList(this.CompanyId); 
  }
  beginPagination(pagedItems: Array<any>) {  
    this.pagedItems = pagedItems;   
  }    
  getBranchList(CompanyId:string){            
    this.apiService.GetBranchList(CompanyId).subscribe(   
      (data:any)=>  
      
      {
        console.log('API Response Data:', data);
        if (data != null){
          if(data.Status != "1" && data.ErrorMessage !=""  ){
            this.errorMessage = data.ErrorMessage;
          }
          else{                           
            this.BranchList = data['BranchList'];
            
          }
        }              
      },           
      err => {
        if (err.error) {
          this.errorMessage = err.error.ErrorMessage;
        }
      }    
  ); 
}
EditBranch(CompanyId: string, BranchId:string) {          
  this.router.navigate(['AddNewBranch'], {
    queryParams: {        
      "CompanyId": btoa(CompanyId), "BranchId": btoa(BranchId)//, "CompanyName" : btoa(this.CompanyName)
    }
  });    
}
deleteBranch(CompanyId:string,BranchId:string,NumOfEmployee:number) { 
  if(NumOfEmployee>0){
    $('#smallform').modal('show'); 
    return;
  }
  else{
      if(confirm("Are you sure you want to delete this Branch?")) {     
          this.apiService.deleteBranch(CompanyId,BranchId).subscribe(   
            (data:any)=>  
            {
              if (data != null){
                if(data.Status =! "1" && data.ErrorMessage !=""  ){
                  this.errorMessage = data.ErrorMessage;
                }
                else{                             
                  window.location.reload();
                }
              }              
            },           
            err => {
              if (err.error) {
                this.errorMessage = err.error.ErrorMessage;
              }
            }    
          ); 
      }
  }
}
AddNewBranch(){
  this.router.navigate(['AddNewBranch'], {
    queryParams: {        
      "CompanyId": this.CompanyId //, "CompanyName" : btoa(this.CompanyName)
    }
  });    
}

}
